interface SectionContent {
  title: string;
  description: string;
  icon?: string;
}

interface JoinMerchantContent {
  title: string;
  type: string;
  content: Array<SectionContent>;
}

const joinMerchantContent: Array<JoinMerchantContent> = [
  {
    title: "Keunggulan menjadi merchant Foodizmart",
    type: "text",
    content: [
      {
        title: "Memaksimalkan Potensi Pendapatan",
        description: "Dengan market yang sudah tertarget, bisnis Anda akan lebih mudah mencapai target pendapatan",
        icon: "",
      },
      {
        title: "Kemudahan Proses Marketing",
        description: `Berbagai fitur pada aplikasi diantaranya tampilan toko yang menarik (seperti instagram) dan 
                      fitur share toko serta share produk akan memudahkan Anda membagikan informasi kepada pelanggan`,
        icon: "",
      },
      {
        title: "Gratis Ongkir",
        description: `Foodizmart menyediakan jasa pengiriman yang dapat disesuaikan dengan keinginan penjual / pembeli, untuk pengiriman jarak dekat
                      diantaranya Grab Express, Go-Send dan MrSpeedy, sedangkan untuk pengiriman jarak jauh Foodizmart menyediakan JNE, J&T, Si Cepat, Cargo, dll.`,
        icon: "",
      },
      {
        title: "Kemudahan Manajemen Produk",
        description: `Fitur diambil di toko akan menguntungkan merchant karena tidak perlu disibukkan melakukan pengantaran, bagi pelanggan pun akan
                      diuntungkan dengan tidak perlu membayar biaya ongkir`,
        icon: "",
      },
      {
        title: "Banyak Pilihan Metode Pengiriman",
        description: `Foodizmart menyediakan jasa pengiriman yang dapat disesuaikan dengan keinginan penjual / pembeli, untuk pengiriman jarak dekat
                      diantaranya Grab Express, Go-Send dan MrSpeedy, sedangkan untuk pengiriman jarak jauh Foodizmart menyediakan JNE, J&T, Si Cepat, Cargo, dll`,
        icon: "",
      },
      {
        title: "Kemudahan Manajemen Produk",
        description: "Foodizmart menyediakan fitur upload atau edit produk yang dapat dilakukan melalui aplikasi maupun melalui website seller.foodizmart.com",
        icon: "",
      },
    ],
  },
  {
    title: "Cara Membuka Toko Online Foodizmart",
    type: "text",
    content: [
      {
        title: "Masuk ke Google Playstore, cari aplikasi Foodizmart",
        description: "",
        icon: "",
      },
      {
        title: "Registrasi dengan memasukkan nomer handphone",
        description: "",
        icon: "",
      },
      {
        title: "Masukkan OTP yang dikirim melalui whatsapp",
        description: "",
        icon: "",
      },
      {
        title: "Akun member Foodizmart siap digunakan",
        description: "",
        icon: "",
      },
      {
        title: "Masuk ke menu Profil, klik tombol Akun Toko",
        description: "",
        icon: "",
      },
      {
        title: "Isikan data - data toko yang diminta",
        description: "",
        icon: "",
      },
      {
        title: "Buat minimal 1 produk baru yang akan dijual",
        description: "",
        icon: "",
      },
      {
        title: "Akun toko siap digunakan",
        description: "",
        icon: "",
      },
    ],
  },
  {
    title: "Alasan Segera Buka Toko Online di Foodizmart",
    type: "text",
    content: [
      {
        title: "Tak perlu punya offline store",
        description: "",
        icon: "",
      },
      {
        title: "Tak perlu punya ready stock, karena Foodizmart bisa dengan konsep pre order",
        description: "",
        icon: "",
      },
      {
        title: "Join merchant secara Gratis !",
        description: "",
        icon: "",
      },
    ],
  },
];

export default function SectionMerchantContent() {
  return (
    <div className="d-flex flex-column py-4">
      <h6 className="mb-3 profile-title">GABUNG MENJADI TOKO (MERCHANT)</h6>

      <p className="mb-2">
        Join Merchant Saatnya action untuk menjadi penjual dengan omset jutaan hanya dengan Buka Toko Online di Foodizmart
      </p>
      <p className="mb-2">Apa itu Foodizmart ?</p>
      <p>
        Foodizmart adalah aplikasi pemesanan makanan yang mengusung tema acara
        yang pada umumnya dilakukan dalam kehidupan sosial bermasyarakat.
        Dengan adanya Foodizmart pelanggan akan terbantu untuk menemukan ide
        makanan yang tepat untuk acara yang akan mereka adakan
      </p>

      {joinMerchantContent.map((merchantContent: JoinMerchantContent, i: number) => {
        return (
          <div key={i.toString()} className="d-flex flex-column position-relative mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="icofont-caret-right" />
              <h6 className="mb-0 ml-2">{merchantContent.title}</h6>
            </div>

            {merchantContent.content.map((content: SectionContent, x: number) => {
              return (
                <div key={x.toString()} className="d-flex flex-column ml-4 mb-2">
                  <div className="d-flex align-items-center">
                    <div style={{ width: 20 }}>{x + 1}</div>
                    <div className="flex-1">{content.title}</div>
                  </div>

                  {content.description !== "" && (
                    <div style={{ paddingLeft: 20 }}>
                      <span className="text-muted">{content.description}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}

      <p>
        Info lebih lanjut dapat menghubungi customer support kami melalui
        {" "}
        nomor whatsapp 0857-7757-7000 pada jam operasional senin - sabtu pukul 08.00 - 16.00 (Hari Libur Nasional Tutup)
      </p>
    </div>
  );
}
