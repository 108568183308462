import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import Slider from "react-slick";
import { toast } from "react-toastify";
// Components
import { Button, Dialog } from "../../../atoms";
import CardInformation from "./cardInformation";
// Configs
import { routesName } from "../../../../configs";
import { Products, Shops, RoutesDataTypes, CartsItem } from "../../../../configs/datatype";
// Utils
import { setRoute, decoded } from "../../../../utils";
import { useLocalStorage } from "../../../../utils/hooks";
// Data
import { serviceAddItemCarts } from "../../../../services";
import { AppDispatch, RootState, actionAddItemCarts, actionRemoveItemCarts, actionGetCarts } from "../../../../reduxs";

type BuyType = "buynow" | "addcart";

interface SectionDetailProductProps {
  product: Partial<Products>;
  shop: Partial<Shops>;
}

function SectionDetailProduct(props: SectionDetailProductProps) {
  const { product, shop } = props;

  const [quantity, setQuantity] = useState(1);
  const [dialogChangeShop, setDialogChangeShop] = useState(false);
  const [buyType, setBuyType] = useState<BuyType | string>("");
  const [source, setSource] = useState<string>("/");

  const [localCarts, setLocalCarts] = useLocalStorage("_cartsItem", null);
  const [localShop, setLocalShop] = useLocalStorage("_shop", null);

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: RootState) => { return state.rdcusers; });
  const { carts, cartsId } = useSelector((state: RootState) => { return state.rdccarts; });

  const router = useRouter();

  useEffect(() => {
    if (product) {
      const localQuantity = carts?.find((cart: CartsItem) => { return cart.product_id === product.id; })?.qty;

      setQuantity(localQuantity || product.minimum_order_stock!);
    }
  }, [product, carts]);

  useEffect(() => {
    if (product && product.photo) setSource(product.photo);
  }, [product]);

  const handleQuantity = (value: number) => {
    setQuantity(value);
  };

  const handleAddToCart = async (type: BuyType | string) => {
    setBuyType(type);

    const isLogin = JSON.parse(sessionStorage.getItem("_xLo")!);

    // cookies
    const _xSe = Cookies.get("_xSe");

    const item = {
      session_request: decoded(_xSe || ""),
      product_id: product.id!,
      qty: quantity,
      notes: "",
      shopping_cart_id: "",
    };

    const tempCarts: Array<CartsItem> = [
      {
        product_id: product.id!,
        product_name: product.name!,
        price: product.price!,
        qty: quantity,
        notes: "",
        product_photo: product.photo!,
        delivery_area: {
          type: product.delivery_area!,
          areas: [],
        },
      },
    ];

    if (!isLogin) {
      const whereToAfterLogin = type === "buynow" ? setRoute(routesName.CARTS) : `${setRoute(routesName.PRODUCTS)}/${product.id}`;

      dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts: tempCarts } } });
      setLocalCarts(tempCarts);
      setLocalShop(shop);

      sessionStorage.setItem("to", whereToAfterLogin);

      router.push(setRoute(routesName.LOGIN));

      return;
    }

    if (shop.member_id === user.id) {
      toast.warning("Can't add to cart, you are owner of this shop");

      return;
    }

    if (localShop && localShop.id !== shop.id) {
      setDialogChangeShop(true);

      return;
    }

    // Jika tidak ada data localStorage carts
    if (carts.length === 0) {
      dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts: tempCarts } } });
      dispatch(actionAddItemCarts(item));

      toast.success(`${product.name} is added to cart`);

      setLocalShop(shop);

      if (type === "buynow") {
        router.push(setRoute(routesName.CARTS));
      }

      return;
    }

    // Jika ada localStorage carts maka akan di cek apakah produk sudah ada di localStorage carts
    if (carts.find((cart: CartsItem) => { return cart.product_id === product.id; })?.product_id === product.id) {
      const productSimilarIndex = carts.findIndex((cart: CartsItem) => { return cart.product_id === product.id; });

      carts[productSimilarIndex].qty = quantity;

      // item.qty = quantity;
      item.shopping_cart_id = cartsId;

      dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts } } });
      dispatch(actionAddItemCarts(item));

      if (type === "buynow") {
        router.push(setRoute(routesName.CARTS));
      }

      return;
    }

    carts.push({
      product_id: product.id!,
      product_name: product.name!,
      price: product.price!,
      qty: quantity,
      notes: "",
      product_photo: product.photo!,
      delivery_area: {
        type: product.delivery_area!,
        areas: [],
      },
    });

    item.shopping_cart_id = cartsId;

    dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts } } });
    dispatch(actionAddItemCarts(item));

    toast.success(`${product.name} is added to cart`);

    if (type === "buynow") {
      router.push(setRoute(routesName.CARTS));
    }
  };

  const handleClearCartChangeShop = () => {
    try {
      // Token
      const _xSe = Cookies.get("_xSe");

      // Delete old carts
      for (let i = 0; i < carts.length; i += 1) {
        dispatch(actionRemoveItemCarts({
          session_request: decoded(_xSe || ""),
          shopping_cart_id: cartsId,
          product_id: carts[i].product_id,
        }));
      }

      localStorage.removeItem("_cartsId");
      localStorage.removeItem("_shipping");
      localStorage.removeItem("_shippingdate");
      localStorage.removeItem("_shipper");
      localStorage.removeItem("_payment");
      // localStorage.removeItem("_shop");

      setDialogChangeShop(false);

      handleAddToCart(buyType);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while removing item from carts");
    }
  };

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: false,
    dots: true,
    autoplay: true,
  };

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 ">
          <Slider {...settings} className="mb-3">
            <div className="osahan-product-slider-item position-relative overflow-hidden rounded shadow-sm">
              <Image
                src={source}
                onError={() => { setSource("/images/image-default.jpg"); }}
                alt={product?.name}
                layout="fill"
                objectFit="cover"
                priority
                placeholder="blur"
                blurDataURL={product?.photo}
              />
            </div>
          </Slider>

          <div className="pd-f d-flex align-items-center mb-3">
            <Button
              label="KE KERANJANG"
              variant="warning"
              block
              size="lg"
              icon="icofont-plus"
              className="mr-3"
              buttonProps={{
                onClick: () => {
                  handleAddToCart("addcart");
                },
              }}
            />

            <Button
              label="BELI SEKARANG"
              variant="success"
              block
              size="lg"
              icon="icofont-cart"
              className="m-0"
              buttonProps={{
                onClick: () => {
                  handleAddToCart("buynow");
                },
              }}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 ">

          <CardInformation
            product={product}
            shop={shop}
            quantity={quantity}
            doQuantity={handleQuantity}
          />

        </div>
      </div>

      <Dialog open={dialogChangeShop} size="sm">
        <div className="modal-body p-4">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <i className="icofont-cart text-danger my-3" style={{ fontSize: "50px" }} />
            <h6 className="text-center" style={{ lineHeight: 1.5 }}>Ingin pesan dari toko ini saja?</h6>
            <p className="small text-muted m-0 text-center">Bisa saja, tapi kami akan menghapus item di keranjangmu saat ini dari toko sebelumnya terlebih dahulu.</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Batal"
            size="md"
            variant="light"
            buttonProps={{
              onClick: () => { setDialogChangeShop(false); },
            }}
          />

          <Button
            label="Ya, Silahkan"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: handleClearCartChangeShop,
            }}
          />
        </div>
      </Dialog>
    </section>
  );
}

export default SectionDetailProduct;
