import { useEffect, useState } from "react";
// Component
import TabRiwayat from "./tabContent/riwayat";
import TabRekening from "./tabContent/rekening";
// Configs
import { AccountBank } from "../../../configs/datatype";

type TabName = "riwayat" | "rekening";

interface RenderTabsContainerProps {
  tab: TabName;
  data: AccountBank;
  getdata: () => void;
}

interface SaldoTabs {
  name: TabName;
  title: string;
}

interface PropsSectionTabSaldo {
  data: AccountBank;
  hasBankAccount: boolean;
  getdata: () => void;
}

const saldoTabs: Array<SaldoTabs> = [
  {
    name: "riwayat",
    title: "Riwayat Penarikan",
  },
  {
    name: "rekening",
    title: "Rekening Bank",
  },
];

const RenderTabsContainer = (props: RenderTabsContainerProps) => {
  const { tab, data, getdata } = props;

  switch (tab) {
    case "riwayat":
      return (
        <TabRiwayat />
      );
    case "rekening":
      return (
        <TabRekening bankAccount={data} getBankAccount={getdata} />
      );
    default:
      return null;
  }
};

export default function SectionTabSaldo(props: PropsSectionTabSaldo) {
  const { data, hasBankAccount, getdata } = props;

  const [activeTabs, setActiveTabs] = useState<TabName>("rekening");

  useEffect(() => {
    if (hasBankAccount) {
      setActiveTabs("riwayat");
    }
  }, [hasBankAccount]);

  return (
    <div>
      <ul className="nav nav-pills">
        {saldoTabs.map((saldo: SaldoTabs, i: number) => {
          return (
            <li key={i.toString()} className="nav-item" role="presentation">
              <button
                className={`nav-link ${saldo.name === activeTabs ? "active" : ""}`}
                id={i.toString()}
                onClick={() => { setActiveTabs(saldo.name); }}
              >
                {saldo.title}
              </button>
            </li>
          );
        })}
      </ul>

      <div className="tab-content mt-3">
        <RenderTabsContainer
          tab={activeTabs}
          data={data}
          getdata={getdata}
        />
      </div>
    </div>
  );
}
