// import { NextRouter, useRouter } from "next/router";
import cx from "classnames";

interface SectionAboutContentProps {
  query: any;
}

export default function SectionAboutContent(props: SectionAboutContentProps) {
  const { query } = props;

  const { layout } = query;

  const bodyClass = cx("d-flex flex-column", {
    "py-4": layout !== "mobile",
  });

  return (
    <div className={bodyClass}>
      {layout !== "mobile" && (
        <h6 className="mb-4 profile-title">TENTANG KAMI</h6>
      )}

      <p className="">
        Foodizmart merupakan platform pemesanan makanan yang dikembangkan oleh
        anak muda Indonesia yang peduli akan perekonomian masyarakat Indonesia
        yang memburuk dalam semenjak Covid-19 masuk ke Indonesia
      </p>
      <p className="">
        Foodizmart hadir untuk membantu menggerakkan perekonomian dengan
        mengapresiasi karya UMKM yang layak untuk dipromosikan dengan kemudahan
        sistem pemesanan. Sebuah aplikasi pemesanan makanan yang akan memicu
        pergerakan perekonomian dengan munculnya karya karya baru di bidang
        kuliner yang selama ini dikelilingi keraguan dan bingung ingin memulai
        dari mana
      </p>
      <p className="">
        Visi : Mempermudah pembeli menemukan produk yang tepat sesuai acara atau
        kebutuhannya
      </p>
      <p className="">
        Misi : Mempromosikan produk UMKM agar dikenal oleh masyarakat luas
        sehingga dapat membantu meningkatkan perekonomian masyarakat
      </p>
    </div>
  );
}
