import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// Components
import { Button } from "../../../atoms";
// Utils
import { convertToIdr } from "../../../../utils";

type PropsSectionBillDetail = {
  data: any;
  paymentDetail: any;
}

type PropsPopoverTentangSaldo = {
  open: boolean;
  data: any;
  onClose: () => void;
}

const PopoverRincianBayar = (props: PropsPopoverTentangSaldo) => {
  const { open, data, onClose } = props;

  const popoverref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (popoverref.current && !popoverref.current.contains(e.target)) {
        onClose && onClose();
      }
    };

    if (open) {
      document.addEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [popoverref, open, onClose]);

  if (!open) return null;

  return (
    <div
      ref={popoverref}
      className="popover bs-popover-bottom"
      role="tooltip"
      style={{
        position: "absolute",
        top: 255,
        left: 0,
        maxWidth: 400,
      }}
    >
      <div className="arrow" style={{ left: 34 }} />
      <h4 className="popover-header">Rincian Pembayaran</h4>
      <div className="popover-body">
        <dl className="row m-0">
          <dt className="col-6">Pembelian</dt>
          <dd className="col-6 text-right">{convertToIdr(data?.price)}</dd>
          <dt className="col-6">Biaya layanan</dt>
          <dd className="col-6 text-right">{convertToIdr(data?.payment_fee)}</dd>
          <dt className="col-6">Ongkos kirim</dt>
          <dd className="col-6 text-right">{convertToIdr(data?.shipment_fee)}</dd>
        </dl>
        <hr className="mt-1" />
        <dl className="row m-0">
          <dt className="col-6">Total Pembayaran</dt>
          <dd className="col-6 text-right">{convertToIdr(data?.payment)}</dd>
        </dl>
      </div>
    </div>
  );
};

export default function SectionBillDetail(props: PropsSectionBillDetail) {
  const { data, paymentDetail } = props;

  const [togglePopover, setTogglePopover] = useState(false);

  const handleCopyAmount = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      toast.success("Amount copied to clipboard");

      return navigator.clipboard.writeText(data?.payment);
    }

    return Promise.reject(new TypeError("The Clipboard API is not available."));
  };

  const handleCopyBankAccount = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      toast.success("Bank account copied to clipboard");

      return navigator.clipboard.writeText(paymentDetail?.bank_acc_no);
    }

    return Promise.reject(new TypeError("The Clipboard API is not available."));
  };

  return (
    <div className="row mb-5">
      <div className="col-md-5">
        <div className="card">
          <div className="card-header">
            <span>Total Pembayaran:</span>
          </div>
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <h5 className="mb-1 text-danger" style={{ fontWeight: 500 }}>
                {convertToIdr(data?.payment)}
              </h5>

              {/* <Button
                variant="link"
                size="sm"
                label="Lihat rincian"
                className="p-0 text-muted border-0"
              /> */}
              <Button
                label="Lihat rincian"
                size="sm"
                variant="link"
                icon="icofont-info-circle"
                className="p-0"
                buttonProps={{
                  onClick: () => { setTogglePopover(true); },
                }}
              />
            </div>

            <Button
              variant="light"
              size="sm"
              label="Salin"
              buttonProps={{
                onClick: handleCopyAmount,
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-md-7">
        <div className="card">
          <div className="card-header">
            <span>Nomor Rekening:</span>
          </div>
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <h5 className="mb-1 text-danger" style={{ fontWeight: 500 }}>
                {paymentDetail?.bank_acc_no}
              </h5>
              <p className="mb-0">
                Bank
                {" "}
                {paymentDetail?.bank_name}
              </p>
            </div>

            <Button
              variant="light"
              size="sm"
              label="Salin"
              buttonProps={{
                onClick: handleCopyBankAccount,
              }}
            />
          </div>
        </div>
      </div>

      <PopoverRincianBayar
        open={togglePopover}
        data={data}
        onClose={() => { setTogglePopover(false); }}
      />
    </div>
  );
}
