import { useState, useEffect } from "react";
import Image from "next/image";
// Configs
import { UserProfile } from "../../../configs/datatype";
// Utils
import { decoded } from "../../../utils";

type PropsAvatar = {
  avatar: string;
  doChange: (field: string, value: string) => void;
  doEditAvatar: (isUpdating: "avatar" | "name", value?: string | File) => void;
}

export default function ColumnAvatar(props: PropsAvatar) {
  const { avatar, doChange, doEditAvatar } = props;

  const [source, setSource] = useState<string>("/");
  const [upload, setUpload] = useState<string>("");

  useEffect(() => {
    if (avatar) setSource(avatar);
  }, [avatar]);

  const handleChangeAvatar = (value: File) => {
    setUpload(URL.createObjectURL(value));
    // doChange("avatar_file", value.name);

    doEditAvatar("avatar", value);
  };

  return (
    <div className="position-relative">
      <div className="d-flex flex-column mb-3">
        <div
          className="mb-3 position-relative overflow-hidden bg-light rounded"
          style={{
            width: "100%",
            height: 200,
          }}
        >
          <Image
            src={upload || source}
            onError={() => { setSource("/images/avatar-default.jpg"); }}
            alt="avatar"
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
        <label className="btn btn-light btn-md d-flex justify-content-center border">
          <input
            type="file"
            onChange={(e) => { handleChangeAvatar(e.target.files![0]); }}
          />
          Pilih Foto
        </label>
      </div>

      <small className="text-muted">
        Besar file: maksimum 1.000.000 bytes (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG
      </small>
    </div>
  );
}
