import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
// Configs
import { fbsc } from "../constants";
// Utils
import { decoded } from "../../utils";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: decoded(fbsc._ekey),
  authDomain: decoded(fbsc._edomain),
  projectId: decoded(fbsc._proid),
  storageBucket: decoded(fbsc._strbuct),
  messagingSenderId: decoded(fbsc._msgid),
  appId: decoded(fbsc._apps),
  measurementId: decoded(fbsc._messid),
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const messaging = null;
// const messaging = getMessaging(firebase);
// const analytics = getAnalytics(firebase);

export { firebase, messaging };
