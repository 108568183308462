import Link from "next/link";

const Sidebar = () => {
  return (
    <nav id="main-nav">
      <ul className="second-nav">
        <li>
          <Link href="/">
            <a>
              <i className="icofont-home mr-2" />
              Home
            </a>
          </Link>
        </li>
        <li>
          <Link href="/product">
            <a>
              <i className="icofont-chicken mr-2" />
              Produk
            </a>
          </Link>
        </li>
        <li>
          <Link href="/faq">
            <a>
              <i className="icofont-sub-listing mr-2" />
              F.A.Q
            </a>
          </Link>
        </li>
        <li>
          <Link href="/about">
            <a>
              <i className="icofont-page mr-2" />
              Tentang Kami
            </a>
          </Link>
        </li>
        {/* <li>
          <a href="/#">
            <i className="icofont-login mr-2" /> Authentication
          </a>
          <ul>
            <li>
              <a className="dropdown-item" href="signin.html">
                Sign In
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="signup.html">
                Sign Up
              </a>
            </li>
            <li>
              <a href="verification.html">Verification</a>
            </li>
          </ul>
        </li> */}
      </ul>
      <ul className="bottom-nav">
        <li className="ko-fi">
          <Link href="/">
            <a>
              <p className="h5 m-0">
                <i className="icofont-location-pin" />
              </p>
              Location
            </a>
          </Link>
        </li>
        <li className="github">
          <Link href="/">
            <a>
              <p className="h5 m-0">
                <i className="icofont-cart" />
              </p>
              Cart
            </a>
          </Link>
        </li>
        <li className="email">
          <Link href="/">
            <a>
              <p className="h5 m-0">
                <i className="icofont-login" />
              </p>
              Sign In
            </a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
