import { useState, useEffect } from "react";

interface CounterProps {
  doSet: (val: number) => void;
  data?: number;
  min?: number;
  max?: number;
}

export default function CounterInput(props: CounterProps) {
  const { data, min, max, doSet } = props;

  const [counter, setCounter] = useState<number>(1);

  useEffect(() => {
    if (data) {
      setCounter(data);
    }
  }, [data]);

  const handlePlus = () => {
    setCounter(counter + 1);
    doSet(counter + 1);
  };

  const handleMinus = () => {
    const temp = min || 1;

    if (counter > temp) {
      setCounter(counter - 1);
      doSet(counter - 1);
    }
  };

  const handleForm = (value: string) => {
    const temp = min || 1;
    let quantity = null;

    if (value === "") {
      quantity = temp;
    } else if (parseInt(value, 10) < temp) {
      quantity = temp;
    } else {
      quantity = parseInt(value, 10);
    }

    setCounter(quantity);
    doSet(quantity);
  };

  return (
    <div className="cart-items-number d-flex">
      <button className="qtyminus btn btn-success btn-sm" onClick={handleMinus}> - </button>
      <input
        type="number"
        name="quantity"
        min={1}
        className="form-control"
        value={counter}
        onChange={(e) => { handleForm(e.target.value); }}
      />
      <button className="qtyplus btn btn-success btn-sm" onClick={handlePlus}> + </button>
    </div>
  );
}

CounterInput.defaultProps = {
  data: undefined,
  min: undefined,
  max: undefined,
};
