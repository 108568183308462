import { useState, useEffect } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { Anchor, Button } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
import { RoutesDataTypes, UserProfile } from "../../../configs/datatype";
// Utils
import { navigation, setRoute, useLocalStorage, decoded } from "../../../utils";
// Data
import { serviceLogout } from "../../../services";
import { AppDispatch, RootState, actionLogoutUser } from "../../../reduxs";
import ActionTypes from "../../../reduxs/actions/users/actionTypes";

interface UserAuthProps {
  // isLogin: boolean;
  doSetLogin: (value: boolean) => void;
}

const UserAuth = (props: UserAuthProps) => {
  const { doSetLogin } = props;

  const [source, setSource] = useState<string>("/");

  const router = useRouter();

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { isLogin, user, token } = useSelector((state: RootState) => { return state.rdcusers; });

  useEffect(() => {
    if (user && user.avatar) setSource(user.avatar);
  }, [user]);

  const handleLogout = async () => {
    const sessionToken = token || decoded(Cookies.get("_xSe") as string || "");

    dispatch({ type: ActionTypes.LOGOUT_USER_REQUEST });

    try {
      const fd = new FormData();

      fd.append("session_request", sessionToken);

      const response = await serviceLogout(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error("Terjadi kesalahan saat logout");
        dispatch({ type: ActionTypes.LOGOUT_USER_FAILURE, payload: { data: null, message: response.message } });

        return;
      }

      localStorage.clear();
      sessionStorage.setItem("_xLo", JSON.stringify(false));
      Cookies.remove("_xSe");

      dispatch({ type: ActionTypes.LOGOUT_USER_SUCCESS, payload: { data: null } });

      doSetLogin(false);

      router.push("/");
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") toast.error(err.message);
      toast.error("An error occurred while logout");

      dispatch({ type: ActionTypes.LOGOUT_USER_FAILURE, payload: { data: null, message: err.message } });
    }
  };

  if (isLogin) {
    return (
      <div className="dropdown mr-3">
        <Anchor
          href="/#"
          className="dropdown-toggle text-dark "
          anchorProps={{
            id: "dropdownMenuButton",
            "data-toggle": "dropdown",
            "aria-expanded": "false",
            "aria-haspopup": "true",
          }}
        >
          <div className="position-relative overflow-hidden bg-light rounded-circle" style={{ height: 34, width: 34 }}>
            <Image
              src={source}
              onError={() => { setSource("/images/avatar-default.jpg"); }}
              alt="avatar"
              layout="fill"
              objectFit="cover"
              priority
            />
          </div>
          <span className="ml-2">
            Hi,
            {" "}
            {user?.name?.split(" ")[0] || "Guest"}
          </span>
        </Anchor>

        <div
          className="dropdown-menu dropdown-menu-right top-profile-drop"
          aria-labelledby="dropdownMenuButton"
        >
          {navigation("authmenu").map((route: RoutesDataTypes, i: number) => {
            return (
              <Anchor
                key={i.toString()}
                href={route.path}
                className="dropdown-item"
              >
                {route.title}
              </Anchor>
            );
          })}

          <hr className="dropdown-divider" />

          <button className="dropdown-item" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    );
  }

  return (
    <Anchor
      href={setRoute(routesName.LOGIN)}
      // data-toggle="modal"
      // data-target="#login"
      className="mr-2 text-dark bg-light rounded-pill p-2 icofont-size border shadow-sm"
    >
      <i className="icofont-login" />
    </Anchor>
  );
};

export default UserAuth;
