import { Children, SelectHTMLAttributes } from "react";
import cx from "classnames";

interface DefaultInput extends SelectHTMLAttributes<HTMLSelectElement> {
  prepend?: JSX.Element | JSX.Element[] | string;
  append?: JSX.Element | JSX.Element[] | string;
}

interface SelectProps {
  id: string;
  label?: string;
  children: JSX.Element | JSX.Element[];
  block?: boolean;
  useFormGroup?: boolean;
  required?: boolean;
  direction?: "row" | "column";
  size?: "sm" | "md" | "lg";
  error?: boolean;
  helper?: string;
  selectProps?: DefaultInput;
}

export default function Select(props: SelectProps) {
  const { id, label, children, block, useFormGroup, required, direction, size, error, helper, selectProps } = props;

  const FormGroupClass = cx(
    {
      "form-group": useFormGroup,
    },
  );

  const InputGroupClass = cx(
    "input-group",
    {
      "has-validation": error,
      "input-group-sm": size === "sm",
      "input-group-lg": size === "lg",
    },
  );

  const SelectClass = cx(
    "custom-select",
    {
      "is-invalid": error,
    },
  );

  return (
    <div className={FormGroupClass}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
          {required && " *"}
        </label>
      )}

      <div className={InputGroupClass}>
        {selectProps?.prepend && (
          <div className="input-group-prepend">
            {selectProps.prepend}
          </div>
        )}

        <select
          id={id}
          className={SelectClass}
          required={required}
          {...selectProps}
        >
          <option className="text-muted" disabled>{selectProps?.placeholder}</option>

          {Children.map(children, (child) => {
            return (
              <option value={child.props.value}>
                {child.props.children}
              </option>
            );
          })}

        </select>

        {selectProps?.append && (
          <div className="input-group-append">
            {selectProps.append}
          </div>
        )}

        {error && <div className="invalid-feedback">{helper}</div>}
      </div>

      {helper && !error && (
        <small className="form-text text-muted">{helper}</small>
      )}
    </div>
  );
}

Select.defaultProps = {
  label: undefined,
  block: false,
  useFormGroup: true,
  required: false,
  direction: "column",
  size: "md",
  error: false,
  helper: undefined,
  selectProps: undefined,
};
