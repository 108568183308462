import { DataRegister } from "../../configs/datatype";

const registerValidation = (data: Partial<DataRegister>, validate: (error: Partial<DataRegister>) => void) => {
  const fields = data;
  const errors: Partial<DataRegister> = {};

  let isValid: boolean = true;

  const notEmpy = "Tidak boleh kosong";

  if (!fields.name || fields.name === "") {
    isValid = false;
    errors.name = notEmpy;
  }

  if (typeof fields.name !== "undefined" && fields.name) {
    if (!fields.name.match(/^[a-zA-Z ]+$/)) {
      isValid = false;
      errors.name = "Hanya boleh huruf";
    }
  }

  if (!fields.handphone || fields.handphone === "") {
    isValid = false;
    errors.handphone = notEmpy;
  }

  if (typeof fields.handphone !== "undefined" && fields.handphone !== "") {
    if (!fields.handphone.match(/^[+ 0-9]+$/)) {
      isValid = false;
      errors.handphone = "Hanya boleh angka";
    }
  }

  if (!fields.password || fields.password === "") {
    isValid = false;
    errors.password = notEmpy;
  }

  if (typeof fields.password !== "undefined" && fields.password !== "") {
    if (!fields.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
      isValid = false;
      errors.password = "Minimal 8 karakter serta kombinasi huruf besar kecil dan angka";
    }

    // if (!fields.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
    //   isValid = false;
    //   errors.password = "Password minimal 8 karakter, terdiri dari huruf kapital, huruf kecil, angka, dan karakter spesial";
    // }
  }

  if (!fields.confirm_password || fields.confirm_password === "") {
    isValid = false;
    errors.confirm_password = notEmpy;
  }

  if (typeof fields.confirm_password !== "undefined" && fields.confirm_password !== "") {
    if (fields.confirm_password !== fields.password) {
      isValid = false;
      errors.confirm_password = "Konfirmasi password dan password harus sama";
    }

    if (!fields.confirm_password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)) {
      isValid = false;
      errors.confirm_password = "Minimal 8 karakter serta kombinasi huruf besar kecil dan angka";
    }
  }

  validate(errors);

  return isValid;
};

export default registerValidation;
