import { AddressAddData } from "../../configs/datatype";

type ErrorType = AddressAddData & string;

const addressValidation = (data: Partial<AddressAddData>, validate: (error: Partial<AddressAddData>) => void) => {
  const fields = data;
  const errors: Partial<AddressAddData> = {};

  let isValid: boolean = true;

  const notEmpy = "Tidak boleh kosong";

  if (!fields.address_alias || fields.address_alias === "") {
    isValid = false;
    errors.address_alias = notEmpy;
  }

  if (!fields.recipient_name || fields.recipient_name === "") {
    isValid = false;
    errors.recipient_name = notEmpy;
  }

  if (!fields.phone || fields.phone === "") {
    isValid = false;
    errors.phone = notEmpy;
  }

  if (typeof fields.phone !== "undefined" && fields.phone !== "") {
    if (!fields.phone.match(/^[+ 0-9]+$/)) {
      isValid = false;
      errors.phone = "Hanya boleh angka";
    }
  }

  if (!fields.provinsi?.name || fields.provinsi.name === "") {
    isValid = false;
    errors.provinsi = notEmpy;
  }

  if (!fields.kabupaten?.name || fields.kabupaten.name === "") {
    isValid = false;
    errors.kabupaten = notEmpy;
  }

  if (!fields.kecamatan?.name || fields.kecamatan.name === "") {
    isValid = false;
    errors.kecamatan = notEmpy;
  }

  if (!fields.kelurahan?.name || fields.kelurahan.name === "") {
    isValid = false;
    errors.kelurahan = notEmpy;
  }

  if (!fields.address || fields.address === "") {
    isValid = false;
    errors.address = notEmpy;
  }

  validate(errors);

  return isValid;
};

export default addressValidation;
