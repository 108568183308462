import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import Image from "next/image";
import FormData from "form-data";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
// component
import { Anchor, Button, Dialog, Alert } from "../../atoms";
// Configs
import { AddressItem } from "../../../configs/datatype";
// Utils
import { convertToIdr, decoded } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
// Data
import { serviceGetShipperCarts } from "../../../services";
import { RootState } from "../../../reduxs";

interface RenderShipperImageProps {
  shipmentMethod: string;
  finalRate: number;
}
interface CartShipperSectionProps {
  localShipper: any;
  setLocalShipper: any;
  doSetShippingPrice: (value: number) => void;
}

const RenderShipperImage = (props: RenderShipperImageProps) => {
  const { shipmentMethod, finalRate } = props;

  switch (shipmentMethod) {
    case "by_buyer":
      return (
        <div className="d-flex flex-column ml-4">
          <h6 className="card-text mb-1">Gratis</h6>
          <span className="text-muted">Pesanan diambil oleh kamu sendiri</span>
        </div>
      );
    case "by_seller":
      return (
        <div className="d-flex flex-column ml-4">
          <h6 className="card-text mb-1">Gratis</h6>
          <span className="text-muted">Pengiriman dilakukan oleh toko, gerai atau penjual</span>
        </div>
      );
    default:
      return (
        <h6 className="card-text ml-4">
          {convertToIdr(finalRate)}
        </h6>
      );
  }
};

export default function CartShipperSection(props: CartShipperSectionProps) {
  const { localShipper, setLocalShipper, doSetShippingPrice } = props;

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loadingShipper, setLoadingShipper] = useState<boolean>(false);
  const [shippers, setShippers] = useState<any>([]);

  // const [localShipper, setLocalShipper] = useLocalStorage("_shipper", null);

  const { cartsId } = useSelector((state: RootState) => { return state.rdccarts; });

  const router = useRouter();

  const formatedShippingMethod = (value: string) => {
    switch (value) {
      case "by_buyer":
        return "Ambil Ditempat";
      case "by_seller":
        return "Kurir Toko / Penjual";
      case "regular":
        return "Reguler";
      case "express":
        return "Express";
      case "instant":
        return "Instant";
      default:
        return null;
    }
  };

  const getShipper = async () => {
    setLoadingShipper(true);

    // Token
    const _xSe = Cookies.get("_xSe");

    // Local address
    const _shipping = localStorage.getItem("_shipping")!;
    const address: AddressItem = JSON.parse(_shipping);

    const _shippingdate = localStorage.getItem("_shippingdate")!;
    const shippingDate = JSON.parse(_shippingdate);

    const dataSubmit = {
      session_request: decoded(_xSe || ""),
      shopping_cart_id: cartsId,
      member_address_id: address.id,
    };

    try {
      const fd = new FormData();
      fd.append("session_request", dataSubmit.session_request);
      fd.append("shopping_cart_id", dataSubmit.shopping_cart_id);
      fd.append("member_address_id", dataSubmit.member_address_id);

      const response = await serviceGetShipperCarts(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("Fetch Shipper", dataSubmit);
        Sentry.captureException(new Error(`Failed to get shipper :: ${response.message}`), {
          tags: {
            section: "carts",
          },
        });

        toast.error(response.message);
        setLoadingShipper(false);

        return;
      }

      const today = moment().format("YYYY-MM-DD");

      const keyOfRates = Object.keys(response.data.rates);

      const rates = keyOfRates
        .filter((shipper) => {
          if (moment(today).isSame(shippingDate.date)) {
            return shipper !== "express" && shipper !== "regular";
          }

          return shipper;
        })
        .map((shipper: any) => {
          const shipperName = formatedShippingMethod(shipper);

          return {
            type: shipper,
            name: shipperName,
            data: Object.values(response.data.rates[shipper]),
          };
        });

      setShippers(rates);
      setLoadingShipper(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === "development") {
        toast.error(err.message);
        setLoadingShipper(false);

        return;
      }

      Sentry.setContext("Fetch Shipper", dataSubmit);
      Sentry.captureException(new Error(`Failed to get shipper :: ${err.message}`), {
        tags: {
          section: "carts",
        },
      });

      toast.error("Something when wrong when fetching shipper data");
      setLoadingShipper(false);
    }
  };

  useEffect(() => {
    if (openDialog) getShipper();
  }, [openDialog]);

  useEffect(() => {
    if (localShipper) doSetShippingPrice(localShipper?.finalRate);
  }, []);

  const handleEditShipper = () => {
    const _shippingdate = localStorage.getItem("_shippingdate");
    const shippingDate = _shippingdate ? JSON.parse(_shippingdate) : null;

    if (!shippingDate || !shippingDate.date || !shippingDate.time) {
      toast.warning("Please select shipping date and time first");

      return;
    }

    setOpenDialog(true);
  };

  const handleSetShipper = (data: any) => {
    doSetShippingPrice(data?.finalRate as number);

    setLocalShipper(data);
    setOpenDialog(false);
  };

  const localShipmetMethod = formatedShippingMethod(localShipper?.shipment_method);

  return (
    <div className="card">
      <div className="card-header">
        <span>Metode Pengiriman</span>
      </div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <i className="icofont-vehicle-delivery-van icofont-size mr-3" />
            <p className="card-text">{localShipper ? `${localShipmetMethod} - ${localShipper?.name}` : "Silahkan pilih metode pengiriman"}</p>
          </div>

          <Button
            label={localShipper ? "Ubah" : "Pilih Kurir"}
            variant="link"
            size="md"
            icon="icofont-edit"
            className="p-0 text-muted"
            buttonProps={{
              onClick: handleEditShipper,
            }}
          />
        </div>
      </div>

      <Dialog open={openDialog} size="md">
        <div className="modal-header border-0">
          <Alert
            variant="warning"
            text={"Pesanan yang ingin diambil sendiri di toko, silahkan centang pilihan \"Ambil Ditempat\"."}
            className="mb-0"
          />
        </div>
        <div className="modal-body">
          {loadingShipper && [...Array(1)].map((_, i) => {
            return (
              <div key={i.toString()} className="card-input-select mb-2">
                <div className="card bg-light border-0 align-items-center justify-content-center text-muted" style={{ height: 120 }}>
                  <i className="icofont-spinner animate-spin" />
                </div>
              </div>
            );
          })}

          {!loadingShipper && shippers.length === 0 && (
            <div className="card-input-select mb-2">
              <div className="d-flex flex-column align-items-center justify-content-center text-muted" style={{ height: 200 }}>
                <i className="icofont-delivery-time mb-3" style={{ fontSize: 80 }} />
                <div className="text-center" style={{ width: 200 }}>
                  <p>Tidak ditemukan kurir dengan alamat tujuan</p>
                </div>
              </div>
            </div>
          )}

          {!loadingShipper && shippers.length > 0 && shippers.map((val: any, i: number) => {
            return (
              <div className="d-flex flex-column mb-4" key={i.toString()}>
                <p className="text-muted" style={{ fontSize: 14 }}>
                  {val.name}
                </p>

                {val?.data?.map((shipper: any, x: number) => {
                  return (
                    <div key={x.toString()} className="card-input-select mb-2">
                      <input
                        type="radio"
                        id={`reguler-${shipper.shipment_method}-${x}`}
                        name="sipper"
                        value={shipper.shipment_method_code}
                        checked={shipper.shipment_method_code === localShipper?.shipment_method_code}
                        onChange={() => { handleSetShipper(shipper); }}
                      />

                      <label htmlFor={`reguler-${shipper.shipment_method}-${x}`} className="card">
                        <div className="card-body">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">

                              {(shipper.shipment_method === "by_buyer") || (shipper.shipment_method === "by_seller") ? (
                                <i className="icofont-package" style={{ fontSize: 40 }} />
                              ) : (
                                <div
                                  style={{
                                    position: "relative",
                                    width: 100,
                                    height: 40,
                                  }}
                                >
                                  <Image
                                    src={shipper ? shipper.logo_url : "/"}
                                    // width={100}
                                    // height={40}
                                    alt="logo"
                                    layout="fill"
                                    objectFit="contain"
                                  />
                                </div>
                              )}

                              <RenderShipperImage
                                shipmentMethod={shipper.shipment_method}
                                finalRate={shipper.finalRate}
                              />
                            </div>
                            <span className="checkmark" />
                          </div>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}

        </div>

        <div className="modal-footer">
          <Button
            label="Tutup"
            variant="light"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: () => { setOpenDialog(false); },
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
