import { InputHTMLAttributes } from "react";
import cx from "classnames";

interface DefaultInput extends InputHTMLAttributes<HTMLInputElement> {
  prepend?: JSX.Element | JSX.Element[] | string;
  append?: JSX.Element | JSX.Element[] | string;
}

interface InputProps {
  type?: "text" | "email" | "password" | "number";
  id: string;
  label?: string;
  block?: boolean;
  useFormGroup?: boolean;
  required?: boolean;
  direction?: "row" | "column";
  size?: "sm" | "md" | "lg";
  className?: string;
  error?: boolean;
  helper?: string;
  inputProps?: DefaultInput;
}

export default function Input(props: InputProps) {
  const { type, id, label, block, useFormGroup, required, direction, size, className, error, helper, inputProps } = props;

  const FormGroupClass = cx(
    {
      "form-group": useFormGroup,
    },
  );

  const InputGroupClass = cx(
    "input-group",
    {
      "has-validation": error,
      "input-group-sm": size === "sm",
      "input-group-lg": size === "lg",
    },
  );

  const InputClass = cx(
    className,
    "form-control",
    {
      "is-invalid": error,
    },
  );

  return (
    <div className={FormGroupClass}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
          {required && " *"}
        </label>
      )}

      <div className={InputGroupClass}>
        {inputProps?.prepend && (
          <div className="input-group-prepend">
            {inputProps.prepend}
          </div>
        )}

        <input
          type={type}
          id={id}
          className={InputClass}
          required={required}
          {...inputProps}
          // autoComplete=""
        />

        {inputProps?.append && (
          <div className="input-group-append">
            {inputProps.append}
          </div>
        )}

        {error && <div className="invalid-feedback">{helper}</div>}
      </div>

      {helper && !error && (
        <small className="form-text text-muted">{helper}</small>
      )}
    </div>
  );
}

Input.defaultProps = {
  type: "text",
  label: undefined,
  block: false,
  useFormGroup: true,
  required: false,
  direction: "column",
  size: "md",
  className: undefined,
  error: false,
  helper: undefined,
  inputProps: undefined,
};
