import { useState, useEffect } from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import cx from "classnames";
// Components
import { Anchor } from "../../../atoms";
// Configs
import { routesName } from "../../../../configs";
import { RoutesDataTypes, UserProfile } from "../../../../configs/datatype";
// Utils
import { decoded, navigation, limitContentText } from "../../../../utils";
// Data
import { RootState } from "../../../../reduxs";

interface IconProps {
  routename: string;
}

const RenderIcon = ({ routename }: IconProps) => {
  const iconclass = cx(
    "osahan-icofont bg-light text-black-50",
    {
      "icofont-list": routename === "transaction" || !routename,
      "icofont-address-book": routename === "address",
      "icofont-coins": routename === "deposit",
      "icofont-user-alt-1": routename === "profile",
      "icofont-comment": routename === "review",
    },
  );

  return <i className={iconclass} />;
};

export default function Menus() {
  const [source, setSource] = useState<string>("/");

  // Reduxs
  const { user } = useSelector((state: RootState) => { return state.rdcusers; });

  useEffect(() => {
    if (user && user.avatar) setSource(user.avatar);
  }, [user]);

  return (
    <div className="osahan-account bg-white rounded shadow-sm overflow-hidden">
      <div className="d-flex flex-row align-items-center px-4 border-bottom" style={{ height: 130 }}>

        <div className="position-relative rounded-pill bg-light overflow-hidden" style={{ width: 60, height: 60 }}>
          <Image
            src={source}
            onError={() => { setSource("/images/avatar-default.jpg"); }}
            alt="avatar"
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>

        <div className="d-flex flex-column ml-3">
          <h6 className="mb-1" style={{ fontWeight: 500 }}>
            {limitContentText(user?.name as string || "Guest", 15)}
          </h6>
          <p className="small m-0 text-success ">
            <i className="icofont-check-circled mr-1" />
            Member
          </p>
        </div>
      </div>
      <div className="account-sections">
        <ul className="list-group">

          {navigation("usermenu").map(
            (route: RoutesDataTypes, i: number) => {
              return (
                <Anchor
                  key={i.toString()}
                  href={route.path}
                  className="text-decoration-none text-dark"
                >
                  <li className="border-bottom bg-white d-flex align-items-center p-3">
                    <RenderIcon routename={route.name} />
                    {route.title}
                    <span className="badge badge-success p-1 badge-pill ml-auto">
                      <i className="icofont-simple-right" />
                    </span>
                  </li>
                </Anchor>
              );
            },
          )}

        </ul>
      </div>
    </div>
  );
}
