import Link from "next/link";
// Components
import { Anchor } from "../../atoms";
// Configs
import { routes, routesName } from "../../../configs";
import { RoutesDataTypes } from "../../../configs/datatype";
// Utils
import { navigation, setRoute } from "../../../utils";

const HeaderNavbar = () => {
  return (
    <div className="bg-color-head">
      <div className="container menu-bar d-flex align-items-center">
        <ul className="list-unstyled form-inline mb-0">

          {navigation("header").map((route: RoutesDataTypes, i: number) => {
            return (
              <li key={i.toString()} className={`nav-item ${false && "active"}`}>
                <Anchor
                  href={route.path}
                  className={`nav-link text-white ${i === 0 && "pl-0"}`}
                >
                  <>
                    {route.title}
                    {false && <span className="sr-only">(current)</span>}
                  </>
                </Anchor>
              </li>
            );
          })}

          {/* <li className="nav-item dropdown">
              <a
                className="nav-link text-white dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Sample Dropdown
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="listing.html">
                  Listing One
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="picks_today.html">
                  Listing Two
                </a>
                <a className="dropdown-item" href="recommend.html">
                  Listing Three
                </a>
              </div>
            </li> */}

        </ul>
        <div className="list-unstyled form-inline mb-0 ml-auto">
          <Anchor
            href={setRoute(routesName.JOIN_MERCHANT)}
            className="text-white bg-offer px-3 py-2"
          >
            <>
              Gabung
              {" "}
              {routes.find((route: RoutesDataTypes) => { return route.name === routesName.JOIN_MERCHANT; })?.title as string}
            </>
          </Anchor>

          {/* <Link href="/">
              <a className="text-white bg-offer px-3 py-2">
                <i className="icofont-sale-discount h6"></i> Promos
              </a>
            </Link> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderNavbar;
