import { NextRouter, useRouter } from "next/router";
import cx from "classnames";

export default function SectionInvestorContent() {
  const router: NextRouter = useRouter();

  const { layout } = router.query;

  const bodyClass = cx("d-flex flex-column", {
    "py-4": layout !== "mobile",
  });

  return (
    <div className="d-flex flex-column py-4">
      {layout !== "mobile" && (
        <h6 className="mb-4 profile-title">MENJADI INVESTOR</h6>
      )}

      <ol className="pl-3">
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Latar Belakang</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Pandemi yang telah berjalan selama lebih dari 1 (satu) tahun ini
            sangat berdampak pada penurunan kualitas perekonomian rakyat
            Indonesia, khususnya pebisnis di bidang kuliner pada taraf UMKM
          </p>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Foodizmart hadir untuk membantu menggerakkan perekonomian dengan
            mengapresiasi karya UMKM yang layak untuk dipromosikan dengan
            kemudahan sistem pemesanan. Sebuah aplikasi pemesanan makanan yang
            akan memicu pergerakan perekonomian dengan munculnya karya karya
            baru di bidang kuliner yang selama ini dikelilingi keraguan dan
            bingung ingin memulai dari mana
          </p>
          <p className="mb-0" style={{ fontWeight: 400 }}>
            Foodizmart memberikan tempat kepada UMKM untuk berjualan secara
            online dengan difasilitasi berbagai metode pengiriman dan fitur
            menarik yang mendukung proses promosi
          </p>
        </li>
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Visi dan Misi</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Visi : Mempermudah pembeli menemukan produk yang tepat sesuai acara
            atau kebutuhannya
          </p>
          <p className="mb-0" style={{ fontWeight: 400 }}>
            Misi : Mempromosikan produk UMKM agar dikenal oleh masyarakat luas
            sehingga dapat membantu meningkatkan perekonomian masyarakat
          </p>
        </li>
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Jenis Produk dan Keunggulan yang Ditawarkan</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Foodizmart merupakan aplikasi pemesanan makanan yang mengusung tema
            acara yang pada umumnya dilakukan dalam kehidupan sosial
            bermasyarakat. Dengan adanya Foodizmart pelanggan akan terbantu untuk
            menemukan ide makanan yang tepat untuk acara yang akan mereka adakan
          </p>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Keunggulan yang ditawarkan oleh Foodizmart diantaranya:
          </p>
          <ol type="A" className="pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>
              <span>Kemudahan Proses Marketing</span>
              <p className="mb-2 text-muted">
                Berbagai fitur pada aplikasi diantaranya tampilan toko yang
                menarik (seperti instagram) dan fitur share toko serta share
                produk akan memudahkan Anda membagikan informasi kepada pelanggan
              </p>
            </li>
            <li>
              <span>Gratis Ongkir</span>
              <p className="mb-2 text-muted">
                Fitur diambil di toko akan menguntungkan merchant karena tidak
                perlu disibukkan melakukan pengantaran, bagi pelanggan pun akan
                diuntungkan dengan tidak perlu membayar biaya ongkir
              </p>
            </li>
            <li>
              <span>Banyak Pilihan Metode Pengiriman</span>
              <p className="mb-2 text-muted">
                Foodizmart menyediakan jasa pengiriman yang dapat disesuaikan
                dengan keinginan penjual / pembeli, untuk pengiriman jarak dekat
                diantaranya Grab Express, Go-Send dan MrSpeedy, sedangkan untuk
                pengiriman jarak jauh Foodizmart menyediakan JNE, J&T, Si Cepat,
                Cargo, dll
              </p>
            </li>
            <li>
              <span>Kemudahan Manajemen Produk</span>
              <p className="mb-2 text-muted">
                Foodizmart menyediakan fitur upload atau edit produk yang dapat
                dilakukan melalui aplikasi maupun melalui website
                seller.foodizmart.com
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Target Pasar</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Mengusung visi : Mempermudah pembeli menemukan produk yang tepat
            sesuai acara atau kebutuhannya. Foodizmart akan membidik pasar
            mahasiswa untuk produk catering, sedangkan untuk produk akikah,
            makanan sehat, cake and bakery, froozen food kami akan menargetkan
            pasar keluarga
          </p>
        </li>
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Strategi Pemasaran dan Penjualan</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Karena menargetkan masyarakat konsumen muda sebagai salah satu target
            pasar, strategi yang Kami gunakan untuk memasarkan juga lebih
            bervariasi. Mengingat target pasar adalah masyarakat yang melek
            internet, salah satu strategi pemasaran yang akan kami lakukan yakni
            melalui promosi di media sosial yang mana dapat diatur dengan mudah
            profil user yang akan dibidik
          </p>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Sebagai strategi tambahan, pada tahap pengenalan aplikasi kami akan
            mencoba bekerjasama dengan pemilik outlet makanan yang sudah
            berhasil dan terkenal di Jogja sehingga akan lebih mudah membangun
            kepercayaan pelanggan
          </p>
        </li>
        <li className="mb-3" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Resiko Bisnis</span>
          <p className="mb-2" style={{ fontWeight: 400 }}>
            Resiko bisnis dari aplikasi Foodizmart adalah jika terjadi gangguan
            pada server atau jaringan internet sehingga aplikasi tidak dapat
            digunakan. Namun kami mengantisipasinya dengan memilih server yang
            kompatibel
          </p>
        </li>
      </ol>
    </div>
  );
}
