import { useState, useEffect, useCallback } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import FormData from "form-data";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
// Components
import { Button, Anchor, Dialog, Alert } from "../../atoms";
// Utils
import { setRoute, convertToIdr, decoded } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
// Configs
import { routesName, uri } from "../../../configs";
// Data
import { RootState, AppDispatch } from "../../../reduxs";
import { serviceAddTransaction } from "../../../services";

interface PropsRenderShipperImage {
  type: string;
  data: any;
}

interface PropsSectionPayment {
  data: any;
  loadingSubmit: boolean;
  priceDetail: {
    shippingPrice: number;
    subtotalPrice: number;
    servicePrice: number;
    discountPrice: number;
  }
  doSetServicePrice: (value: number) => void;
  doSetLoadingSubmit: (value: boolean) => void;
}

const RenderSelectedPayment = (props: PropsRenderShipperImage) => {
  const { type, data } = props;

  switch (type) {
    case "virtual-account":
      return (
        <p className="card-text">
          {`Transfer, bank ${data?.name} (dicek otomatis)`}
        </p>
      );
    default:
      return (
        <p className="card-text">
          Silahkan pilih metode bayar
        </p>
      );
  }
};

export default function SectionPayment(props: PropsSectionPayment) {
  const { data, loadingSubmit, priceDetail, doSetServicePrice, doSetLoadingSubmit } = props;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [localPaymentMethod, setLocalPaymentMethod] = useLocalStorage("_payment", null);

  const router = useRouter();

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { carts, cartsId, isLoadingItem } = useSelector((state: RootState) => { return state.rdccarts; });

  useEffect(() => {
    const _payment = localStorage.getItem("_payment");

    if (_payment) {
      const paymentMethod = JSON.parse(_payment!);

      doSetServicePrice(paymentMethod?.data?.payment_fee);
    }
  }, []);

  const handleEditPayment = () => {
    setOpenDialog(true);
  };

  const handleSetPayment = (payMethod: any) => {
    doSetServicePrice(payMethod.payment_fee);

    setLocalPaymentMethod({
      type: "virtual-account",
      data: payMethod,
    });

    setOpenDialog(false);
  };

  const totalPembayaran = (priceDetail.shippingPrice + priceDetail.subtotalPrice + priceDetail.servicePrice) - priceDetail.discountPrice;

  const handleSubmitTransaction = useCallback(async () => {
    const _shipping = localStorage.getItem("_shipping");
    const _shipper = localStorage.getItem("_shipper");
    const _shippingdate = localStorage.getItem("_shippingdate");
    const _payment = localStorage.getItem("_payment");

    const shippingAddress = _shipping ? JSON.parse(_shipping) : null;
    const shipper = _shipper ? JSON.parse(_shipper) : null;
    const shippingDate = _shippingdate ? JSON.parse(_shippingdate) : null;
    const payment = _payment ? JSON.parse(_payment) : null;

    if (!shippingAddress) {
      toast.warning("Please select your shipping address");
      return;
    }

    if (!shippingDate || !shippingDate.date || !shippingDate.time) {
      toast.warning("Please select your shipping date and time");
      return;
    }

    if (!shipper) {
      toast.warning("Please select your shipper");
      return;
    }

    if (!payment) {
      toast.warning("Please select your payment method");
      return;
    }

    const _xSe = Cookies.get("_xSe");

    const dataSubmit = {
      session_request: decoded(_xSe || ""),
      shopping_cart_id: cartsId,
      delivery_datetime: `${shippingDate.date} ${shippingDate.time}:00`,
      member_address_id: shippingAddress.id,
      payment_method_code: payment.data.code,
      shipment_method: {
        type: shipper.shipment_method,
        data: JSON.stringify(shipper),
      },
      total_item_price: priceDetail.subtotalPrice,
      shipment_fee: priceDetail.shippingPrice,
      payment_fee: payment.data.payment_fee,
      discount: priceDetail.discountPrice,
      admin_fee: 0,
      total_payment: totalPembayaran,
    };

    try {
      doSetLoadingSubmit(true);

      const fd = new FormData();
      fd.append("session_request", dataSubmit.session_request);
      fd.append("shopping_cart_id", dataSubmit.shopping_cart_id);
      fd.append("delivery_datetime", dataSubmit.delivery_datetime);
      fd.append("member_address_id", dataSubmit.member_address_id);
      fd.append("payment_method_code", dataSubmit.payment_method_code);
      fd.append("shipment_method[type]", dataSubmit.shipment_method.type);
      fd.append("shipment_method[data]", dataSubmit.shipment_method.data);
      fd.append("total_item_price", dataSubmit.total_item_price);
      fd.append("shipment_fee", dataSubmit.shipment_fee);
      fd.append("payment_fee", dataSubmit.payment_fee);
      fd.append("discount", dataSubmit.discount);
      fd.append("admin_fee", dataSubmit.admin_fee);
      fd.append("total_payment", dataSubmit.total_payment);

      const response = await serviceAddTransaction(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("Submit Transaction", dataSubmit);
        Sentry.captureException(new Error("Something went wrong while submit transaction"), {
          tags: {
            section: "transaction",
          },
        });

        toast.error(response.message);
        doSetLoadingSubmit(false);

        return;
      }

      localStorage.removeItem("_cartsId");
      localStorage.removeItem("_shipping");
      localStorage.removeItem("_shippingdate");
      localStorage.removeItem("_shipper");
      localStorage.removeItem("_payment");
      localStorage.removeItem("_shop");

      dispatch({ type: "CLEAR_CARTS" });

      // doSetLoadingSubmit(false);

      router.push({
        pathname: setRoute(routesName.PAYMENT),
        query: { id: response.data.transaction.id },
      });
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        doSetLoadingSubmit(false);

        return;
      }

      Sentry.setContext("Submit Transaction", dataSubmit);
      Sentry.captureException(new Error(err.message), {
        tags: {
          section: "transaction",
        },
      });

      toast.error("Something went wrong while processing your request");
      doSetLoadingSubmit(false);
    }
  }, [totalPembayaran, priceDetail, cartsId]);

  return (
    <div className="card">
      <div className="card-header">
        <span>Metode Pembayaran & Pembelian</span>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-7">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <i className="icofont-credit-card icofont-size mr-3" />

                <RenderSelectedPayment
                  type={localPaymentMethod?.type}
                  data={localPaymentMethod?.data}
                />
              </div>

              <Button
                label={localPaymentMethod ? "Ubah" : "Pilih Pembayaran"}
                variant="link"
                size="md"
                icon="icofont-edit"
                className="p-0 text-muted"
                buttonProps={{
                  onClick: handleEditPayment,
                }}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex flex-column align-items-end">
              <div className="w-100 position-relative mb-4">
                <div className="row">
                  <div className="col text-right">
                    <p className="mb-3">Ongkos Kirim:</p>
                    <p className="mb-3">Biaya Layanan:</p>
                    <p className="mb-3">Diskon:</p>
                  </div>
                  <div className="col text-right text-muted">
                    <h6 className="mb-3 font-weight-light">{convertToIdr(priceDetail.shippingPrice)}</h6>
                    <h6 className="mb-3 font-weight-light">{convertToIdr(priceDetail.servicePrice)}</h6>
                    <h6 className="mb-3 font-weight-light">{convertToIdr(priceDetail.discountPrice)}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end bg-light rounded">
          <div className="d-flex align-items-center mr-5">
            <p className="mb-0 mr-3 text-danger" style={{ fontSize: 14, fontWeight: 500 }}>Total Pembayaran:</p>

            {isLoadingItem ? (
              <i className="icofont-spinner animate-spin mr-4" />
            ) : (
              <h6 className="mb-0 text-danger" style={{ fontWeight: 600 }}>{convertToIdr(totalPembayaran)}</h6>
            )}
          </div>

          <Button
            label="Beli, proses pembayaran"
            variant="danger"
            size="lg"
            buttonProps={{
              disabled: loadingSubmit,
              onClick: handleSubmitTransaction,
            }}
          />
        </div>
      </div>

      <Dialog open={openDialog} size="md">
        <div className="modal-header border-0">
          <Alert
            variant="warning"
            text="Hindari melakukan pembayaran pada tengah malam yaitu diatas jam 23:30 - 01:30"
            className="mb-0"
          />
        </div>
        <div className="modal-body">
          {data?.length === 0 && (
            <div className="card-input-select mb-2">
              <div className="d-flex flex-column align-items-center justify-content-center text-muted" style={{ height: 200 }}>
                <i className="icofont-credit-card mb-3" style={{ fontSize: 80 }} />
                <div className="text-center" style={{ width: 200 }}>
                  <p>Kanal pembayaran tidak ada / ditemukan</p>
                </div>
              </div>
            </div>
          )}

          {data?.length > 0 && data?.map((paymentType: any, i: number) => {
            return (
              <div className="d-flex flex-column mb-4" key={i.toString()}>
                <p className="text-muted" style={{ fontSize: 14 }}>
                  {paymentType.name}
                </p>

                {paymentType?.items?.filter((payment: any) => { return payment.status_active === "active"; })
                  .map((payment: any, x: number) => {
                    return (
                      <div key={x.toString()} className="card-input-select mb-2">
                        <input
                          type="radio"
                          id={`${payment.code}-${x}`}
                          name="payment"
                          value={payment.code}
                          checked={payment.code === localPaymentMethod?.data?.code}
                          onChange={() => { handleSetPayment(payment); }}
                        />

                        <label htmlFor={`${payment.code}-${x}`} className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <div style={{
                                  position: "relative",
                                  width: 100,
                                  height: 40,
                                }}
                                >
                                  <Image
                                    src={payment.logo}
                                    alt={`${payment.name.toLowerCase()}-logo`}
                                    layout="fill"
                                    sizes="(max-width: 100px) 100vw, 100px"
                                    objectFit="cover"
                                  />
                                </div>

                                <p className="card-text ml-5">
                                  {data.name === "Virtual Account" && (
                                    <>
                                      Bank
                                      {" "}
                                    </>
                                  )}

                                  {payment.name}
                                </p>
                              </div>
                              <span className="checkmark" />
                            </div>
                          </div>
                        </label>
                      </div>
                    );
                  })}
              </div>
            );
          })}

        </div>

        <div className="modal-footer">
          <Button
            label="Tutup"
            variant="light"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: () => { setOpenDialog(false); },
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
