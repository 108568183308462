import { useEffect, useState } from "react";
import Link from "next/link";
import { connect, useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
// Components
import { Anchor, Button } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
import { RoutesDataTypes, CartsItem } from "../../../configs/datatype";
// Utils
import { setRoute, decoded } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
// Data
import { RootState, AppDispatch, actionGetCarts } from "../../../reduxs";
// import {} from "../../../services";

interface CartProps {
  isLogin: boolean;
}

export default function Cart(props: CartProps) {
  const { isLogin } = props;

  // Reduxs
  const { carts } = useSelector((state: RootState) => { return state.rdccarts; });
  const { token } = useSelector((state: RootState) => { return state.rdcusers; });
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const _xSe = Cookies.get("_xSe");
    const sessionReq = _xSe ? decoded(_xSe) : token;
    const cartsId = localStorage.getItem("_cartsId");

    if (sessionReq && cartsId) {
      dispatch(actionGetCarts({ session_request: sessionReq, shopping_cart_id: cartsId }));
    }
  }, [token]);

  // useEffect(() => {
  //   if (isLogin && localcarts) {
  //     dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts: localcarts } } });
  //   }
  // }, [isLogin]);

  return (
    <Anchor
      href={setRoute(routesName.CARTS)}
      className="mr-2 p-2 position-relative bg-light text-dark rounded-pill icofont-size border shadow-sm"
    >
      <i className="icofont-cart" />

      {(isLogin && carts?.length > 0) && (
        <span
          className="d-flex align-items-center justify-content-center position-absolute bg-danger text-white rounded-circle"
          style={{
            width: 18,
            height: 18,
            right: -5,
            top: -5,
            fontSize: 12,
            fontFamily: "\"Poppins\", sans-serif",
            fontWeight: 600,
          }}
        >
          {carts?.length}
        </span>
      )}
    </Anchor>
  );
}
