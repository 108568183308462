import { useState, useEffect } from "react";
import Head from "next/head";
import { NextRouter, useRouter } from "next/router";
// Components
import { Logo, Anchor } from "../../atoms";
import { Footer } from "../../organisms";
// Configs
import { routesName } from "../../../configs";
import { RoutesDataTypes } from "../../../configs/datatype";
// Utils
import { navigationSingle } from "../../../utils";

interface NoHeaderProps {
  children: JSX.Element | JSX.Element[];
}

export default function MobileHeader(props: NoHeaderProps) {
  const { children } = props;

  const router: NextRouter = useRouter();

  const { layout } = router.query;

  return (
    <div className="position-relative">

      {layout !== "mobile" && (
        <div className="bg-white shadow-sm osahan-main-nav d-none d-md-block">
          <nav className="navbar navbar-expand-lg navbar-light bg-white osahan-header container">
            <Logo />

            <div className="ml-auto d-flex align-items-center">

              {navigationSingle(routesName.HOME).map((route: RoutesDataTypes, i: number) => {
                return (
                  <Anchor
                    key={i.toString()}
                    href={route.path}
                    className="text-muted"
                  >
                    {route.title}
                  </Anchor>
                );
              })}

            </div>
          </nav>
        </div>
      )}

      {children}

      {layout !== "mobile" && <Footer />}
    </div>
  );
}
