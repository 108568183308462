// Components
import { Button } from "../../../atoms";

interface PropsSectionHowToPay {
  data: any;
  instruction: Array<any>;
}

export default function SectionHowToPay(props: PropsSectionHowToPay) {
  const { data, instruction } = props;

  return (
    <div className="d-flex flex-column">
      <h6 className="mb-3">Panduan & cara pembayaran</h6>
      <div id="basicsAccordion">

        {instruction?.reverse()?.map((item: any, i: number) => {
          return (
            <div key={i.toString()} className="box border rounded mb-1 bg-white">
              <div id={`heading${i + 1}`}>
                <h5 className="mb-0">
                  <button
                    type="button"
                    className={`shadow-none btn btn-block d-flex align-items-center justify-content-between card-btn p-3 ${i === 0 ? "" : "collapsed"}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#how-to-pay-${i + 1}`}
                    aria-expanded={i === 0 ? "true" : "false"}
                    aria-controls={`how-to-pay-${i + 1}`}
                  >
                    <span>
                      Petunjuk Transfer
                      {" "}
                      {item?.type}
                    </span>
                    <i className="icofont-simple-down" />
                  </button>
                </h5>
              </div>

              <div
                id={`how-to-pay-${i + 1}`}
                className={`collapse ${i === 0 ? "show" : ""}`}
                data-bs-parent="#basicsAccordion"
              >
                <div className="card-body border-top p-3 text-muted">
                  <div className="d-flex flex-column">

                    {item?.data?.map((list: any, x: number) => {
                      return (
                        <div key={x.toString()} className="d-flex align-items-center mb-2">
                          <p
                            className="d-flex align-items-center justify-content-center mb-0"
                            style={{
                              width: 26,
                              height: 26,
                              backgroundColor: "#eee",
                              borderRadius: 50,
                            }}
                          >
                            {x + 1}
                          </p>

                          <p
                            className="mb-0 ml-3 flex-1"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: list }}
                          />
                        </div>
                      );
                    })}

                  </div>
                </div>
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
}
