import { useState, useEffect } from "react";
import Head from "next/head";
// Components
import { Header, Footer, HeaderMobile, Sidebar, ChatComponent } from "../../organisms";
// Configs
import { Locations } from "../../../configs/datatype";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  doSetLocation: (val: Locations) => void;
}

const Layout = (props: Partial<LayoutProps>) => {
  const { children, doSetLocation } = props;

  const [isLogin, setIsLogin] = useState<boolean>(false);

  useEffect(() => {
    setIsLogin(JSON.parse(sessionStorage.getItem("_xLo")!));
  }, []);

  const handleLoginStatus = (value: boolean) => {
    setIsLogin(value);
  };

  return (
    <div className="position-relative">
      <Sidebar />
      <HeaderMobile />
      <Header isLogin={isLogin} doSetLogin={handleLoginStatus} doSetLocation={doSetLocation!} />

      {children}

      <Footer />

      <ChatComponent isLogin={isLogin} />
    </div>
  );
};

export default Layout;
