import { useState, useCallback, useEffect, FormEvent } from "react";
import FormData from "form-data";
import Cookies from "js-cookie";
import _ from "lodash";
import { toast } from "react-toastify";
// Components
import { Input, Button, Select, Alert } from "../../../atoms";
// Utils
import { decoded } from "../../../../utils";
// Configs
import { AccountBank } from "../../../../configs/datatype";
// Data
import { serviceGetListsBank, serviceUpdateAccountBank, serviceCreateAccountBank } from "../../../../services";

interface PropsTabRekening {
  bankAccount: AccountBank;
  getBankAccount: () => void;
}

interface AccountData {
  id?: number;
  "bank_code"?: string;
  "account_number"?: string;
  "account_name"?: string;
}

export default function TabRekening(props: PropsTabRekening) {
  const { bankAccount, getBankAccount } = props;

  const [listsBank, setListsBank] = useState<Array<any>>([]);
  const [data, setData] = useState<AccountData>({});
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getListsBank = useCallback(async () => {
    try {
      const _xSe = Cookies.get("_xSe");
      const token = _xSe ? decoded(_xSe) : "";

      const fd = new FormData();

      fd.append("session_request", token);

      const response = await serviceGetListsBank(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      setListsBank(response.data.lists);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting bank list");
    }
  }, []);

  useEffect(() => {
    getListsBank();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(bankAccount)) {
      setData({
        id: bankAccount.id,
        bank_code: bankAccount.bank_code,
        account_number: bankAccount.bank_account_number,
        account_name: bankAccount.bank_account_name,
      });

      setEdit(true);
    }
  }, [bankAccount]);

  const handleSubmitBank = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Cookies
      const _xSe = Cookies.get("_xSe");
      const token = _xSe ? decoded(_xSe) : "";

      const fd = new FormData();

      fd.append("session_request", token);
      fd.append("bank_code", data.bank_code);
      fd.append("bank_account_name", data.account_name);
      fd.append("bank_account_number", data.account_number);

      let response = null;

      if (edit) {
        fd.append("id", data.id);

        response = await serviceUpdateAccountBank(fd);
      } else {
        response = await serviceCreateAccountBank(fd);
      }

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      setLoading(false);
      getBankAccount();

      toast.success(`Bank account successfully ${edit ? "updated" : "added"}`);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while sumbitting bank account");
    }
  }, [data, edit]);

  const handleChange = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  return (
    <div className="card">
      <div className="card-body">

        <Alert
          variant="warning"
          text="Periksa kembali rekening sebelum menyimpan. Pastikan nomor rekening yang dimasukkan sudah benar dan sesuai dengan bank."
        />

        <form className="mt-4" onSubmit={handleSubmitBank}>
          <div className="row">
            <div className="col-md-5">

              <Select
                id="bank"
                label="Di mana bank kamu?"
                selectProps={{
                  placeholder: "Pilih bank",
                  prepend: (
                    <span className="input-group-text"><i className="icofont-bank-alt" /></span>
                  ),
                  value: data?.bank_code || "Pilih bank",
                  disabled: loading,
                  onChange: (e) => { handleChange("bank_code", e.target.value); },
                }}
              >
                {listsBank.map((val: any, i: number) => {
                  return (
                    <option key={i.toString()} value={val.code} selected={val.code === data?.bank_code}>
                      {val.name}
                    </option>
                  );
                })}
              </Select>

            </div>
          </div>
          <div className="row">
            <div className="col-md-5">

              <Input
                id="no-rekening"
                label="Nomor Rekening"
                required
                inputProps={{
                  placeholder: "e.g. 1230121234",
                  prepend: (
                    <span className="input-group-text"><i className="icofont-id" /></span>
                  ),
                  value: data.account_number || "",
                  disabled: loading,
                  onChange: (e) => { handleChange("account_number", e.target.value); },
                }}
              />

            </div>
            <div className="col-md-7">
              <Input
                id="nama-pemilik-rekening"
                label="Nama Pemilik"
                required
                inputProps={{
                  placeholder: "e.g. Budi Sudirman",
                  value: data.account_name || "",
                  disabled: loading,
                  onChange: (e) => { handleChange("account_name", e.target.value); },
                }}
              />
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <Button
              label={edit ? "Ubah Rekening" : "Simpan Rekening"}
              variant="danger"
              size="md"
              buttonProps={{
                type: "submit",
                disabled: loading,
              }}
            />
          </div>
        </form>
      </div>
      <div className="card-footer">
        <span className="text-muted">Rekening ini akan digunakan sebagai rekening penerima saat penarikan saldo.</span>
      </div>
    </div>
  );
}
