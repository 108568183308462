import { useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import FormData from "form-data";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// Components
import { Button, Dialog } from "../../../atoms";
// Configs
import { routesName, uri } from "../../../../configs";
import { UserProfile, StatusTransaction, ConversationsAdd, CartsItem } from "../../../../configs/datatype";
// Utils
import { setRoute, decoded, convertToIdr } from "../../../../utils";
// Data
import { serviceUpdateTransactionStatus, serviceCancelTransaction, serviceAddItemCarts, serviceGetStoreDetail, serviceRemoveItemCarts } from "../../../../services";
import { RootState, AppDispatch, actionAddConversation, actionRemoveItemCarts, actionGetCarts, actionAddItemCarts } from "../../../../reduxs";

interface renderActionButton {
  data: any;
  loading: boolean;
  doUpdateStatus: (value: StatusTransaction) => void;
  doIsCancel: (value: boolean) => void;
  doContactSeller: () => void;
}
interface propsStatus {
  data: any;
}

const RenderActionButton = (props: renderActionButton) => {
  const { data, loading, doUpdateStatus, doIsCancel, doContactSeller } = props;

  const router = useRouter();

  const handleConfirmCancel = () => {
    doIsCancel(true);
  };

  switch (data?.status_transaction) {
    case "pending":
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-inline">
            <span>
              Terjadi kesalahan pesanan? atau bukan pesananmu?
              <br />
              batalkan pesanan,
            </span>
            {" "}
            <button
              className="btn btn-link btn-lg p-0"
              onClick={handleConfirmCancel}
            >
              Batalkan
            </button>
          </div>

          <div className="d-flex align-items-center">
            <Button
              label="Hubungi Penjual"
              variant="link"
              size="lg"
              className="text-muted mr-3"
              buttonProps={{
                onClick: doContactSeller,
              }}
            />

            <Button
              label="Bayar Sekarang"
              variant="danger"
              size="lg"
              buttonProps={{
                // disabled: true,
                onClick: () => {
                  router.push({
                    pathname: setRoute(routesName.PAYMENT),
                    query: { id: data?.id },
                  });
                },
              }}
            />
          </div>
        </div>
      );
    case "paid":
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-inline">
            <span>
              Terjadi kesalahan pesanan? atau bukan pesananmu?
              <br />
              batalkan pesanan,
            </span>
            {" "}
            <button
              className="btn btn-link btn-lg p-0"
              onClick={handleConfirmCancel}
            >
              Batalkan
            </button>
          </div>

          <div className="d-flex align-items-center">
            <Button
              label="Hubungi Penjual"
              variant="link"
              size="lg"
              className="text-muted mr-3"
              buttonProps={{
                onClick: doContactSeller,
              }}
            />

            <Button
              label={data?.shipment_method_detail_code === "BUY" ? "Pesanan Diambil" : "Pesanan Diterima"}
              variant="danger"
              size="lg"
              buttonProps={{
                disabled: true,
              }}
            />
          </div>
        </div>
      );
    case "confirm":
      return (
        <div className="d-flex align-items-center justify-content-end">
          <Button
            label="Hubungi Penjual"
            variant="link"
            size="lg"
            className="text-muted mr-3"
            buttonProps={{
              onClick: doContactSeller,
            }}
          />

          <Button
            label={data?.shipment_method_detail_code === "BUY" ? "Pesanan Diambil" : "Pesanan Diterima"}
            variant="danger"
            size="lg"
            buttonProps={{
              disabled: true,
            }}
          />
        </div>
      );
    case "ready":
      return (
        <div className="d-flex align-items-center justify-content-end">
          <Button
            label="Hubungi Penjual"
            variant="link"
            size="lg"
            className="text-muted mr-3"
            buttonProps={{
              onClick: doContactSeller,
            }}
          />

          <Button
            label={data?.shipment_method_detail_code === "BUY" ? "Pesanan Diambil" : "Pesanan Diterima"}
            variant="danger"
            size="lg"
            buttonProps={{
              disabled: loading,
              onClick: () => { doUpdateStatus("done"); },
            }}
          />
        </div>
      );
    case "done":
      return (
        <div className="d-flex align-items-center justify-content-end">
          <Button
            label="Hubungi Penjual"
            variant="link"
            size="lg"
            className="text-muted mr-3"
            buttonProps={{
              onClick: doContactSeller,
            }}
          />

          <Button
            label="Beli Lagi"
            variant="danger"
            size="lg"
          />
        </div>
      );
    default:
      return null;
  }
};

export default function DetailSectionPayment(props: propsStatus) {
  const { data } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [dialogChangeShop, setDialogChangeShop] = useState<boolean>(false);

  const router = useRouter();

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { carts, cartsId } = useSelector((state: RootState) => { return state.rdccarts; });

  const handleUpdateStatus = useCallback(async (trxStatus: StatusTransaction) => {
    setLoading(true);

    try {
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();

      fd.append("session_request", decoded(_xSe || ""));
      fd.append("transaction_food_id", data?.id);
      fd.append("status_transaction", trxStatus);

      const response = await serviceUpdateTransactionStatus(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setLoading(false);

        return;
      }

      sessionStorage.setItem("lastpostab", trxStatus);

      setLoading(false);

      router.push(setRoute(routesName.TRANSACTION));
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setLoading(false);

        return;
      }

      toast.error("Something went wrong while updating status");
      setLoading(false);
    }
  }, [data]);

  const handleCancel = useCallback(async () => {
    setLoading(true);

    try {
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();

      fd.append("session_request", decoded(_xSe || ""));
      fd.append("transaction_food_id", data?.id);

      const response = await serviceCancelTransaction(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setLoading(false);

        return;
      }

      toast.success("Transaction has been canceled");
      setLoading(false);

      router.push(setRoute(routesName.TRANSACTION));
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setLoading(false);

        return;
      }

      toast.error("Something went wrong while canceling transaction");
      setLoading(false);
    }
  }, [data]);

  const handleBuyAgain = useCallback(async () => {
    try {
      // Token
      const _xSe = Cookies.get("_xSe");

      const localShop = JSON.parse(localStorage.getItem("_shop") || "null");

      if (localShop && localShop.id !== data?.transaction?.shop_id) {
        setDialogChangeShop(true);

        return;
      }

      const fdItem = new FormData();
      fdItem.append("session_request", decoded(_xSe || ""));
      fdItem.append("shopping_cart_id", "");
      fdItem.append("product_id", data?.items[0].product_id);
      fdItem.append("qty", data?.items[0].qty);
      fdItem.append("notes", data?.items[0].notes);

      const responseCarts = await serviceAddItemCarts(fdItem);

      if (responseCarts.status === "failed" || responseCarts.status === "error") {
        toast.error("Gagal menambahkan item ke keranjang, silahkan ulangi lagi");

        return;
      }

      const { items, shopping_car_id } = responseCarts.data.shopping_cart;

      // Get Shop Detail
      const fdShop = new FormData();
      fdShop.append("shop_id", data?.transaction?.shop_id);

      const responseShop = await serviceGetStoreDetail(fdShop);

      if (responseShop.status === "failed" || responseShop.status === "error") {
        dispatch(actionRemoveItemCarts({ session_request: decoded(_xSe || ""), shopping_cart_id: shopping_car_id, product_id: items[0].product_id }));
        toast.error("Gagal menambahkan item ke keranjang, silahkan ulangi lagi");

        return;
      }

      const { shop } = responseShop.data;
      shop.logo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["shop-original"]}/${responseShop.data.shop.logo}`;

      localStorage.setItem("_shop", JSON.stringify(shop));
      localStorage.setItem("_cartsId", shopping_car_id);

      dispatch(actionGetCarts({ session_request: decoded(_xSe || ""), shopping_cart_id: shopping_car_id }));

      if (data?.items.length > 1) {
        data?.items.shift();

        for (let i = 1; i < data?.items.length; i += 1) {
          const item = {
            session_request: decoded(_xSe || ""),
            product_id: data?.items[i].product_id,
            qty: data?.items[i].qty,
            notes: data?.items[i].notes,
            shopping_cart_id: shopping_car_id,
          };

          dispatch(actionAddItemCarts(item));
        }
      }

      router.push(setRoute(routesName.CARTS));
      // setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while processing your request");
    }
  }, [data]);

  const handleClearCartChangeShop = async () => {
    try {
      // cookies
      const _xSe = Cookies.get("_xSe");

      // Delete old carts
      const removeItemCarts = carts.map((val: CartsItem, i: number) => {
        const fdOldItem = new FormData();

        fdOldItem.append("session_request", decoded(_xSe || ""));
        fdOldItem.append("shopping_cart_id", cartsId);
        fdOldItem.append("product_id", val.product_id);

        return serviceRemoveItemCarts(fdOldItem);
      });

      await Promise.all(removeItemCarts);

      localStorage.removeItem("_cartsId");
      localStorage.removeItem("_shipping");
      localStorage.removeItem("_shippingdate");
      localStorage.removeItem("_shipper");
      localStorage.removeItem("_payment");
      // localStorage.removeItem("_shop");

      setDialogChangeShop(false);

      handleBuyAgain();
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while removing item from carts");
    }
  };

  const handleAddConversation = () => {
    const ufk = localStorage.getItem("ufk");
    const userdata: UserProfile = ufk ? JSON.parse(decoded(ufk as string)) : {};

    const _xSe = Cookies.get("_xSe");

    const dataConversation: ConversationsAdd = {
      participants: [
        { user_id: userdata.id },
        { shop_id: data?.shop_id },
      ],
      last_message: "-",
    };

    dispatch({ type: "OPEN_CHAT" });
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch(actionAddConversation(dataConversation, { session: decoded(_xSe as string || ""), userid: userdata.id }));
  };

  let paymentMethod = "";

  switch (data?.payment_method_detail_code) {
    case "CREDIT":
      paymentMethod = "Pembayaran otomatis: Kartu Kredit";
      break;
    default:
      paymentMethod = `Transfer, bank ${data?.payment_description?.name} (dicek otomatis)`;
      break;
  }

  const handleIsCancel = (value: boolean) => {
    setIsCancel(value);
  };

  return (
    <div className="d-flex flex-column">
      <div className="card mb-4">
        <div className="card-header">
          <span>Rincian Pembayaran</span>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-7">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="icofont-credit-card icofont-size mr-3" />
                  <p className="card-text">{paymentMethod}</p>
                </div>

                {/* <Button
                  label="Lihat"
                  variant="light"
                  size="sm"
                /> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column align-items-end">
                <div className="w-100 position-relative">
                  <div className="row">
                    <div className="col text-right">
                      <p className="mb-3">Ongkos Kirim:</p>
                      <p className="mb-3">Biaya Layanan:</p>
                      <p className="mb-5">Diskon:</p>
                      <p className="mb-0" style={{ fontSize: 14, fontWeight: 500 }}>Total Belanja:</p>
                    </div>
                    <div className="col text-right text-muted">
                      <h6 className="mb-3 font-weight-light">{convertToIdr(data?.shipment_fee ? data?.shipment_fee : 0)}</h6>
                      <h6 className="mb-3 font-weight-light">{convertToIdr(data?.payment_fee ? data?.payment_fee + data?.admin_fee : 0)}</h6>
                      <h6 className="mb-5 font-weight-light">{convertToIdr(0)}</h6>
                      <h6 className="mb-0" style={{ fontWeight: 600 }}>{convertToIdr(data?.payment ? data?.payment : 0)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RenderActionButton
        data={data}
        loading={loading}
        doUpdateStatus={handleUpdateStatus}
        doIsCancel={handleIsCancel}
        doContactSeller={handleAddConversation}
      />

      <Dialog open={isCancel} size="sm">
        <div className="modal-body">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <i className="icofont-archive text-danger my-3" style={{ fontSize: "40px" }} />
            <h6 className="text-center" style={{ lineHeight: 1.5 }}>Apa kamu yakin ingin membatalkan pesanan ini?</h6>
            <p className="small text-muted m-0">{data?.product_name}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Nanti"
            size="md"
            variant="light"
            buttonProps={{
              onClick: () => { setIsCancel(false); },
            }}
          />

          <Button
            label="Ya, Batalkan"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: () => { setIsCancel(false); handleCancel(); },
            }}
          />
        </div>
      </Dialog>

      <Dialog open={dialogChangeShop} size="sm">
        <div className="modal-body p-4">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <i className="icofont-cart text-danger my-3" style={{ fontSize: "50px" }} />
            <h6 className="text-center" style={{ lineHeight: 1.5 }}>Ingin pesan dari toko ini saja?</h6>
            <p className="small text-muted m-0 text-center">Bisa saja, tapi kami akan menghapus item di keranjangmu saat ini dari toko sebelumnya terlebih dahulu.</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Batal"
            size="md"
            variant="light"
            buttonProps={{
              onClick: () => { setDialogChangeShop(false); },
            }}
          />

          <Button
            label="Ya, Silahkan"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: handleClearCartChangeShop,
            }}
          />
        </div>
      </Dialog>

      <Dialog open={loading} size="md">
        <div className="modal-body">
          <div className="d-flex align-items-center justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="d-flex flex-column ml-4">
              <h6 className="mb-1 text-danger" style={{ fontWeight: 500 }}>Mohon tunggu...</h6>
              <p className="mb-0 text-muted">Kami sedang memproses permintaan</p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
