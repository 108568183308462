import { useState, useEffect, FormEvent, ChangeEvent, KeyboardEvent } from "react";
import Cookies from "js-cookie";
import moment from "moment";
// Components
import { Button } from "../../atoms";

interface FormOtpProps {
  length?: number;
  data: any;
  doChange: (field: string, value: string) => void;
  doSubmit: (e: FormEvent<HTMLFormElement>) => void;
  doResend: () => void;
}

type InputFocus = KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>;

export default function FormOtp(props: FormOtpProps) {
  const { length = 6, data, doChange, doSubmit, doResend } = props;

  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const authDateEnd = JSON.parse(Cookies.get("authentication_date") || "");
    const now = moment();
    const end = moment(authDateEnd);

    const timer = setInterval(() => {
      const timeLeft = moment(end.diff(now));
      const formatted = timeLeft.format("ss");

      if (end.isAfter(now)) setSeconds(Number(formatted));
    }, 1000);

    return () => { clearTimeout(timer); };
  }, [seconds]);

  const handleResendOtp = () => {
    if (seconds > 0) return;

    doResend();
  };

  const inputfocus = (e: InputFocus) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;

      if (next > -1) {
        (e.target.form?.elements[next] as HTMLElement).focus();
      }
    } else {
      const next = e.target.tabIndex;

      if (next < length!) {
        (e.target.form?.elements[next] as HTMLElement).focus();
      }
    }
  };

  return (
    <>
      <form onSubmit={doSubmit}>
        <div className="row mb-5">
          {[...Array(length)].map((_, i) => {
            return (
              <div key={i.toString()} className="col px-2">
                <input
                  type="text"
                  placeholder="0"
                  className="form-control opt form-control-sm text-center"
                  maxLength={1}
                  tabIndex={i + 1}
                  value={data[`otp-${i + 1}`] || ""}
                  onChange={(e) => {
                    doChange(`otp-${i + 1}`, e.target.value);
                  }}
                  onKeyUp={(e: InputFocus) => {
                    inputfocus(e);
                  }}
                />
              </div>
            );
          })}
        </div>

        <Button
          label="Continue"
          variant="danger"
          size="lg"
          block
          buttonProps={{
            type: "submit",
            disabled: data[`otp-${length}`] === undefined || data[`otp-${length}`] === "",
            // disabled: Object.keys(data).length < length,
          }}
        />
      </form>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <Button
          variant="link"
          size="lg"
          label="Kirim ulang OTP"
          className="text-dark"
          buttonProps={{
            disabled: seconds > 0,
            onClick: handleResendOtp,
          }}
        />

        {seconds > 0 && <p className="text-muted mb-0">{seconds}</p>}
      </div>
    </>
  );
}

FormOtp.defaultProps = {
  length: 6,
};
