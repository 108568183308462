import { useState, useEffect } from "react";
import Image from "next/image";
// Components
import { Anchor } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
// Utils
import { setRoute, limitContentText } from "../../../utils";

interface ShopProps {
  shop: any;
}

export default function CardShop(props: ShopProps) {
  const { shop } = props;

  const [source, setSource] = useState<string>("/");

  useEffect(() => {
    if (shop && shop.logo) setSource(shop.logo);
  }, [shop]);

  return (
    <Anchor
      // href={`/detail/store/${shop.id}`}
      href={`${setRoute(routesName.SHOPS)}/${shop.id}`}
      className="text-dark"
    >
      <div className="list-card bg-white rounded overflow-hidden shadow-sm" style={{ height: 280 }}>
        <div className="position-relative overflow-hidden" style={{ width: "100%", height: 200 }}>
          <Image
            src={source}
            onError={() => { setSource("/images/image-default.jpg"); }}
            alt={shop?.name}
            layout="fill"
            objectFit="cover"
            loading="lazy"
            placeholder="blur"
            blurDataURL={shop?.logo}
          />
        </div>
        <div className="p-3 position-relative">
          <h6 className="mb-1 text-danger">{shop.name}</h6>
          <div className="d-flex align-items-center mt-2">
            <div className="d-flex align-items-center text-muted">
              <i className="icofont-location-pin mb-0 mr-1" />
              <p className="mb-0">
                {shop?.distance}
                {" "}
                km
              </p>
            </div>

            <div
              className="mx-2"
              style={{ height: 12, borderRight: "1px solid #ccc" }}
            />

            {shop.rating > 0 && (
              <div className="d-flex align-items-center">
                <i className="text-warning icofont-star mb-0 mr-1" />
                <p className="mb-0" style={{ fontWeight: 600 }}>{shop.rating}</p>
              </div>
            )}

            {shop?.rating > 0 && (
              <div
                className="mx-2"
                style={{ height: 12, borderRight: "1px solid #ccc" }}
              />
            )}

            <p className="mb-0">
              {limitContentText(shop.address, 25)}
            </p>
          </div>
        </div>
      </div>
    </Anchor>
  );
}
