import Link from "next/link";
import Image from "next/image";
import { useRouter, NextRouter } from "next/router";
// Components
import { Button, Anchor } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
// Utils
import { setRoute } from "../../../utils";

interface CardCategoryProps {
  category: any;
}

export default function CardCategory(props: CardCategoryProps) {
  const { category } = props;

  const router: NextRouter = useRouter();

  const handleCategory = () => {
    router.push({
      pathname: setRoute(routesName.PRODUCTS),
      query: {
        category: category.id,
      },
    });
  };

  return (
    <div className="col-c d-flex flex-column" style={{ height: "100%" }}>

      <button
        className="bg-white shadow-sm rounded text-center my-2 px-2 py-3 c-it"
        onClick={handleCategory}
        style={{
          height: "100%",
          outline: 0,
          border: 0,
        }}
      >
        <Image
          src={category.logo || "/"}
          className="img-fluid mx-auto"
          alt="img-card"
          width={50}
          height={50}
        />
        <p className="m-0 pt-2 text-muted text-center">{category.name}</p>
      </button>

    </div>
  );
}
