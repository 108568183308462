import { useRouter } from "next/router";
import cx from "classnames";
import moment from "moment";

interface detailSectionAlert {
  data: any
}

export default function DetailSectionAlert(props: detailSectionAlert) {
  const { data } = props;

  let status = "";
  let statusContent = "";

  switch (data?.status_transaction) {
    case "pending":
      status = "Belum dibayarkan";
      statusContent = `Mohon melakukan pembayaran sebelum ${moment(data?.payment_timelimit).format("DD-MMMM-YYYY, HH:mm")}`;
      break;
    case "paid":
      status = "Pembayaran dikonfirmasi";
      statusContent = `Proses verifikasi pembayaran. Penjual harus mengkonfirmasi sebelum ${moment(data?.delivery_date).format("DD-MMMM-YYYY, HH:mm")}`;
      break;
    case "confirm":
      status = "Diproses";
      statusContent = `Produk sedang disiapkan dan dikemas. Penjual harus mengirimkan sebelum ${moment(data?.delivery_date).format("DD-MMMM-YYYY, HH:mm")}`;
      break;
    case "ready":
      status = "Dikirim";
      statusContent = "Produk telah dikirimkan oleh penjual. Pengiriman akan terpantau oleh sistem.";
      break;
    case "done":
      status = "Selesai";
      statusContent = "Pesanan ini sudah selesai, dan sudah diterima pembeli.";
      break;
    default:
      status = "Barang dipesan";
      statusContent = "Pesanan telah akan segera diproses oleh sistem atau oleh penjual.";
      break;
  }

  const statusClassName = cx(
    "alert mb-4",
    {
      "alert-success": data?.status_transaction === "done",
      "alert-info": data?.status_transaction !== "done",
    },
  );

  return (
    <div className={statusClassName} role="alert">
      <div className="d-flex align-items-center">
        <i className="icofont-info" style={{ fontSize: 18 }} />
        <div className="d-flex flex-column ml-4">
          <h6 className="alert-heading mb-1" style={{ fontSize: ".85rem" }}>{status}</h6>
          <p className="mb-0">{statusContent}</p>
        </div>
      </div>
    </div>
  );
}
