import Image from "next/image";
import { NextRouter, useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { WhatsappShareButton, FacebookShareButton } from "react-share";
import { toast } from "react-toastify";
// Components
import { Anchor } from "../../../atoms";
import { BadgeProductFeature, CounterInput } from "../../../molecules";
// Configs
import { routesName, APP_BASEURL } from "../../../../configs";
import { Products, Shops, UserProfile, ConversationsAdd } from "../../../../configs/datatype";
// Utils
import { decoded, convertToIdr, setRoute } from "../../../../utils";
// Data
import { AppDispatch, actionAddConversation } from "../../../../reduxs";

interface DetailInformationProps {
  product: Partial<Products>;
  shop: Partial<Shops>;
  quantity: number;
  doQuantity: (val: number) => void;
}

const quickPickQuantity = [4, 8, 12, 20];

export default function CardDetailInformation(props: DetailInformationProps) {
  const { product, shop, quantity, doQuantity } = props;

  const router: NextRouter = useRouter();

  const dispatch: AppDispatch = useDispatch();

  const handleAddConversation = () => {
    const _xLo = JSON.parse(sessionStorage.getItem("_xLo")!);

    if (!_xLo) {
      toast.warning("Silahkan login terlebih dahulu");

      return;
    }

    const ufk = localStorage.getItem("ufk");
    const userdata: UserProfile = ufk ? JSON.parse(decoded(ufk as string)) : {};

    const token = Cookies.get("_xSe");

    const data: ConversationsAdd = {
      participants: [
        { user_id: userdata.id },
        { shop_id: shop.id },
      ],
      last_message: "-",
    };

    dispatch({ type: "OPEN_CHAT" });
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch(actionAddConversation(data, { session: decoded(token as string), userid: userdata.id }));
  };

  const shareContent = `Ada produk yang kamu cari nih "${product.name}" di Foodizmart - ${product.shop_name} dengan harga ${convertToIdr(product.price)}.`;

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <div className="pt-0">
        <h5 className="font-weight-normal">{product.name}</h5>

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mr-5">
              <p className="mb-0 mr-2">Kategori:</p>
              <p className="mb-0" style={{ fontWeight: 600 }}>{product.product_category_name}</p>
            </div>

            <div className="d-flex align-items-center mr-3">
              <p className="mb-0 mr-2">Min. order:</p>
              <p className="mb-0" style={{ fontWeight: 600 }}>{product.minimum_order_stock}</p>
            </div>
          </div>

          <h5 className="text-dark m-0" style={{ fontWeight: 600 }}>{convertToIdr(product.price)}</h5>
          {/* <span className="badge badge-danger ml-2">50% OFF</span> */}
        </div>
      </div>

      <div className="pt-4">
        <div className="d-flex align-items-center">
          {product.shipment_by_seller! === "true" && product.shipment_by_seller_maximum_radius! > 0 && (
            <BadgeProductFeature
              icon="icofont-fast-delivery"
              iconsize="h3"
              className="mr-2"
            >
              <p className="text-muted m-0 font-weight-bold">Free Ongkir</p>
              <p className="text-muted m-0" style={{ fontSize: "80%" }}>
                maks.
                {" "}
                {product.shipment_by_seller_maximum_radius}
                {" "}
                km
              </p>
            </BadgeProductFeature>
          )}

          {product.product_type === "readystock" && (
            <BadgeProductFeature
              icon="icofont-cube"
              iconsize="h5"
              className="mr-2"
            >
              <p className="text-muted m-0 font-weight-bold">Readystock</p>
              <p className="text-muted m-0" style={{ fontSize: "80%" }}>
                Stok tersisa
                {" "}
                {product.stock}
              </p>
            </BadgeProductFeature>
          )}

          {product.product_type === "preorder" && (
            <BadgeProductFeature
              icon="icofont-list"
              iconsize="h5"
              className="mr-2"
            >
              <p className="text-muted m-0 font-weight-bold">Pre-Order</p>
              <p className="text-muted m-0" style={{ fontSize: "80%" }}>
                pesanan min. h-
                {product.minimum_order_day}
              </p>
            </BadgeProductFeature>
          )}

          {product.rating_star! > 0 && (
            <BadgeProductFeature
              icon="icofont-star"
              iconsize="h5"
            >
              <p className="text-muted m-0" style={{ fontSize: "80%" }}>Rating</p>
              <p className="text-muted m-0 font-weight-bold">{product.rating_star}</p>
            </BadgeProductFeature>
          )}
        </div>
      </div>

      <div className="pt-4">
        <p className="font-weight-bold mb-2">Deskripsi</p>
        <p className="text-muted small mb-0">{product.description}</p>
      </div>

      <div className="pt-4">
        <div className="d-flex align-items-center justify-content-between p-3 border-top border-bottom">
          <div className="d-flex align-items-center pr-3">
            <Image src={shop.logo!} width={70} height={70} className="rounded-circle object-fit-cover" />
            <div className="d-flex flex-column ml-3">
              <Anchor
                href={`${setRoute(routesName.SHOPS)}/${shop.id}`}
                className="mb-1 text-dark"
                anchorProps={{
                  style: { fontSize: 14, fontWeight: 500 },
                }}
              >
                <span>{shop.name}</span>
              </Anchor>
              <div className="d-flex align-items-center">
                {product.rating_star! > 0 ? (
                  <div className="d-flex align-items-center mr-4">
                    <i className="text-warning icofont-star mb-0 mr-1" />
                    <p className="mb-0" style={{ fontWeight: 600 }}>{shop.rating}</p>
                  </div>
                ) : null}

                <div className="d-flex align-items-center text-muted">
                  <i className="icofont-location-pin mr-2" />
                  {`${shop.regional_kecamatan_name !== "" ? shop.regional_kecamatan_name : shop.regional_kelurahan_name}, ${shop.regional_kabupaten_name}`}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <button
              className="btn d-flex align-items-center text-dark rounded-circle"
              onClick={handleAddConversation}
            >
              <i className="icofont-support-faq h5 mb-0 mr-1" />
              <span style={{ fontSize: 10 }}>Hubungi</span>
            </button>

            <div className="dropdown ml-3">
              <a
                className="dropdown-toggle d-flex align-items-center text-dark osahan-location-drop rounded-circle"
                href="#"
                id="dropdownMenuButton"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icofont-share h5 mb-0 mr-1" />
                <span style={{ fontSize: 10 }}>Share</span>
              </a>

              <div
                className="dropdown-menu dropdown-menu-right top-profile-drop"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="d-flex">
                  <FacebookShareButton
                    url={`${APP_BASEURL}/produk/${product.id}`}
                    quote={shareContent}
                    hashtag="#foodizmart"
                    className="p-2"
                  >
                    <i className="icofont-facebook h4" />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={`${APP_BASEURL}/produk/${product.id}`}
                    title={shareContent}
                    separator=" :: "
                    className="p-2"
                  >
                    <i className="icofont-whatsapp h4" />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 details">
        <p className="font-weight-bold mb-2">Jumlah Pesanan</p>
        <div className="d-flex align-items-start justify-content-between">
          <div className="btn-group osahan-radio">
            {quickPickQuantity.map((item) => {
              return (
                <button
                  key={item.toString()}
                  className={`btn btn-secondary ${quantity === item ? "active" : ""}`}
                  disabled={product.minimum_order_stock! > item}
                  onClick={() => { doQuantity(item); }}
                >
                  {item}
                  {" "}
                  pcs
                </button>
              );
            })}
          </div>

          <div className="d-flex flex-column align-items-end">
            <CounterInput data={quantity} min={product.minimum_order_stock} doSet={doQuantity} />
            {/* <CounterInput data={quantity} min={1} doSet={doQuantity} /> */}

            <div className="d-flex align-items-center mt-3">
              <p className="text-muted font-weight-light mb-0 mr-4">subtotal:</p>
              <h6 className="text-muted mb-0" style={{ fontWeight: 600 }}>{convertToIdr((product.price! * quantity))}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
