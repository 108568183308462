import { useState } from "react";
import cx from "classnames";
// Components
import { Button } from "../../../atoms";
import { ShowedMap } from "../../../molecules";
// Configs
import { AddressItem } from "../../../../configs/datatype";

interface ItemAddressProps {
  data: AddressItem;
  localShipping: any;
  onChange: () => void;
}

export default function ItemListAddress(props: ItemAddressProps) {
  const { data, localShipping, onChange } = props;

  const [showMap, setShowMap] = useState(false);

  const handleShowMap = () => {
    setShowMap(!showMap);
  };

  // const containerClassName = cx("card-input-select", { "mb-2": !showMap, "mb-4": showMap });
  // const labelClassName = cx("card", { "mb-2": showMap });

  return (
    <div className="card-input-select mb-2">
      <input
        type="radio"
        id={data.id.toString()}
        name="delivery-address"
        value={data.address_alias}
        checked={data.id === localShipping?.id}
        onChange={onChange}
      />

      <label htmlFor={data.id.toString()} className="card overflow-hidden">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex flex-1 flex-column">
              <div className="d-flex align-items-center mb-3">
                <p className="card-title mb-0 mr-3" style={{ fontWeight: 600 }}>
                  {data.address_alias}
                </p>
                {data.default_address === "true" && <span className="badge badge-pill badge-light">Utama</span>}
              </div>
              <div className="d-flex align-items-center mb-2">
                <h6 className="card-text mb-0 mr-3" style={{ fontSize: 14 }}>{data.recipient_name}</h6>
                <p className="card-text text-muted">
                  (+62)
                  {" "}
                  {data.phone[0] === "0" ? data.phone.slice(1) : data.phone}
                </p>
              </div>
              <p className="card-text">
                {data.address}
              </p>
            </div>
            <span className="checkmark" />
          </div>

          <Button
            label={showMap ? "Tutup Map" : "Lihat Map"}
            variant="link"
            size="sm"
            className="p-0 text-danger"
            buttonProps={{
              type: "button",
              onClick: handleShowMap,
            }}
          />
        </div>

        <ShowedMap
          isOpened={showMap}
          id={`maps-${data.id}`}
          dataLocation={{
            coordinate: {
              longitude: Number(data.longitude),
              latitude: Number(data.latitude),
            },
          }}
        />
      </label>
    </div>
  );
}
