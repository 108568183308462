import { useState, useEffect } from "react";
import Image from "next/image";
// import { useRouter } from "next/router";
// Components
import { Anchor } from "../../atoms";
// Utils
import { setRoute, convertToIdr, limitContentText } from "../../../utils";
// Configs
import { routesName } from "../../../configs";
import { Products } from "../../../configs/datatype";

interface ProductProps {
  product: Products;
}

export default function CardProduct(props: ProductProps) {
  const { product } = props;

  const [source, setSource] = useState<string>("/");

  useEffect(() => {
    if (product && product.photo) setSource(product.photo);
  }, [product]);

  // const router = useRouter();

  // const handleShowDetail = (e: any) => {
  //   if (e.nativeEvent.MouseEvent.type === "mouseup") {
  //     router.push({
  //       pathname: `/detail/product/${product.id}`,
  //     });
  //   }
  // };

  return (
    <Anchor
      // href={`/detail/product/${product.id}`}
      href={`${setRoute(routesName.PRODUCTS)}/${product.id}`}
      className="text-dark"
    >
      <div className="list-card bg-white rounded overflow-hidden shadow-sm" style={{ height: 380 }}>
        <div className="list-card-image d-flex flex-column h-100 position-relative">

          <div className="product-card-image">
            <Image
              src={source}
              alt={product?.name}
              onError={() => { setSource("/icons/icon-product.svg"); }}
              layout="fill"
              objectFit="cover"
              loading="lazy"
              placeholder="blur"
              blurDataURL={product?.photo}
            />
          </div>

          <div className="d-flex flex-column flex-fill justify-content-between p-3">
            <div className="d-flex flex-column mb-3">
              <h6 className="mb-1">{limitContentText(product?.name!, 25)}</h6>
              <small className="text-muted" style={{ fontSize: "80%" }}>{product?.product_category_name}</small>

              <div className="d-flex align-items-center mt-2">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-light"
                  style={{
                    height: 35,
                    width: 35,
                  }}
                >
                  <i className="icofont-food-cart text-muted" style={{ fontSize: 18 }} />
                </div>
                <div
                  className="d-flex flex-column ml-2"
                  style={{ lineHeight: 1.7 }}
                >
                  <small>{limitContentText(product?.shop_name!, 30)}</small>
                  <small className="text-muted" style={{ fontSize: "80%" }}>
                    {product?.shop_regional_kelurahan_name}
                    {", "}
                    {product?.shop_regional_kabupaten_name}
                  </small>

                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <h6 className="price mb-1 text-danger">
                  {convertToIdr(product?.price)}
                </h6>

                <div className="d-flex align-items-center mx-0 mt-2" style={{ fontSize: 12 }}>
                  <div className="d-flex align-items-center text-muted">
                    <i className="icofont-location-pin mb-0 mr-1" />
                    <p className="mb-0">
                      {product?.distance}
                      {" "}
                      km
                    </p>
                  </div>

                  {product?.rating_star! > 0 || product?.qty_order! > 0 ? (
                    <>
                      {product?.rating_star! > 0 && (
                        <div
                          className="mx-2"
                          style={{ height: 12, borderRight: "1px solid #ccc" }}
                        />
                      )}

                      {product?.rating_star! > 0 && (
                        <div className="d-flex align-items-center">
                          <i className="text-warning icofont-star mb-0 mr-1" />
                          <p className="mb-0" style={{ fontWeight: 600 }}>{product?.rating_star}</p>
                        </div>
                      )}

                      {product?.qty_order! > 0 && (
                        <div
                          className="mx-2"
                          style={{ height: 12, borderRight: "1px solid #ccc" }}
                        />
                      )}

                      {product?.qty_order! > 0 && (
                        <p className="mb-0">
                          Terjual:
                          {" "}
                          {product?.qty_order}
                        </p>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <button className="btn btn-success btn-sm ml-auto">+</button>
            </div>
          </div>
        </div>
      </div>
    </Anchor>
  );
}
