import Link from "next/link";
import Image from "next/image";
// Configs
import { routesName } from "../../../configs";
// Utils
import { setRoute } from "../../../utils";

const Logo = () => {
  return (
    <Link href={setRoute(routesName.HOME)}>
      <a className="navbar-brand d-flex position-relative mr-0">
        <Image src="/images/logo.png" width={161} height={38} />
        {/* <Image src="/vercel.svg" width={130} height={20} /> */}
      </a>
    </Link>
  );
};

export default Logo;
