import cx from "classnames";

interface AlertProps {
  children?: JSX.Element | JSX.Element[];
  variant: "info" | "warning" | "error" | "success";
  text: string;
  className?: string;
  close?: () => void;
}

export default function Alert(props: AlertProps) {
  const { children, variant, text, className, close } = props;

  const alertclass = cx(
    className,
    "alert",
    {
      "alert-info": variant === "info",
      "alert-warning": variant === "warning",
      "alert-danger": variant === "error",
      "alert-success": variant === "success",
    },
  );

  const iconclass = cx({
    "icofont-info": variant === "info",
    "icofont-info-circle": variant === "warning",
    "icofont-exclamation-tringle": variant === "error",
    "icofont-check-circled": variant === "success",
  });

  return (
    <div className={alertclass} role="alert">
      <div className="d-flex align-items-center">
        <i className={iconclass} style={{ fontSize: 18 }} />
        <p className="ml-4 mb-0">
          {text}
        </p>
      </div>

      {children || null}
    </div>
  );
}

Alert.defaultProps = {
  children: undefined,
  className: undefined,
  close: undefined,
};
