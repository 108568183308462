import { useState, useEffect } from "react";
import "react-dates/initialize";
import { DayPickerSingleDateController } from "react-dates";
import moment, { Moment } from "moment";
import { toast } from "react-toastify";
// Components
import { Button, Dialog, Anchor } from "../../atoms";
import TimePicker from "./picker/timePicker";
// Utils
import { useLocalStorage } from "../../../utils/hooks";
import { Shops } from "../../../configs/datatype";

type CartShippingDateSectionProps = {
  setLocalShipper: any;
  doSetShippingPrice: (value: number) => void;
}

export default function CartShippingDateSection(props: CartShippingDateSectionProps) {
  const { setLocalShipper, doSetShippingPrice } = props;

  const [showDate, setShowDate] = useState<boolean>(false);
  const [showTime, setShowTime] = useState<boolean>(false);
  const [dates, setDates] = useState<Moment | null>(moment());
  const [datesFocus, setDateFocus] = useState<any>(false);

  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const [localDate, setLocalDate] = useLocalStorage("_shippingdate", null);

  useEffect(() => {
    const shop = JSON.parse(localStorage.getItem("_shop") || "");

    const [storeOpenH, storeOpenM, storeOpenS] = shop.shop_time_open.split(":");

    setHours(Number(storeOpenH));
    setMinutes(Number(storeOpenM));
  }, []);

  useEffect(() => {
    setDates(moment(localDate?.date || new Date()));

    if (localDate && localDate.time) {
      const [h, m] = localDate.time.split(":");

      setHours(Number(h));
      setMinutes(Number(m));
    }
  }, [localDate]);

  const handleSaveDates = () => {
    const _shipper = localStorage.getItem("_shipper");

    if (_shipper) {
      setLocalShipper(null);
      doSetShippingPrice(0);
    }

    setLocalDate({
      ...localDate,
      date: moment(dates).format("YYYY-MM-DD"),
    });

    setShowDate(false);
  };

  const handleSaveTimes = () => {
    const shop: Shops = JSON.parse(localStorage.getItem("_shop") || "");

    const [storeOpenH, storeOpenM, storeOpenS] = shop.shop_time_open.split(":");
    const [storeCloseH, storeCloseM, storeCloseS] = shop.shop_time_closed.split(":");

    const StoreOpenTime = `${storeOpenH}:${storeOpenM}`;
    const StoreCloseTime = `${storeCloseH}:${storeCloseM}`;

    const selectedTime = `${hours.toString().length === 1 ? `0${hours}` : hours}:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`;

    if (selectedTime < StoreOpenTime) {
      toast.warning(`You can't shipping your order before store opens at ${StoreOpenTime} Wib.`);
      return;
    }

    if (selectedTime > StoreCloseTime) {
      toast.warning(`You can't shipping your order after store closes at ${StoreCloseTime} Wib`);
      return;
    }

    setLocalDate({
      ...localDate,
      time: selectedTime,
    });

    setShowTime(false);
  };

  const handleShowDate = () => {
    setShowDate(true);
    setDateFocus(true);
  };

  const handleCloseDate = () => {
    setShowDate(false);
    setDateFocus(false);
  };

  const handleShowTime = () => {
    setShowTime(true);
  };

  const handleCloseTime = () => {
    setShowTime(false);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span>Waktu Pengiriman</span>
        </div>
        <div className="card-body">
          <div className="row align-items-center justify-content-between">
            <div className="col-xs-12 col-sm-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="icofont-ui-calendar icofont-size mr-3" />
                  {localDate && localDate.date ? (
                    <div className="d-flex flex-column">
                      <p className="card-text mb-1">Tanggal kirim</p>
                      <p className="card-text">{moment(localDate.date).format("DD MMMM YYYY")}</p>
                    </div>
                  ) : <p className="card-text">Tanggal pengiriman</p>}
                </div>

                <Button
                  label={localDate && localDate.date ? "Ubah" : "Pilih Tanggal"}
                  variant="link"
                  size="md"
                  icon="icofont-edit"
                  className="p-0 text-muted"
                  buttonProps={{
                    onClick: handleShowDate,
                  }}
                />
              </div>
            </div>

            <div className="col-xs-12 col-sm-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i className="icofont-clock-time icofont-size mr-3" />
                  {localDate && localDate.time ? (
                    <div className="d-flex flex-column">
                      <p className="card-text mb-1">Waktu kirim</p>
                      <p className="card-text">{localDate.time}</p>
                    </div>
                  ) : <p className="card-text">Waktu pengiriman</p>}
                </div>

                <Button
                  label={localDate && localDate.time ? "Ubah" : "Pilih Waktu"}
                  variant="link"
                  size="md"
                  icon="icofont-edit"
                  className="p-0 text-muted"
                  buttonProps={{
                    onClick: handleShowTime,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={showDate} size="md">
        <div className="modal-header border-0">
          <p className="modal-title" style={{ fontSize: 14 }}>Pilih tanggal pengiriman</p>
        </div>
        <div className="modal-body d-flex flex-column position-relative p-0" style={{ height: 530, overflow: "hidden" }}>

          <DayPickerSingleDateController
            initialVisibleMonth={() => { return moment(dates); }}
            date={dates}
            onDateChange={(date: Moment | null) => { return setDates(date); }}
            focused={datesFocus}
            onFocusChange={({ focused }: any) => { setDateFocus(focused); }}
            isOutsideRange={(date: Moment) => { return date.isBefore(moment().subtract(1, "days")) || date.isAfter(moment().add(3, "M")); }}
            numberOfMonths={1}
            weekDayFormat="ddd"
            daySize={60}
            hideKeyboardShortcutsPanel
            transitionDuration={0}
            withPortal
            orientation="vertical"
          />

        </div>
        <div className="modal-footer border-0">
          <Button
            label="Tutup"
            variant="light"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: handleCloseDate,
            }}
          />

          <Button
            label="Simpan"
            variant="danger"
            size="md"
            buttonProps={{
              onClick: handleSaveDates,
            }}
          />
        </div>
      </Dialog>

      <Dialog open={showTime} size="sm">
        <div className="modal-header border-0">
          <p className="modal-title" style={{ fontSize: 14 }}>Pilih waktu pengiriman</p>
        </div>
        <div className="modal-body">

          <TimePicker
            hour={hours}
            minute={minutes}
            onChangeHours={(hour: number) => { return setHours(hour); }}
            onChangeMinutes={(minute: number) => { return setMinutes(minute); }}
          />

        </div>
        <div className="modal-footer">
          <Button
            label="Tutup"
            variant="light"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: handleCloseTime,
            }}
          />

          <Button
            label="Simpan"
            variant="danger"
            size="md"
            buttonProps={{
              onClick: handleSaveTimes,
            }}
          />
        </div>
      </Dialog>
    </>
  );
}
