import { useState, useEffect, useRef } from "react";
import mapboxgl, { LngLatLike, MapMouseEvent } from "mapbox-gl";
// Components
import { Button } from "../../../atoms";
// Configs
import { Locations } from "../../../../configs/datatype";

interface MapProps {
  isOpened: boolean;
  dataLocation: Partial<Locations>;
  doChangeDataLocation: (value: Partial<Locations>) => void;
  initMapLocation: boolean;
}

export default function Map(props: MapProps) {
  const { isOpened, dataLocation, initMapLocation, doChangeDataLocation } = props;

  useEffect(() => {
    let maps: any = null;
    let center: LngLatLike = [110.4058563385276, -7.7594104029866315];

    // if (dataLocation.coordinate?.latitude! < -90 || dataLocation.coordinate?.latitude! > 90) {
    //   center = [dataLocation.coordinate?.latitude as number, dataLocation.coordinate?.longitude as number];
    // } else {

    center = [dataLocation.coordinate?.longitude as number, dataLocation.coordinate?.latitude as number];

    // }

    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN!;

    maps = new mapboxgl.Map({
      container: "maps",
      style: "mapbox://styles/mapbox/streets-v11",
      center,
      zoom: 15,
    });

    maps.addControl(new mapboxgl.NavigationControl());

    const marker = new mapboxgl.Marker({
      color: "#f52d56",
      draggable: true,
    })
      .setLngLat(center)
      .addTo(maps);

    const onChangeCoordinate = (lngLat: { lng: number, lat: number }) => {
      const temp = { ...dataLocation };

      temp.coordinate!.longitude = lngLat.lng as number;
      temp.coordinate!.latitude = lngLat.lat as number;

      doChangeDataLocation(temp);
    };

    maps.on("dblclick", (e: MapMouseEvent) => {
      onChangeCoordinate(e.lngLat);
    });

    marker.on("dragend", () => {
      onChangeCoordinate(marker.getLngLat());
    });

    return () => {
      maps.remove();
    };
  }, [dataLocation, initMapLocation]);

  return (
    <div
      style={{
        borderRadius: ".25rem",
        overflow: "hidden",
      }}
    >
      <div id="maps" style={{ minHeight: 450 }} />
    </div>
  );
}
