import { useState, useCallback } from "react";
import { useRouter, NextRouter } from "next/router";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import FormData from "form-data";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// Components
import { Button, Dialog } from "../../atoms";
// Configs
import { uri, routesName } from "../../../configs/constants";
import { UserProfile, StatusTransaction, ConversationsAdd, CartsItem } from "../../../configs/datatype";
// Utils
import { convertToIdr, setRoute, decoded } from "../../../utils";
// Data
import { serviceUpdateTransactionStatus, serviceGetDetailTransaction, serviceGetStoreDetail, serviceAddItemCarts, serviceRemoveItemCarts } from "../../../services";
import { RootState, AppDispatch, actionAddConversation, actionAddItemCarts, actionGetCarts, actionRemoveItemCarts } from "../../../reduxs";

interface ItemOrderStatusProps {
  status: string;
  data: any;
  doChangeTab?: (status: StatusTransaction) => void;
}

interface renderActionButton {
  status?: string;
  data: any;
  date?: any;
  doChangeTab?: (status: StatusTransaction) => void;
}

const RenderActionButton = (props: renderActionButton) => {
  const { status, data, date, doChangeTab } = props;

  const [dialogChangeShop, setDialogChangeShop] = useState<boolean>(false);

  const router: NextRouter = useRouter();

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { carts, cartsId } = useSelector((state: RootState) => { return state.rdccarts; });

  const handleShowDetail = () => {
    router.push(`${setRoute(routesName.TRANSACTION)}/${data?.id}`);
  };

  const handleUpdateStatus = useCallback(async (trxStatus: StatusTransaction) => {
    try {
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();
      fd.append("session_request", decoded(_xSe || ""));
      fd.append("transaction_food_id", data?.id);
      fd.append("status_transaction", trxStatus);

      const response = await serviceUpdateTransactionStatus(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      sessionStorage.setItem("lastpostab", trxStatus);

      doChangeTab!("done");
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        return;
      }

      toast.error("Failed to update status");
    }
  }, [data]);

  const handleBuyAgain = useCallback(async () => {
    try {
      // cookies
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();
      fd.append("session_request", decoded(_xSe || ""));
      fd.append("transaction_food_id", data?.id);
      fd.append("type", "buyer");

      const responseDetail = await serviceGetDetailTransaction(fd);

      if (responseDetail.status === "failed" && responseDetail.status === "error") {
        toast.error(responseDetail.message);

        return;
      }

      const itemsTransctions = { ...responseDetail.data.transaction.items };

      const localShop = JSON.parse(localStorage.getItem("_shop") || "null");

      if (localShop && localShop.id !== responseDetail.data.transaction.shop_id) {
        setDialogChangeShop(true);

        return;
      }

      const fdItem = new FormData();
      fdItem.append("session_request", decoded(_xSe || ""));
      fdItem.append("shopping_cart_id", "");
      fdItem.append("product_id", itemsTransctions[0].product_id);
      fdItem.append("qty", itemsTransctions[0].qty);
      fdItem.append("notes", itemsTransctions[0].notes);

      const responseCarts = await serviceAddItemCarts(fdItem);

      if (responseCarts.status === "failed" || responseCarts.status === "error") {
        toast.error("Gagal menambahkan item ke keranjang, silahkan ulangi lagi");

        return;
      }

      const { items, shopping_car_id } = responseCarts.data.shopping_cart;

      // Get Shop Detail
      const fdShop = new FormData();
      fdShop.append("shop_id", responseDetail.data.transaction.shop_id);

      const responseShop = await serviceGetStoreDetail(fdShop);

      if (responseShop.status === "failed" || responseShop.status === "error") {
        dispatch(actionRemoveItemCarts({ session_request: decoded(_xSe || ""), shopping_cart_id: shopping_car_id, product_id: items[0].product_id }));
        toast.error("Gagal menambahkan item ke keranjang, silahkan ulangi lagi");

        return;
      }

      const { shop } = responseShop.data;
      shop.logo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["shop-original"]}/${responseShop.data.shop.logo}`;

      localStorage.setItem("_shop", JSON.stringify(shop));
      localStorage.setItem("_cartsId", shopping_car_id);

      // for (let i = 0; i < items.length; i += 1) {
      //   items[i].product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${items[i].product_photo}`;
      // }

      // dispatch({ type: ActionTypes.ADD_CART_SUCCESS, payload: { data: { cartsId: shopping_car_id, carts: items } } });
      dispatch(actionGetCarts({ session_request: decoded(_xSe || ""), shopping_cart_id: shopping_car_id }));

      if (itemsTransctions.length > 1) {
        itemsTransctions.shift();

        for (let i = 1; i < itemsTransctions.length; i += 1) {
          const item = {
            session_request: decoded(_xSe || ""),
            product_id: itemsTransctions[i].product_id,
            qty: itemsTransctions[i].qty,
            notes: itemsTransctions[i].notes,
            shopping_cart_id: shopping_car_id,
          };

          dispatch(actionAddItemCarts(item));
        }
      }

      router.push(setRoute(routesName.CARTS));
      // setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while processing your request");
    }
  }, [data]);

  const handleClearCartChangeShop = async () => {
    try {
      // cookies
      const _xSe = Cookies.get("_xSe");

      // Delete old carts
      const removeItemCarts = carts.map((val: CartsItem, i: number) => {
        const fdOldItem = new FormData();

        fdOldItem.append("session_request", decoded(_xSe || ""));
        fdOldItem.append("shopping_cart_id", cartsId);
        fdOldItem.append("product_id", val.product_id);

        return serviceRemoveItemCarts(fdOldItem);
      });

      await Promise.all(removeItemCarts);

      localStorage.removeItem("_cartsId");
      localStorage.removeItem("_shipping");
      localStorage.removeItem("_shippingdate");
      localStorage.removeItem("_shipper");
      localStorage.removeItem("_payment");
      // localStorage.removeItem("_shop");

      setDialogChangeShop(false);

      handleBuyAgain();
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while removing item from carts");
    }
  };

  const handleAddConversation = () => {
    const ufk = localStorage.getItem("ufk");
    const userdata: UserProfile = ufk ? JSON.parse(decoded(ufk as string)) : {};

    const _xSe = Cookies.get("_xSe");

    const dataConversation: ConversationsAdd = {
      participants: [
        { user_id: userdata.id },
        { shop_id: data?.shop_id },
      ],
      last_message: "-",
    };

    dispatch({ type: "OPEN_CHAT" });
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch(actionAddConversation(dataConversation, { session: decoded(_xSe as string || ""), userid: userdata.id }));
  };

  switch (status) {
    case "pending":
      return (
        <>
          <p className="card-text mb-0 mr-4">
            <span className="text-muted">Bayar sebelum</span>
            {" "}
            {moment(date).format("DD-MMM-YYYY HH:mm")}
          </p>
          <Button
            label="Lihat"
            variant="light"
            size="md"
            className="mr-2"
            buttonProps={{
              onClick: handleShowDetail,
            }}
          />
          <Button
            label="Bayar Sekarang"
            variant="danger"
            size="md"
            buttonProps={{
              onClick: () => {
                router.push({
                  pathname: setRoute(routesName.PAYMENT),
                  query: { id: data?.id },
                });
              },
            }}
          />
        </>
      );
    case "paid":
      return (
        <>
          <p className="card-text mb-0 mr-4">
            <span className="text-muted">Dikirim sebelum</span>
            {" "}
            {moment(date).format("D-MMM-YYYY HH:mm")}
          </p>
          <Button
            label="Lihat"
            variant="light"
            size="md"
            className="mr-2"
            buttonProps={{
              onClick: handleShowDetail,
            }}
          />
          <Button
            label="Hubungi Penjual"
            variant="info"
            size="md"
            buttonProps={{
              onClick: handleAddConversation,
            }}
          />
        </>
      );
    case "confirm":
      return (
        <>
          <Button
            label="Lihat"
            variant="light"
            size="md"
            className="mr-2"
            buttonProps={{
              onClick: handleShowDetail,
            }}
          />
          <Button
            label="Hubungi Penjual"
            variant="info"
            size="md"
            buttonProps={{
              onClick: handleAddConversation,
            }}
          />
        </>
      );
    case "ready":
      return (
        <>
          <Button
            label="Lihat"
            variant="light"
            size="md"
            className="mr-2"
            buttonProps={{
              onClick: handleShowDetail,
            }}
          />
          <Button
            label="Pesanan Diterima"
            variant="danger"
            size="md"
            buttonProps={{
              onClick: () => { handleUpdateStatus("done"); },
            }}
          />
        </>
      );
    case "done":
      return (
        <>
          <Button
            label="Lihat"
            variant="light"
            size="md"
            className="mr-2"
            buttonProps={{
              onClick: handleShowDetail,
            }}
          />
          <Button
            label="Beli Lagi"
            variant="danger"
            size="md"
            buttonProps={{
              onClick: handleBuyAgain,
            }}
          />

          <Dialog open={dialogChangeShop} size="sm">
            <div className="modal-body p-4">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <i className="icofont-cart text-danger my-3" style={{ fontSize: "50px" }} />
                <h6 className="text-center" style={{ lineHeight: 1.5 }}>Ingin pesan dari toko ini saja?</h6>
                <p className="small text-muted m-0 text-center">Bisa saja, tapi kami akan menghapus item di keranjangmu saat ini dari toko sebelumnya terlebih dahulu.</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                label="Batal"
                size="md"
                variant="light"
                buttonProps={{
                  onClick: () => { setDialogChangeShop(false); },
                }}
              />

              <Button
                label="Ya, Silahkan"
                size="md"
                variant="danger"
                buttonProps={{
                  onClick: handleClearCartChangeShop,
                }}
              />
            </div>
          </Dialog>
        </>
      );
    default:
      return null;
  }
};

export default function ItemOrderStatus(props: ItemOrderStatusProps) {
  const { data, status, doChangeTab } = props;

  const imageurl = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/`;

  const subTotalFirstProduct = data?.transaction_description?.product_detail[0]?.qty * data?.transaction_description?.product_detail[0].price;

  let limitDate = null;

  switch (status) {
    case "pending":
      limitDate = data?.payment_timelimit;
      break;
    case "paid":
      limitDate = data?.delivery_date;
      break;
    default:
      limitDate = null;
  }

  return (
    <div className="card rounded shadow-sm">
      <div className="py-3 px-4">
        <div className="row no-gutters mb-3">
          <div className="col-md-3 position-relative rounded overflow-hidden">
            <Image
              src={imageurl + data?.transaction_description?.product_detail[0].product_photo}
              alt="..."
              layout="fill"
              objectFit="cover"
            />
          </div>

          <div className="col-md-9">
            <div className="card-body" style={{ paddingRight: 8 }}>
              <h6 className="card-title">
                {data?.transaction_description?.product_detail[0]?.product_name}
              </h6>
              <div className="mb-2 d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <p className="card-text mb-0 mr-2">jumlah:</p>
                  <h6 className="card-text mb-0">
                    x
                    {" "}
                    {data?.transaction_description?.product_detail[0]?.qty}
                  </h6>
                </div>
                <h6 className="card-text m-0">{convertToIdr(subTotalFirstProduct, "Rp")}</h6>
              </div>
              <p className="card-text text-muted">
                <i
                  className="icofont-food-cart mr-2 text-muted"
                  style={{ lineHeight: 1.5 }}
                />
                {data?.shop_name}
              </p>
            </div>
          </div>
        </div>

        <div className="row border-top border-bottom bg-light">
          <div className="col-md-5">
            <div className="py-2">
              <p className="card-text mb-0">
                {/* {data?.transaction_description.product_detail.length > 1 && "Ada "}
                {data?.transaction_description.product_detail.length}
                {" "}
                produk dipesan */}
                <span className="text-muted">Penerima:</span>
                {" "}
                {data?.delivery_address_description?.recipient_name}
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="py-2 d-flex flex-row align-items-center justify-content-end">
              <p className="card-text mb-0 mr-2">Total Pembayaran:</p>
              <h6 className="card-text mb-0">{convertToIdr(data?.payment, "Rp")}</h6>
            </div>
          </div>
        </div>

        <div className="row border-bottom">
          <div className="col-md-12 py-2 d-flex align-items-center">
            <i
              className="icofont-fast-delivery mr-3 text-muted"
              style={{ fontSize: 22, lineHeight: 0 }}
            />
            <p className="card-text">
              <span className="text-muted">{data?.delivery_address_description?.address}</span>
            </p>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3 d-flex">
            <p className="card-text mb-0 align-self-center text-muted">
              INV:
              {" "}
              {data?.order_id}
            </p>
          </div>
          <div className="col-md-9 d-flex align-items-center justify-content-end">

            <RenderActionButton
              status={status}
              data={data}
              date={limitDate}
              doChangeTab={doChangeTab}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

ItemOrderStatus.defaultProps = {
  doChangeTab: undefined,
};

RenderActionButton.defaultProps = {
  date: undefined,
  status: undefined,
  doChangeTab: undefined,
};
