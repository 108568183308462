import Head from "next/head";
// Components
import { HeaderMobile, Sidebar } from "../../organisms";

interface NoHeaderProps {
  children: JSX.Element | JSX.Element[];
}

export default function NoHeader(props: NoHeaderProps) {
  const { children } = props;

  return (
    <>
      <HeaderMobile />
      <Sidebar />

      <div className="fixed-bottom-padding">{children}</div>
    </>
  );
}
