import { useState, useCallback, useEffect, useRef, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
// Components
import Convertsations from "./conversations";
import Messages from "./messages";
// Utils
import { decoded } from "../../../utils";
// Configs
import { UserProfile, ConversationsData } from "../../../configs/datatype";
// Data
import { AppDispatch, RootState, actionGetConversation } from "../../../reduxs";

interface ChatComponentProps {
  isLogin: boolean;
}

export default function ChatComponent(props: ChatComponentProps) {
  const { isLogin } = props;

  // const [openChat, setOpenChat] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<UserProfile>>({});
  const [recipient, setRecipient] = useState<Partial<ConversationsData>>({});

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { isOpenChat, conversations } = useSelector((state: RootState) => { return state.rdcchats; });

  // const user: UserProfile = isLogin ? JSON.parse(decoded(localUser)) : null;

  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ufk = localStorage.getItem("ufk");
    const userdata: UserProfile = ufk ? JSON.parse(decoded(ufk as string)) : {};

    setUser(userdata);
  }, []);

  useEffect(() => {
    const token = Cookies.get("_xSe");
    const session = token ? decoded(token as string) : "";

    if (user.id) dispatch(actionGetConversation({ session, userid: user.id }));
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent | any) => {
      if (chatContainerRef.current && !chatContainerRef.current?.contains(e.target as HTMLElement)) {
        dispatch({ type: "CLOSE_CHAT" });
      }
    };

    if (isOpenChat) {
      document.addEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [isOpenChat]);

  const handleSetRecipient = (value: ConversationsData) => {
    setRecipient(value);
  };

  if ((!isLogin && conversations.length === 0) || conversations.length === 0) return null;

  return (
    <>
      {!isOpenChat ? (
        <button
          className="button-chat shadow-lg"
          onClick={() => { dispatch({ type: "OPEN_CHAT" }); }}
        >
          <i className="icofont-speech-comments" style={{ fontSize: 28 }} />
          <span className="ml-2" style={{ fontSize: 14, fontWeight: 600 }}>Chat</span>

          {true && (
            <span
              className="position-absolute bg-danger rounded-circle"
              style={{
                width: 8,
                height: 8,
                left: 44,
                top: 15,
              }}
            />
          )}
        </button>
      ) : (
        <div className="container-chat shadow-lg" ref={chatContainerRef}>
          <div className="d-flex flex-column position-relative flex-1">
            <button
              className="button-close-chat"
              onClick={() => { dispatch({ type: "CLOSE_CHAT" }); }}
            >
              <i className="icofont-rounded-down" />
            </button>

            <div className="d-flex h-100">
              <div className="border-right h-100" style={{ width: 185 }}>

                <Convertsations
                  user={user}
                  doSetRecipient={handleSetRecipient}
                />

              </div>
              <div className="flex-1 h-100">

                <Messages
                  user={user}
                  recipient={recipient}
                />

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
