import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { Button } from "../../atoms";
import { CardProduct } from "../../molecules";
// Configs
import { uri } from "../../../configs";
import { Products, Locations } from "../../../configs/datatype";
// Utils
import { convertToDistance } from "../../../utils";
// Data
import { serviceGetProductsInStore } from "../../../services";

type SectionStoreProductProps = {
  location: Partial<Locations>;
}

export default function SectionStoreProduct(props: SectionStoreProductProps) {
  const { location } = props;

  // const [location, setLocation] = useState<Partial<Locations>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<Products>>([]);
  const [more, setMore] = useState<boolean>(false);
  const [showing, setShowing] = useState<number>(0);

  const router = useRouter();

  const { id } = router.query;

  const getDataProducts = useCallback(async () => {
    setIsLoading(true);

    try {
      const fd = new FormData();
      fd.append("shop_id", id);

      const response = await serviceGetProductsInStore(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setIsLoading(false);

        return;
      }

      const formatedData = response.data.products.map((val: Products) => {
        const product = { ...val };

        const nearbyInMeters = location.coordinate ? convertToDistance(location.coordinate, { latitude: val.shop_latitude!, longitude: val.shop_longitude! }) : 0;
        const nearbyInKilometers = nearbyInMeters / 1000;

        // Type of size on image uri: original, medium, small
        product.photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${val.photo}`;
        product.information = JSON.parse(val.information);
        product.distance = nearbyInKilometers.toFixed(1);

        return product;
      });

      setProducts(formatedData);
      setIsLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setIsLoading(false);

        return;
      }

      toast.error("Something went wrong while getting products");
      setIsLoading(false);
    }
  }, [id, more, location]);

  useEffect(() => {
    getDataProducts();
  }, [id, more, location]);

  // useEffect(() => {
  //   const currentLocation = sessionStorage.getItem("location");

  //   if (currentLocation) setLocation(JSON.parse(currentLocation));
  // }, []);

  const handleShowMore = () => {
    setMore(!more);
  };

  let dataLength = products.length;

  if (products.length > 8 && more) {
    dataLength = products.length;
  }

  if (products.length > 8 && !more) {
    dataLength = 8;
  }

  return (
    <div className="container mb-5">
      <div className="d-flex align-items-center justify-content-between py-3">
        <h5 className="m-0">Produk di toko ini</h5>
        <div className="d-flex align-items-center">
          <small className="text-muted">
            Menampilkan
            {" "}
            {dataLength}
            {" "}
            {products.length > 8 && `dari ${products.length}`}
            {" "}
            produk
          </small>

          {products.length > 8 && (
            <Button
              label={!more ? "Tampilkan Lebih" : "Tampilkan Sedikit"}
              variant="danger"
              size="sm"
              outline
              className="ml-4"
              buttonProps={{
                onClick: handleShowMore,
              }}
            />
          )}
        </div>
      </div>
      <div className="row">

        {isLoading && [...Array(8)].map((_, i) => {
          return (
            <div key={i.toString()} className="col-12 col-sm-6 col-md-3 mb-3">
              <div className="list-card bg-light rounded overflow-hidden shadow-sm" style={{ height: 380 }} />
            </div>
          );
        })}

        {!isLoading && products.slice(0, dataLength).map((val: Partial<Products>, i: number) => {
          return (
            <div key={val.id} className="col-6 col-md-3 mb-3">
              <CardProduct product={val} />
            </div>
          );
        })}

      </div>
    </div>
  );
}
