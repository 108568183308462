import { useState } from "react";
import { useRouter, NextRouter } from "next/router";
import Image from "next/image";
import moment from "moment";
import _ from "lodash";
// Components
import { Button, Dialog } from "../../../atoms";

interface detailSectionInformation {
  data: any;
  doGetData: () => void;
}

export default function DetailSectionInformation(props: detailSectionInformation) {
  const { data, doGetData } = props;

  const [open, setOpen] = useState(false);

  const router: NextRouter = useRouter();

  const handleOpenDialog = () => {
    setOpen(true);

    if (data?.status_transaction === "ready") doGetData();
  };

  const shipper = data?.shipment_method_detail_data?.data || null;

  const shipperLogs = _.orderBy(data?.shipment_description?.tracking_description, ["created_date"], ["desc"]) || [];

  return (
    <div className="row mb-4">
      <div className="col-md-5 col-sm-12">
        <div className="card">
          <div className="card-header">
            <span>Metode Pengiriman</span>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column align-items-center">
                {(shipper?.shipment_method === "by_buyer") || (shipper?.shipment_method === "by_seller") ? (
                  <i className="icofont-package" style={{ fontSize: 40 }} />
                ) : (
                  <div
                    style={{
                      position: "relative",
                      width: 120,
                      height: 45,
                      marginBottom: ".5rem",
                    }}
                  >
                    <Image
                      src={shipper ? shipper.logo_url : "/"}
                      // width={120}
                      // height={45}
                      alt="logo"
                      layout="fill"
                      objectFit="contain"
                    />
                  </div>
                )}

                <p className="card-text mb-0">
                  {shipper?.name}
                  {(shipper?.shipment_method === "by_buyer") || (shipper?.shipment_method === "by_seller")
                    ? null
                    : (
                      <>
                        {" "}
                        -
                        {" "}
                        {shipper?.shipment_method[0].toUpperCase() + shipper?.shipment_method.slice(1)}
                      </>
                    )}
                </p>
              </div>

              {(data?.status_transaction === "ready" || data?.status_transaction === "done") && (
                <Button
                  variant="light"
                  size="sm"
                  label="Lacak"
                  buttonProps={{
                    onClick: handleOpenDialog,
                  }}
                />
              )}

              <Dialog
                open={open}
                onClose={() => { setOpen(false); }}
              >
                <div className="modal-header border-0">
                  <span>Informasi Kiriman</span>
                </div>
                <div className="modal-body">
                  <div className="shipping-track">

                    {shipperLogs.map((item: any, i: number) => {
                      return (
                        <div key={i.toString()} className="shipping-track-item">
                          <p className="dates">{moment(item.created_date).format("DD MMM YYYY, HH:mm")}</p>
                          <div className="description">
                            <p className="mb-0">{item.step}</p>

                            {i === 0 && item.step.includes("Dalam Perjalanan") && (
                              <p className="mb-0 mt-1 text-muted">Paket sedang dalam perjalanan menuju alamat.</p>
                            )}

                            {i === 0 && item.step.includes("Berhasil Dikirim") && (
                              <p className="mb-0 mt-1 text-muted">Paket sudah diterima oleh pemesan.</p>
                            )}

                            {shipperLogs.length - 2 === i + 1 && item.step.includes("Diterima Partner") && (
                              <p className="mb-0 mt-1 text-muted">Mulai proses pengiriman oleh kurir.</p>
                            )}

                            {shipperLogs.length === i + 1 && item.step.includes("Order Masuk") && (
                              <p className="mb-0 mt-1 text-muted">Pesanan sudah siap di diambil kurir.</p>
                            )}

                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
                <div className="modal-footer border-0">
                  <Button
                    variant="light"
                    size="sm"
                    label="Tutup"
                    buttonProps={{
                      onClick: () => { setOpen(false); },
                    }}
                  />
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-7 col-sm-12">
        <div className="card">
          <div className="card-header">
            <span>Info Pengiriman</span>
          </div>
          <div className="alert alert-warning mb-0 rounded-0">
            <div className="d-flex align-items-center justify-content-between">
              <p className="card-text mb-0">Waktu Pengiriman:</p>
              <div className="d-flex align-items-center">
                <p className="card-text mb-0">
                  <strong>{moment(data?.delivery_date).format("DD MMMM YYYY")}</strong>
                </p>
                <p className="card-text mb-0 ml-3">
                  <strong>{moment(data?.delivery_date).format("HH:mm")}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            <p className="card-text mb-3">Alamat Pengiriman:</p>
            <div className="d-flex align-items-center mb-1">
              <p className="card-title mb-0">
                <strong>{data?.delivery_address_description?.recipient_name}</strong>
              </p>
              <p className="card-text mb-0 ml-4">
                +62
                {" "}
                {data?.delivery_address_description?.phone[0] === "0" ? data?.delivery_address_description?.phone.slice(1) : data?.delivery_address_description?.phone}
              </p>
            </div>
            <p className="card-text">
              <i>
                {data?.delivery_address_description?.address}
                {data?.delivery_address_description?.address_detail !== "" && (
                  <>
                    <br />
                    {data?.delivery_address_description?.address_detail}
                  </>
                )}
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
