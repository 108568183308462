import { useState, useCallback, FormEvent, ChangeEvent } from "react";
import { useRouter, NextRouter } from "next/router";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
// Components
import { Input, Select, Button, Anchor, Alert } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
import { DataRegister } from "../../../configs/datatype";
// Utils
import { encoded, decoded, setRoute, useLocalStorage } from "../../../utils";
import { registerValidation } from "../../../utils/validation";
// Data
import { serviceRegister } from "../../../services";

type ErrorType = DataRegister & string;
type Field = "handphone" | "country_code" | "name" | "password" | "confirm_password";
type CountryCode = {
  code: string;
  label: string;
  value: string;
}

const countryCodeList: Array<CountryCode> = [
  {
    code: "id",
    label: "Indonesia",
    value: "62",
  },
];

export default function SectionMainSignup() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Partial<DataRegister>>({});
  const [error, setError] = useState<Partial<ErrorType>>({});
  const [agree, setAgree] = useState<boolean>(false);
  const [errorAgree, setErrorAgree] = useState<string>("");
  const [registerStatus, setRegisterStatus] = useState<string>("success");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

  const router: NextRouter = useRouter();

  const [registerResponse, setRegisterResponse] = useLocalStorage("verification", null);

  const handleForm = (field: Field, value: string) => {
    setData({ ...data, [field]: value });
  };

  const handleError = (value: Partial<DataRegister>) => {
    setError(value as ErrorType);
  };

  const handleAgreeToggle = () => {
    setAgree(!agree);
  };

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fcm = localStorage.getItem("fcm");
    const inputValid = registerValidation(data, handleError);

    if (!inputValid) {
      toast.warning("Please fill all required field");
      return;
    }

    if (inputValid && !agree) {
      setErrorAgree("Silahkan baca dan setujui syarat ketentuan");
      return;
    }

    setErrorAgree("");
    setIsLoading(true);

    const dataSubmit = {
      type: "phone",
      handphone: `0${data.handphone}`,
      name: data.name,
      password: data.password,
      confirm_password: data.confirm_password,
      fcm_token: decoded(fcm as string),
      reference_id: "",
    };

    try {
      const fd = new FormData();
      fd.append("type", dataSubmit.type);
      fd.append("phone", dataSubmit.handphone);
      fd.append("name", dataSubmit.name);
      fd.append("password", dataSubmit.password);
      fd.append("confirm_password", dataSubmit.confirm_password);
      fd.append("fcm_token", dataSubmit.fcm_token);

      const response = await serviceRegister(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("User Registration", dataSubmit);
        Sentry.captureException(new Error(`Failed to register :: ${response.message}`), {
          tags: {
            section: "registration",
          },
        });

        setRegisterStatus("failed");
        setError("Register fail, your phone number is already registered");
        setIsLoading(false);

        return;
      }

      dataSubmit.reference_id = response.data.reference_id;

      Cookies.set("authentication", "true", { expires: 1 });
      Cookies.set("authentication_date", JSON.stringify(moment().add(1, "minutes").format("YYYY/MM/DD HH:mm:ss")), { expires: 1 });

      setRegisterStatus("success");
      setRegisterResponse({ type: "register", data: dataSubmit });
      setIsLoading(false);

      router.push(setRoute(routesName.OTP));
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setIsLoading(false);

        return;
      }
      Sentry.setContext("User Registration", dataSubmit);
      Sentry.captureException(new Error(`Failed to register :: ${err.message}`), {
        tags: {
          section: "registration",
        },
      });

      toast.error("Something went wrong while registering");
      setIsLoading(false);
    }
  }, [data, agree]);

  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const regex = /^[0-9]*$/;

    if (value.length > 0 && value[0] === "0") {
      value = value.substring(1);
    }

    if (value.length > 14) {
      value = value.slice(0, 14);
    }

    if (regex.test(value)) {
      handleForm("handphone", value);
    }
  };

  return (
    <section className="osahan-main-body" style={{ padding: "50px 0" }}>
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-6 pl-lg-5">
            <div className="osahan-signup shadow bg-white p-4 rounded">
              <div className="p-3">
                <h5 className="my-0">Registrasi</h5>
                <p className="small mb-4 mt-1">Isi data dengan benar untuk melanjutkan</p>

                {(registerStatus !== "success" && typeof error === "string") ? (
                  <Alert
                    variant="warning"
                    text={typeof error === "string" ? error : "Error aplikasi"}
                  />
                ) : null}

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <Select
                        id="code-area"
                        label="Kode"
                        size="lg"
                        selectProps={{
                          value: data.country_code || "62",
                          // placeholder: "Kode Negara",
                          disabled: isLoading,
                          onChange: (e) => { handleForm("country_code", e.target.value); },
                        }}
                      >
                        {countryCodeList.map((val: CountryCode, i: number) => {
                          return (
                            <option key={i.toString()} value={val.value} selected={val.value === data.country_code}>
                              +
                              {val.value}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-9">
                      <Input
                        id="no-handphone"
                        label="No. Handphone"
                        size="lg"
                        // required
                        error={error.handphone !== undefined}
                        inputProps={{
                          value: data.handphone || "",
                          placeholder: "cth. 87839800006",
                          disabled: isLoading,
                          onChange: handleInputPhoneNumber,
                        }}
                        helper={error.handphone ? error.handphone : ""}
                      />
                    </div>
                  </div>

                  <Input
                    id="nama"
                    label="Nama Lengkap"
                    size="lg"
                    // required
                    error={error.name !== undefined}
                    inputProps={{
                      value: data.name || "",
                      placeholder: "cth. Rizky Excel",
                      disabled: isLoading,
                      onChange: (e) => { handleForm("name", e.target.value); },
                    }}
                    helper={error.name ? error.name : ""}
                  />

                  <Input
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    size="lg"
                    error={error.password !== undefined}
                    inputProps={{
                      value: data.password || "",
                      disabled: isLoading,
                      append: (
                        <Button
                          variant="light"
                          size="lg"
                          icon={showPassword ? "icofont-eye-blocked" : "icofont-eye"}
                          buttonProps={{
                            type: "button",
                            onClick: () => { setShowPassword(!showPassword); },
                          }}
                        />
                      ),
                      onChange: (e) => { handleForm("password", e.target.value); },
                    }}
                    helper={error.password ? error.password : ""}
                  />

                  <Input
                    id="confirm-password"
                    label="Konfirmasi Password"
                    type={showPasswordConfirm ? "text" : "password"}
                    size="lg"
                    error={error.confirm_password !== undefined}
                    inputProps={{
                      value: data.confirm_password || "",
                      disabled: isLoading,
                      append: (
                        <Button
                          variant="light"
                          size="lg"
                          icon={showPasswordConfirm ? "icofont-eye-blocked" : "icofont-eye"}
                          buttonProps={{
                            type: "button",
                            onClick: () => { setShowPasswordConfirm(!showPasswordConfirm); },
                          }}
                        />
                      ),
                      onChange: (e) => { handleForm("confirm_password", e.target.value); },
                    }}
                    helper={error.confirm_password ? error.confirm_password : ""}
                  />

                  <div className="d-flex flex-column mb-4">
                    <div className="custom-control custom-checkbox mb-1">
                      <input
                        id="register-agreement"
                        type="checkbox"
                        className="custom-control-input"
                        onChange={handleAgreeToggle}
                        disabled={isLoading}
                      />

                      <label
                        htmlFor="register-agreement"
                        className="custom-control-label"
                        style={{ lineHeight: 2, fontStyle: "italic" }}
                      >
                        Saya setuju dengan
                        {" "}
                        <Anchor href={setRoute(routesName.TERM)} anchorProps={{ target: "_blank" }}>Syarat & Ketentuan</Anchor>
                        {" "}
                        yang berlaku.
                      </label>
                    </div>

                    {errorAgree !== "" && <span className="text-danger">{errorAgree}</span>}
                  </div>

                  <Button
                    variant="danger"
                    label="Daftarkan akun"
                    size="lg"
                    block
                    buttonProps={{
                      type: "submit",
                      disabled: isLoading,
                    }}
                  />
                </form>

                <p className="text-center mt-3 mb-0">
                  Sudah punya akun?
                  {" "}
                  <Anchor href={setRoute(routesName.LOGIN)} className="text-danger ml-1">
                    Mulai login
                  </Anchor>
                </p>

                <p className="text-muted text-center small m-0 py-3">atau</p>

                <Button
                  variant="light"
                  label="Kembali ke beranda"
                  size="lg"
                  block
                  buttonProps={{
                    onClick: () => { router.push(setRoute(routesName.HOME)); },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
