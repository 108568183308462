import { useState, useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// Components
import { Input } from "../../atoms";
// Data
import { serviceGetLocationListByMapbox, serviceGetLocationListByGoogle, serviceGetLocationByGoogle } from "../../../services";
// Configs
import { KindOfType, PlaceLocations, Locations } from "../../../configs/datatype";

interface FormCariLokasiProps {
  doChangeKind: (value: KindOfType) => void;
  doChangeDataLocation: (value: Partial<Locations>) => void;
}

interface ListLocationContainerProps {
  open: boolean;
  locations: Array<PlaceLocations>;
  onClose?: () => void;
  doSelectLocation: (value: Partial<PlaceLocations>) => void;
}

function ListLocationContainer(props: ListLocationContainerProps) {
  const { open, locations, onClose, doSelectLocation } = props;

  const [localLocation, setLocalLocation] = useState<Partial<Locations>>({});

  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const location: Locations = JSON.parse(sessionStorage.getItem("location") as string);

    if (location) setLocalLocation(location);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (listContainerRef.current && !listContainerRef.current.contains(e.target)) {
        onClose && onClose();
      }
    };

    if (open) {
      document.addEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [listContainerRef, open, onClose]);

  const handleSetManualAddress = () => {
    const companyLocation: Locations = {
      address: "Jln. Kemuning IV No. 450 Perumnas Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta, Indonesia",
      kelurahan: "Condongcatur",
      kecamatan: "Depok",
      kabupaten: "Sleman",
      provinsi: "D.I. Yogyakarta",
      coordinate: {
        latitude: -7.759420379061339,
        longitude: 110.40596552125646,
      },
    };

    doSelectLocation({ location: companyLocation });
  };

  if (!open) {
    return null;
  }

  return (
    <div ref={listContainerRef} className="list-location-container">
      <div className="group-select-manual">
        <button
          className="btn btn-select"
          onClick={() => { doSelectLocation({ location: localLocation as Locations }); }}
        >
          <i className="icofont-focus icofont-size mr-2" />
          Gunakan lokasi saat ini?
        </button>
        <button
          className="btn btn-select"
          onClick={handleSetManualAddress}
        >
          <span className="text-muted">Tidak ada di list? Isi alamat manual</span>
        </button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          maxHeight: 180,
        }}
      >
        {locations.map((address: PlaceLocations, i: number) => {
          return (
            <button
              key={i.toString()}
              className="btn"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px 20px",
                textAlign: "left",
                lineHeight: "normal",
                borderTop: "1px solid #ddd",
              }}
              onClick={() => { doSelectLocation(address); }}
            >
              <span>{address.title}</span>
              <span>{address.place}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default function FormCariLokasi(props: FormCariLokasiProps) {
  const { doChangeKind, doChangeDataLocation } = props;

  const [keywords, setKeywords] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [result, setResult] = useState<Array<PlaceLocations>>([]);

  const getLocation = useCallback(async (texts: string) => {
    try {
      // const _location: Locations = sessionStorage.getItem("location") && JSON.parse(sessionStorage.getItem("location")!);

      // const response: Array<PlaceLocations> = await serviceGetLocationListByGoogle(texts, `${_location.coordinate.latitude},${_location.coordinate.longitude}`);
      const response: Array<PlaceLocations> = await serviceGetLocationListByMapbox(texts);

      setResult(response);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something when wrong when getting location");
    }
  }, []);

  useEffect(() => {
    if (keywords.length < 3) {
      setResult([]);
    }
  }, [keywords]);

  useEffect(() => {
    if (keywords.length >= 3) {
      getLocation(keywords);
    }
  }, [keywords]);

  const handleSelectLocation = (value: Partial<PlaceLocations>) => {
    doChangeDataLocation(value.location!);

    setKeywords("");
    setResult([]);
    setOpen(false);
    doChangeKind("input-location");
  };

  return (
    <div className="position-relative" style={{ width: 600 }}>
      <Input
        id="cari-lokasi"
        label="Dimana lokasi tujuan pengirimanmu?"
        inputProps={{
          placeholder: "Tulis nama jalan / gedung / perumahan",
          autoComplete: "off",
          prepend: (
            <span className="input-group-text">
              <i className="icofont-search-1" />
            </span>
          ),
          value: keywords || "",
          onChange: (e) => { setKeywords(e.target.value); },
          onClick: () => { setOpen(true); },
        }}
      />

      <ListLocationContainer
        open={open}
        locations={result}
        onClose={() => { setOpen(false); }}
        doSelectLocation={handleSelectLocation}
      />
    </div>
  );
}

ListLocationContainer.defaultProps = {
  onClose: undefined,
};
