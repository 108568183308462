import { useState, useCallback, useEffect, FormEvent, ChangeEvent } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getMessaging, isSupported } from "firebase/messaging";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
// Components
import { Anchor, Input, Select, Button, Alert } from "../../atoms";
// Configs
import { routesName, fbsc, uri } from "../../../configs";
import { RoutesDataTypes, DataLogin, CartsItem } from "../../../configs/datatype";
import { firebase } from "../../../configs/firebase";
// Utils
import { encoded, decoded, setRoute, useLocalStorage } from "../../../utils";
import { loginValidation } from "../../../utils/validation";
// Data
import { serviceLogin } from "../../../services";
import { AppDispatch, RootState, actionAddItemCarts, actionLoginUser } from "../../../reduxs";
import ActionTypes from "../../../reduxs/actions/users/actionTypes";

type ErrorType = DataLogin & string;

type CountryCode = {
  code: string;
  label: string;
  value: string;
}

const countryCodeList: Array<CountryCode> = [
  {
    code: "id",
    label: "Indonesia",
    value: "62",
  },
];

export default function Login() {
  const [data, setData] = useState<Partial<DataLogin>>({});
  const [error, setError] = useState<Partial<ErrorType>>({});
  const [initialPageLoad, setInitialPageLoad] = useState<string>("success");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { token, isLogin } = useSelector((state: RootState) => { return state.rdcusers; });

  const retrieveFcm = useCallback(async () => {
    try {
      const isMessagingSupport = await isSupported();

      if (!isMessagingSupport) {
        toast.warning("Your browser does not support push notifications");

        return;
      }

      const messaging = getMessaging(firebase);

      const fcmToken = await getToken(messaging, { vapidKey: decoded(fbsc.pubkey) });

      if (!fcmToken) {
        toast.warning("An error occurred while retrieving fcm token");

        return;
      }

      localStorage.setItem("fcm", encoded(fcmToken));
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something when wrong when retrieving fcm token");
    }
  }, []);

  useEffect(() => {
    const fcmToken = localStorage.getItem("fcm");

    if (!fcmToken) retrieveFcm();
  }, []);

  const handleForm = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  const handleError = (value: Partial<DataLogin>) => {
    setError(value as ErrorType);
  };

  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const regex = /^[0-9]*$/;

    if (value.length > 0 && value[0] === "0") {
      value = value.substring(1);
    }

    if (value.length > 14) {
      value = value.slice(0, 14);
    }

    if (regex.test(value)) {
      handleForm("handphone", value);
    }
  };

  const handleLogin = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError({});
    setInitialPageLoad("success");

    const fcm = localStorage.getItem("fcm");
    const inputValid = loginValidation(data, handleError);

    if (!inputValid) return;

    setLoading(true);

    dispatch({ type: ActionTypes.LOGIN_USER_REQUEST });

    const dataSubmit = {
      type: "phone",
      username: `0${data.handphone}`,
      password: data.password,
      fcm_token: decoded(fcm as string),
    };

    try {
      const fd = new FormData();
      fd.append("type", dataSubmit.type);
      fd.append("username", dataSubmit.username);
      fd.append("password", dataSubmit.password);
      fd.append("fcm_token", dataSubmit.fcm_token);

      const response = await serviceLogin(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("User Login", dataSubmit);
        Sentry.captureException(new Error(`Failed to login :: ${response.message}`), {
          tags: {
            section: "login",
          },
        });

        setInitialPageLoad("failed");
        setError("Email / No. Handphone tidak ditemukan");
        setLoading(false);

        dispatch({ type: ActionTypes.LOGIN_USER_FAILURE, payload: { data: null, message: response.message } });

        return;
      }

      const profile = { ...response.data.profile };

      profile.avatar = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original.profile}/${response.data.profile.avatar}`;
      profile.avatar_file = response.data.profile.avatar;

      const sessionToken = encoded(response.data.session_token);
      const user = encoded(JSON.stringify(profile));

      sessionStorage.setItem("_xLo", JSON.stringify(true));
      localStorage.setItem("ufk", user);

      Cookies.set("_xSe", sessionToken, { expires: 5 });

      dispatch({ type: ActionTypes.LOGIN_USER_SUCCESS, payload: { data: profile, token: response.data.session_token } });

      const to = sessionStorage.getItem("to");
      const _cartsItem = localStorage.getItem("_cartsItem");
      const _shop = localStorage.getItem("_shop");

      if (_shop && JSON.parse(_shop).member_id === profile.id) {
        sessionStorage.removeItem("to");
        localStorage.removeItem("_cartsItem");
        localStorage.removeItem("_shop");

        dispatch({ type: "CLEAR_CARTS" });

        router.back();
        toast.warning("Tidak bisa membeli produk sendiri");
        setLoading(false);

        return;
      }

      if (_cartsItem) {
        const localCarts: Array<CartsItem> = JSON.parse(_cartsItem);

        const item = {
          session_request: response.data.session_token,
          product_id: localCarts[0].product_id,
          qty: localCarts[0].qty,
          notes: "",
          shopping_cart_id: "",
        };

        dispatch(actionAddItemCarts(item));
        localStorage.removeItem("_cartsItem");
      }

      if (to) {
        router.push(to);
        setLoading(false);

        return;
      }

      router.push(setRoute(routesName.TRANSACTION));

      setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setInitialPageLoad("failed");
        dispatch({ type: ActionTypes.LOGIN_USER_FAILURE, payload: { data: null, message: err.message } });
        setLoading(false);

        return;
      }

      Sentry.setContext("User Login", dataSubmit);
      Sentry.captureException(new Error(`Failed to login :: ${err.message}`), {
        tags: {
          section: "login",
        },
      });

      toast.error("An error occurred while logging in");
      setInitialPageLoad("failed");
      dispatch({ type: ActionTypes.LOGIN_USER_FAILURE, payload: { data: null, message: err.message } });
      setLoading(false);
    }
  }, [data]);

  return (
    <section className="osahan-main-body osahan-signin-main">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center vh-100">
          <div className="col-lg-6  pl-lg-5">
            <div className="osahan-signin shadow-sm bg-white p-4 rounded">
              <div className="p-3">
                <h5 className="my-0">Mulai menggunakan</h5>
                <p className="small mb-4 mt-1">Gunakan tombol masuk untuk melanjutkan.</p>

                {(initialPageLoad === "failed" && typeof error === "string") ? (
                  <Alert
                    variant="warning"
                    text={typeof error === "string" ? error : "Error aplikasi"}
                  />
                ) : null}

                <form onSubmit={handleLogin}>
                  {/* was-validated */}
                  <div className="row">
                    <div className="col-md-3">
                      <Select
                        id="code-area"
                        label="Kode"
                        size="lg"
                        selectProps={{
                          // placeholder: "Kode Negara",
                          value: data.country_code || "62",
                          onChange: (e) => { handleForm("country_code", e.target.value); },
                        }}
                      >
                        {countryCodeList.map((val: CountryCode, i: number) => {
                          return (
                            <option key={i.toString()} value={val.value} selected={val.value === data.country_code}>
                              +
                              {val.value}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="col-md-9">
                      <Input
                        id="handphone"
                        size="lg"
                        label="No. Handphone"
                        error={error.handphone !== undefined}
                        inputProps={{
                          value: data.handphone || "",
                          placeholder: "cth. 87839800006",
                          onChange: handleInputPhoneNumber,
                          disabled: loading,
                        }}
                        helper={error.handphone ? error.handphone : ""}
                      />
                    </div>
                  </div>

                  <Input
                    id="password"
                    size="lg"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    error={error.password !== undefined}
                    inputProps={{
                      value: data.password || "",
                      append: (
                        <Button
                          variant="light"
                          size="lg"
                          icon={showPassword ? "icofont-eye-blocked" : "icofont-eye"}
                          buttonProps={{
                            type: "button",
                            onClick: () => { setShowPassword(!showPassword); },
                          }}
                        />
                      ),
                      onChange: (e) => { handleForm("password", e.target.value); },
                      disabled: loading,
                    }}
                    helper={error.password ? error.password : ""}
                  />

                  <Button
                    variant="danger"
                    label="Masuk ke akun"
                    size="lg"
                    block
                    loading={loading}
                    buttonProps={{
                      type: "submit",
                      disabled: loading,
                    }}
                  />
                </form>

                {/* <p className="text-muted text-center small m-0 py-3">atau</p>

                <Button
                  variant="light"
                  label="Gunakan akun google"
                  size="lg"
                  block
                /> */}

                <div className="mt-4 d-flex justify-content-between">
                  <p className="ml-2 mb-0">
                    Belum punya akun?
                    {" "}
                    <Anchor href="/user/register" variant="danger">
                      Daftar
                    </Anchor>
                  </p>
                  <Anchor href="/user/lupa-password" variant="danger">
                    Lupa password?
                  </Anchor>
                </div>

                <p className="text-muted text-center small m-0 py-3">atau</p>

                <Button
                  variant="light"
                  label="Kembali ke beranda"
                  size="lg"
                  block
                  buttonProps={{
                    onClick: () => { router.push(setRoute(routesName.HOME)); },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
