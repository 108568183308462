import { useState, useEffect } from "react";
import { NextRouter, useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
// Components
import { Button, Dialog } from "../../atoms";
import { ItemAddress, ShowedMap } from "../../molecules";
import ItemListAddress from "./itemListAddress";
// Configs
import { routesName } from "../../../configs";
import { AddressItem } from "../../../configs/datatype";
// Utils
import { decoded, setRoute } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
// Data
import { RootState, AppDispatch, actionGetAddresses } from "../../../reduxs";

type CartAddressSectionProps = {
  setLocalShipper: any;
  doSetShippingPrice: (value: number) => void;
}

export default function CartAddressSection(props: CartAddressSectionProps) {
  const { setLocalShipper, doSetShippingPrice } = props;

  const [data, setData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [showDialogMap, setShowDialogMap] = useState(false);

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { addressLists } = useSelector((state: RootState) => { return state.rdcaddress; });

  const [localShipping, setLocalShipping] = useLocalStorage("_shipping", null);

  const router: NextRouter = useRouter();

  useEffect(() => {
    const _xSe = Cookies.get("_xSe");

    dispatch(actionGetAddresses({ token: decoded(_xSe || "") }));
  }, []);

  useEffect(() => {
    const addressListIndex = addressLists?.findIndex((address: any) => { return address.default_address === "true"; }) || 0;

    if (addressLists?.length > 0) {
      setLocalShipping(addressLists?.[addressListIndex]);
    }
  }, [addressLists]);

  const handleDialogShipping = () => {
    setOpenDialog(true);
  };

  const handleSetShipping = (address: any) => {
    const _shipper = localStorage.getItem("_shipper");

    if (_shipper) {
      setLocalShipper(null);
      doSetShippingPrice(0);
    }

    setLocalShipping(address);
    setOpenDialog(false);
  };

  const handleAddNewAddress = () => {
    setOpenDialog(false);

    router.push(setRoute(routesName.ADDRESS));
  };

  const handleToggleDialogShowMap = () => {
    setShowDialogMap(!showDialogMap);
  };

  return (
    <div className="card">
      <div className="card-header">
        <span>Alamat Pengiriman</span>
      </div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <i className="icofont-focus icofont-size mr-3" />
            {localShipping ? (
              <div className="d-flex flex-column align-items-start">
                <p className="card-text mb-1">
                  <span style={{ fontWeight: 600 }}>{localShipping?.recipient_name}</span>
                  {" "}
                  (+62)
                  {" "}
                  {localShipping?.phone?.[0] === "0" ? localShipping?.phone?.slice(1) : localShipping?.phone}
                </p>
                <p className="card-text">{localShipping?.address}</p>
                <Button
                  label="Lihat Map"
                  variant="link"
                  size="sm"
                  className="p-0 text-danger"
                  buttonProps={{
                    onClick: handleToggleDialogShowMap,
                  }}
                />
              </div>
            ) : <p className="card-text">Silahkan pilih alamat pengiriman</p>}
          </div>

          <Button
            label={localShipping ? "Ubah" : "Pilih Alamat"}
            variant="link"
            size="md"
            icon="icofont-edit"
            className="p-0 text-muted"
            buttonProps={{
              onClick: handleDialogShipping,
            }}
          />
        </div>
      </div>

      <Dialog open={openDialog} size="md">
        <div className="modal-header border-0">
          <p className="modal-title" style={{ fontSize: 14 }}>Pilih alamat pengiriman</p>
        </div>

        <div className="modal-body">
          {addressLists.length === 0 && (
            <div className="card-input-select mb-2">
              <div className="d-flex flex-column align-items-center justify-content-center text-muted" style={{ height: 200 }}>
                <i className="icofont-delivery-time mb-3" style={{ fontSize: 80 }} />
                <div className="d-flex flex-column align-items-center" style={{ width: 200 }}>
                  <p className="text-center">Alamat pengiriman masih kosong</p>
                  <Button
                    label="Tambah"
                    variant="danger"
                    size="sm"
                    outline
                    buttonProps={{
                      onClick: handleAddNewAddress,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {addressLists.length > 0 && addressLists?.map((address: AddressItem, i: number) => {
            return (
              <ItemListAddress
                key={i.toString()}
                data={address}
                localShipping={localShipping}
                onChange={() => { handleSetShipping(address); }}
              />
            );
          })}
        </div>

        <div className="modal-footer justify-content-start">
          <div className="d-flex flex-grow-1 align-items-center justify-content-between">
            <Button
              label="Tambah alamat baru"
              variant="link"
              icon="icofont-location-pin"
              size="md"
              className="text-danger"
              buttonProps={{
                onClick: handleAddNewAddress,
              }}
            />

            <Button
              label="Tutup"
              variant="light"
              size="md"
              className="text-muted"
              buttonProps={{
                onClick: () => { setOpenDialog(false); },
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog open={showDialogMap} size="lg">
        <div className="modal-body p-0">
          <ShowedMap
            isOpened={showDialogMap}
            id={`maps-${localShipping?.id}`}
            dataLocation={{
              coordinate: {
                longitude: localShipping?.longitude,
                latitude: localShipping?.latitude,
              },
            }}
            height={400}
          />
        </div>
        <div className="modal-footer">
          <Button
            label="Tutup"
            variant="light"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: handleToggleDialogShowMap,
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
