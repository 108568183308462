import { useState } from "react";
// Components
import { Button } from "../../atoms";
import { CardProduct } from "../../molecules";

interface ProductHomeProps {
  data: any;
  isLoading: boolean;
}

export default function ProductHome(props: ProductHomeProps) {
  const { data, isLoading } = props;

  const [more, setMore] = useState(false);

  const handleShowMore = () => {
    setMore(!more);
  };

  let dataLength = data.length;

  if (data.length > 8 && more) {
    dataLength = data.length;
  }

  if (data.length > 8 && !more) {
    dataLength = 8;
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between py-3">
        <h5 className="m-0">Produk Disekitarmu</h5>

        <div className="d-flex align-items-center">
          <small className="text-muted">
            Menampilkan
            {" "}
            {dataLength}
            {" "}
            {data.length > 8 && `dari ${data.length}`}
            {" "}
            produk
          </small>

          {data.length > 8 && (
            <Button
              label={!more ? "Tampilkan Lebih" : "Tampilkan Sedikit"}
              variant="danger"
              size="sm"
              outline
              className="ml-4"
              buttonProps={{
                onClick: handleShowMore,
              }}
            />
          )}
        </div>
      </div>
      <div className="pick_today">
        <div className="row">

          {isLoading && [...Array(8)].map((_, i) => {
            return (
              <div key={i.toString()} className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="list-card bg-light rounded overflow-hidden shadow-sm" style={{ height: 380 }} />
              </div>
            );
          })}

          {!isLoading && data.slice(0, dataLength).map((val: any, i: number) => {
            return (
              <div key={val.id} className="col-12 col-sm-6 col-md-3 mb-3">
                <CardProduct product={val} />
              </div>
            );
          })}

        </div>
      </div>
    </div>
  );
}
