import { useState, useEffect, useCallback } from "react";
import Image from "next/image";
import Slider from "react-slick";
import { toast } from "react-toastify";
// Component
import { Anchor } from "../../atoms";
// Configs
import { uri } from "../../../configs/constants";
import { AssetsUri, SlidersHome } from "../../../configs/datatype";
// Utils
import { decoded } from "../../../utils";
// Data
import { serviceGetSliderHome } from "../../../services";

type SliderHomeProps = {
  asseturi: any;
}

type SliderHomeItemProps = {
  data: SlidersHome;
}

function RenderSliderItem(props: SliderHomeItemProps) {
  const { data } = props;

  const [source, setSource] = useState<string>("/");

  useEffect(() => {
    if (data && data.path) setSource(data.path);
  }, [data]);

  return (
    <div className="osahan-slider-item px-2">
      <Anchor href="/" className="d-block position-relative overflow-hidden rounded" anchorProps={{ style: { height: 220 } }}>
        <Image
          src={source}
          onError={() => { setSource("/images/image-default.jpg"); }}
          alt="Foodizmart Slide Promo"
          layout="fill"
          objectFit="cover"
          priority
          placeholder="blur"
          blurDataURL={data?.path}
        />
      </Anchor>
    </div>
  );
}

function SliderHome(props: Partial<SliderHomeProps>) {
  const { asseturi } = props;

  const [data, setData] = useState<Array<SlidersHome>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getSlidersHome = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await serviceGetSliderHome();

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setIsLoading(false);

        return;
      }

      const { lists } = response.data;

      // const assets = localStorage.getItem("assets");
      // const assetURL = assets ? JSON.parse(decoded(assets)) : null;

      const formatedData = lists.map((val: SlidersHome) => {
        const slider = { ...val };

        // Type of size on image uri: original, medium, small
        slider.path = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["slider-original"]}/${val.path}`;

        return slider;
      });

      setData(formatedData);
      setIsLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setIsLoading(false);

        return;
      }

      toast.error("Something went wrong while fetching slider data");
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getSlidersHome();
  }, []);

  const settings = {
    slidesToShow: 2,
    arrows: true,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container">

      <div className="py-3 osahan-promos">
        <div className="d-flex align-items-center mb-3">
          <h5 className="m-0">Informasi</h5>
        </div>
        <div className="pb-0 mb-0 position-relative">
          <Slider {...settings}>

            {isLoading && [...Array(3)].map((_, i) => {
              return (
                <div key={i.toString()} className="osahan-slider-item px-2">
                  <div className="d-block bg-light position-relative overflow-hidden rounded" style={{ height: 220 }} />
                </div>
              );
            })}

            {!isLoading && data.map((slider: SlidersHome, i: number) => {
              return (
                <RenderSliderItem key={i.toString()} data={slider} />
              );
            })}

          </Slider>
        </div>
      </div>

    </div>
  );
}

export default SliderHome;
