import { useState, useCallback, FormEvent, ChangeEvent } from "react";
import { useRouter, NextRouter } from "next/router";
import FormData from "form-data";
import { toast } from "react-toastify";
// Conponents
import { Input, Select, Button, Anchor, Alert } from "../../atoms";
// Configs
import { routesName } from "../../../configs";
import { DataForgotPassword } from "../../../configs/datatype";
// Utils
import { encoded, decoded, setRoute, useLocalStorage } from "../../../utils";
import { passwordValidation } from "../../../utils/validation";
// Services
import { serviceForgotPassword } from "../../../services";

type ErrorType = DataForgotPassword & string;
type Field = "handphone" | "country_code" | "password" | "confirm_password" | "otp";
type CountryCode = {
  code: string;
  label: string;
  value: string;
}

const countryCodeList: Array<CountryCode> = [
  {
    code: "id",
    label: "Indonesia",
    value: "62",
  },
];

export default function SectionMainForgotPassword() {
  const [data, setData] = useState<Partial<DataForgotPassword>>({});
  const [error, setError] = useState<Partial<ErrorType>>({});
  const [requestPassword, setRequestPassword] = useState<string>("success");
  const [newPassword, setNewPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

  const router: NextRouter = useRouter();

  const handleForm = (field: Field, value: string) => {
    setData({ ...data, [field]: value });
  };

  const handleError = (value: Partial<DataForgotPassword>) => {
    setError(value as ErrorType);
  };

  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const regex = /^[0-9]*$/;

    if (value.length > 0 && value[0] === "0") {
      value = value.substring(1);
    }

    if (value.length > 14) {
      value = value.slice(0, 14);
    }

    if (regex.test(value)) {
      handleForm("handphone", value);
    }
  };

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const fcmToken = localStorage.getItem("fcm");
      const inputValid = passwordValidation(data, handleError);

      if (inputValid) {
        const fd = new FormData();

        fd.append("phone", `0${data.handphone}`);

        if (newPassword) {
          fd.append("new_password", data.password);
          fd.append("confirm_new_password", data.confirm_password);
          fd.append("reference_id", data.reference_id);
          fd.append("otp_code", data.otp);
        }

        const response = await serviceForgotPassword(fd);

        if (response.status === "success" && !newPassword) {
          setNewPassword(true);
          setRequestPassword("success");
          setData({ ...data, reference_id: response.data.reference_id });

          return;
        }

        if (response.status === "success" && newPassword) {
          setNewPassword(false);
          setData({});
          setError({});
          setRequestPassword("success");
          toast.success("Password berhasil diubah");

          router.push(setRoute(routesName.LOGIN));

          return;
        }

        if (response.status === "error") {
          const { message } = response;

          if (message?.includes("mencapai limit")) {
            toast.error("Anda telah mencapai limit percobaan");

            return;
          }

          setError("Nomor handphone tidak terdaftar");
          setRequestPassword("error");

          return;
        }
      }
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong when processing your request");
    }
  }, [data, newPassword]);

  return (
    <section className="osahan-main-body">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center vh-100">
          <div className="col-lg-6 pl-lg-5">
            <div className="osahan-signup shadow bg-white p-4 rounded">
              <div className="p-3">
                <h5 className="my-0">Lupa Password</h5>
                <p className="small mb-4 mt-1">Masukkan nomor handphone yang terdaftar</p>

                {(requestPassword !== "success" && typeof error === "string") ? (
                  <Alert
                    variant="warning"
                    text={typeof error === "string" ? error : "Error aplikasi"}
                  />
                ) : null}

                <form onSubmit={handleSubmit}>
                  {!newPassword && (
                    <div className="row">
                      <div className="col-md-3">
                        <Select
                          id="code-area"
                          label="Kode"
                          size="lg"
                          selectProps={{
                            // placeholder: "Kode Negara",
                            value: data.country_code || "62",
                            onChange: (e) => { handleForm("country_code", e.target.value); },
                          }}
                        >
                          {countryCodeList.map((val: CountryCode, i: number) => {
                            return (
                              <option key={i.toString()} value={val.value} selected={val.value === data.country_code}>
                                +
                                {val.value}
                              </option>
                            );
                          })}
                        </Select>
                      </div>

                      <div className="col-md-9">
                        <Input
                          id="handphone"
                          size="lg"
                          label="No. Handphone"
                          error={error.handphone !== undefined}
                          inputProps={{
                            value: data.handphone || "",
                            placeholder: "cth. 87839800006",
                            onChange: handleInputPhoneNumber,
                          }}
                          helper={error.handphone ? error.handphone : ""}
                        />
                      </div>
                    </div>
                  )}

                  {newPassword && (
                    <>
                      <Input
                        id="otp"
                        label="OTP"
                        size="lg"
                        required
                        error={error.otp !== undefined}
                        inputProps={{
                          value: data.otp || "",
                          onChange: (e) => { handleForm("otp", e.target.value); },
                        }}
                        helper={error.otp ? error.otp : ""}
                      />

                      <Input
                        id="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        size="lg"
                        error={error.password !== undefined}
                        inputProps={{
                          value: data.password || "",
                          append: (
                            <Button
                              variant="light"
                              size="lg"
                              icon={showPassword ? "icofont-eye-blocked" : "icofont-eye"}
                              buttonProps={{
                                type: "button",
                                onClick: () => { setShowPassword(!showPassword); },
                              }}
                            />
                          ),
                          onChange: (e) => { handleForm("password", e.target.value); },
                        }}
                        helper={error.password ? error.password : ""}
                      />

                      <Input
                        id="confirm-password"
                        label="Konfirmasi Password"
                        type={showPasswordConfirm ? "text" : "password"}
                        size="lg"
                        error={error.confirm_password !== undefined}
                        inputProps={{
                          value: data.confirm_password || "",
                          append: (
                            <Button
                              variant="light"
                              size="lg"
                              icon={showPasswordConfirm ? "icofont-eye-blocked" : "icofont-eye"}
                              buttonProps={{
                                type: "button",
                                onClick: () => { setShowPasswordConfirm(!showPasswordConfirm); },
                              }}
                            />
                          ),
                          onChange: (e) => { handleForm("confirm_password", e.target.value); },
                        }}
                        helper={error.confirm_password ? error.confirm_password : ""}
                      />
                    </>
                  )}

                  <Button
                    variant="danger"
                    label="Kirim permintaan"
                    size="lg"
                    block
                    buttonProps={{
                      type: "submit",
                    }}
                  />
                </form>

                {!newPassword && (
                  <>
                    <p className="text-center mt-3 mb-0">
                      Sudah ingat password?
                      {" "}
                      <Anchor href={setRoute(routesName.LOGIN)} className="text-danger ml-1">
                        Mulai login
                      </Anchor>
                    </p>

                    <p className="text-muted text-center small m-0 py-3">atau</p>

                    <Button
                      variant="light"
                      label="Kembali ke beranda"
                      size="lg"
                      block
                      buttonProps={{
                        onClick: () => { router.push(setRoute(routesName.HOME)); },
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
