import { useState, useEffect, FormEvent } from "react";
import { useRouter } from "next/router";
// Configs
import { routesName } from "../../../configs";
import { RoutesDataTypes } from "../../../configs/datatype";
// Utils
import { setRoute } from "../../../utils";

const SearchBar = () => {
  const [keywords, setKeywords] = useState<string | string[]>("");

  const router = useRouter();

  useEffect(() => {
    if (router.query.keywords) {
      setKeywords(router.query.keywords);
    }
  }, [router]);

  const handleForm = (value: string) => {
    setKeywords(value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (keywords !== "") {
      router.push({
        pathname: setRoute(routesName.PRODUCTS),
        query: { keywords },
      });
    } else {
      router.push(setRoute(routesName.PRODUCTS));
    }
  };

  return (
    <form className="mr-sm-2 col-lg-8" onSubmit={handleSubmit}>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="inlineFormInputGroupUsername2"
          placeholder="Cari produk pilihanmu.."
          onChange={(e) => { handleForm(e.target.value); }}
          value={keywords}
        />
        <div className="input-group-prepend">
          <button type="submit" className="btn btn-danger rounded-right">
            <i className="icofont-search" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
