import { useState, useEffect } from "react";
// Components
import { Button } from "../../atoms";
import { CardProduct } from "../../molecules";
// Utils
import { convertToDistance } from "../../../utils";
// Configs
import { uri } from "../../../configs";
import { Products, Locations } from "../../../configs/datatype";

interface SectionProductLainnyaProps {
  products: Array<Products>;
}

export default function SectionProductLainnya(props: SectionProductLainnyaProps) {
  const { products } = props;

  const [more, setMore] = useState(false);

  const handleShowMore = () => {
    setMore(!more);
  };

  let dataLength = products.length;

  if (products.length > 8 && more) {
    dataLength = products.length;
  }

  if (products.length > 8 && !more) {
    dataLength = 8;
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between pt-5 pb-3">
        <h5 className="m-0">Produk Lainnya</h5>
        <div className="d-flex align-items-center">
          <small className="text-muted">
            Menampilkan
            {" "}
            {dataLength}
            {" "}
            {products.length > 8 && `dari ${products.length}`}
            {" "}
            produk
          </small>

          {products.length > 8 && (
            <Button
              label={!more ? "Tampilkan Lebih" : "Tampilkan Sedikit"}
              variant="danger"
              size="sm"
              outline
              className="ml-4"
              buttonProps={{
                onClick: handleShowMore,
              }}
            />
          )}
        </div>
      </div>

      <div className="row">
        {products.slice(0, dataLength).map((val: Products, i: number) => {
          return (
            <div key={val.id} className="col-6 col-md-3 mb-3">
              <CardProduct product={val} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
