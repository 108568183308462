import { DataForgotPassword } from "../../configs/datatype";

type ErrorType = DataForgotPassword & string;

const passwordValidation = (data: Partial<DataForgotPassword>, validate: (error: Partial<DataForgotPassword>) => void) => {
  const fields = data;
  const errors: Partial<DataForgotPassword> = {};

  let isValid: boolean = true;

  const notEmpy = "Tidak boleh kosong";

  if (!fields.handphone || fields.handphone === "") {
    isValid = false;
    errors.handphone = notEmpy;
  }

  if (typeof fields.handphone !== "undefined" && fields.handphone !== "") {
    if (!fields.handphone.match(/^[+ 0-9]+$/)) {
      isValid = false;
      errors.handphone = "Hanya boleh angka";
    }
  }

  // if (!fields.password || fields.password === "") {
  //   isValid = false;
  //   errors.password = notEmpy;
  // }

  validate(errors);

  return isValid;
};

export default passwordValidation;
