import { useState, useCallback, FormEvent } from "react";
import { useRouter, NextRouter } from "next/router";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
// Components
import { FormOtp } from "../../molecules";
// Utils
import { setRoute, encoded, decoded, useLocalStorage } from "../../../utils";
// Configs
import { routesName } from "../../../configs";
import { uri } from "../../../configs/constants";
// Data
import { AppDispatch } from "../../../reduxs";
import { serviceRegister, serviceAddWithdraw } from "../../../services";
import ActionTypes from "../../../reduxs/actions/users/actionTypes";

const OTP_LENGTH = 6;

export default function SectionMainVarifiCation() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  const router: NextRouter = useRouter();

  const [verification, setVerification] = useLocalStorage("verification", null);

  // Reduxs
  const dispatch: AppDispatch = useDispatch();

  const handleForm = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const OTP = [...Array(OTP_LENGTH)].map((_, i) => { return data[`otp-${i + 1}`]; }).join("");

    const fcmToken = localStorage.getItem("fcm");

    const verificationData = verification.data;

    let response = null;

    let dataSubmit: any = {
      otp_code: OTP,
      reference_id: verificationData.reference_id,
    };

    if (verification.type === "register") {
      dataSubmit = {
        ...dataSubmit,
        type: "phone",
        handphone: verificationData.handphone,
        name: verificationData.name,
        password: verificationData.password,
        confirm_password: verificationData.confirm_password,
        fcm_token: decoded(fcmToken as string),
      };
    }

    if (verification.type === "withdraw") {
      dataSubmit = {
        ...dataSubmit,
        sumber_dana: verificationData.sumber_dana,
        member_bank_account_id: verificationData.member_bank_account_id,
        system_payment: verificationData.system_payment,
        nominal: verificationData.nominal,
        admin_fee: verificationData.admin_fee,
        total_withdraw: verificationData.total_withdraw,
      };
    }

    try {
      const fd = new FormData();
      fd.append("otp_code", dataSubmit.otp_code);
      fd.append("reference_id", dataSubmit.reference_id);

      switch (verification.type) {
        case "register": {
          dispatch({ type: ActionTypes.REGISTER_USER_REQUEST });

          fd.append("type", dataSubmit.type);
          fd.append("phone", dataSubmit.handphone);
          fd.append("name", dataSubmit.name);
          fd.append("password", dataSubmit.password);
          fd.append("confirm_password", dataSubmit.confirm_password);
          fd.append("fcm_token", dataSubmit.fcm_token);

          response = await serviceRegister(fd);

          if (response.status === "failed" || response.status === "error") {
            Sentry.setContext("User Registration", dataSubmit);
            Sentry.captureException(new Error(`Failed to register :: ${response.message}`), {
              tags: {
                section: "registration",
              },
            });

            toast.error(response.message);

            return;
          }

          const profile = { ...response.data.profile };

          profile.avatar = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original.profile}/${response.data.profile.avatar}`;
          profile.avatar_file = response.data.profile.avatar;

          dispatch({ type: ActionTypes.REGISTER_USER_SUCCESS, payload: { data: profile, token: response.data.session_token } });

          const token = encoded(response.data.session_token);
          const user = encoded(JSON.stringify(profile));

          Cookies.set("_xSe", token, { expires: 5 });
          Cookies.remove("authentication");

          sessionStorage.setItem("_xLo", JSON.stringify(true));
          localStorage.setItem("ufk", user);
          localStorage.removeItem("verification");

          const to = sessionStorage.getItem("to");

          if (to) {
            router.push(to);

            return;
          }

          router.push(setRoute(routesName.TRANSACTION));

          break;
        }
        case "withdraw": {
          Cookies.get("_xSe") && fd.append("session_request", decoded(Cookies.get("_xSe") as string));

          fd.append("sumber_dana", dataSubmit.sumber_dana); // member, shop
          fd.append("member_bank_account_id", dataSubmit.member_bank_account_id);
          fd.append("system_payment", dataSubmit.system_payment); // manual, automatic
          fd.append("nominal", dataSubmit.nominal);
          fd.append("admin_fee", dataSubmit.admin_fee);
          fd.append("total_withdraw", dataSubmit.total_withdraw);

          response = await serviceAddWithdraw(fd);

          if (response.status === "failed" || response.status === "error") {
            Sentry.setContext("Withdraw Saldo", dataSubmit);
            Sentry.captureException(new Error(`Failed to withdraw :: ${response.message}`), {
              tags: {
                section: "withdraw",
              },
            });

            toast.error(response.message);

            return;
          }

          Cookies.remove("authentication");

          localStorage.removeItem("verification");

          const to = sessionStorage.getItem("to");

          router.push(to!);

          break;
        }
        default:
          break;
      }
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      switch (verification.type) {
        case "register":
          dispatch({ type: ActionTypes.REGISTER_USER_FAILURE, payload: { data: null, message: err.message } });

          Sentry.setContext("User Registration", dataSubmit);
          Sentry.captureException(new Error(`Failed to register :: ${err.message}`), {
            tags: {
              section: "registration",
            },
          });
          break;
        case "withdraw":
          Sentry.setContext("Withdraw Saldo", dataSubmit);
          Sentry.captureException(new Error(`Failed to withdraw :: ${response.message}`), {
            tags: {
              section: "withdraw",
            },
          });
          break;
        default:
          break;
      }

      toast.error("Verification failed, please try again");
    }
  };

  const handleResend = async () => {
    const fcmToken = localStorage.getItem("fcm");

    const verificationData = verification.data;

    let response = null;
    let dataSubmit: any = {};

    if (verification.type === "register") {
      dataSubmit = {
        type: "phone",
        handphone: verificationData.handphone,
        name: verificationData.name,
        password: verificationData.password,
        confirm_password: verificationData.confirm_password,
        fcm_token: decoded(fcmToken as string),
        reference_id: "",
      };
    }

    if (verification.type === "withdraw") {
      dataSubmit = {
        session_request: decoded(Cookies.get("_xSe") as string || ""),
        sumber_dana: verificationData.sumber_dana,
        member_bank_account_id: verificationData.member_bank_account_id,
        system_payment: verificationData.system_payment,
        nominal: verificationData.nominal,
        admin_fee: verificationData.admin_fee,
        total_withdraw: verificationData.total_withdraw,
        handphone: verificationData.handphone,
        bank_name: verificationData.bank_name,
        bank_account_number: verificationData.bank_account_number,
        bank_account_name: verificationData.bank_account_name,
        reference_id: "",
      };
    }

    try {
      const fdResend = new FormData();

      switch (verification.type) {
        case "register":
          fdResend.append("type", dataSubmit.type);
          fdResend.append("phone", dataSubmit.handphone);
          fdResend.append("name", dataSubmit.name);
          fdResend.append("password", dataSubmit.password);
          fdResend.append("confirm_password", dataSubmit.confirm_password);
          fdResend.append("fcm_token", dataSubmit.fcm_token);

          response = await serviceRegister(fdResend);

          if (response.status === "failed" || response.status === "error") {
            toast.error("Failed to resend verification code, please try registration again");

            Cookies.remove("authentication");
            Cookies.remove("authentication_date");

            localStorage.removeItem("verification");

            router.push(setRoute(routesName.REGISTER));

            return;
          }

          dataSubmit.reference_id = response.data.reference_id;

          Cookies.set("authentication_date", JSON.stringify(moment().add(1, "minutes").format("YYYY/MM/DD HH:mm:ss")), { expires: 1 });

          setVerification({ type: "register", data: dataSubmit });
          setData({});
          break;
        case "withdraw":
          fdResend.append("session_request", dataSubmit.session_request);
          fdResend.append("sumber_dana", dataSubmit.sumber_dana);
          fdResend.append("member_bank_account_id", dataSubmit.member_bank_account_id);
          fdResend.append("system_payment", dataSubmit.system_payment);
          fdResend.append("nominal", dataSubmit.nominal);
          fdResend.append("admin_fee", dataSubmit.admin_fee);
          fdResend.append("total_withdraw", dataSubmit.total_withdraw);

          response = await serviceAddWithdraw(fdResend);

          if (response.status === "failed" || response.status === "error") {
            toast.error("Failed to resend verification code, please try registration again");

            Cookies.remove("authentication");
            Cookies.remove("authentication_date");

            localStorage.removeItem("verification");

            router.push(setRoute(routesName.REGISTER));

            return;
          }

          dataSubmit.reference_id = response.data.reference_id;

          setVerification({ type: "withdraw", data: dataSubmit });
          setData({});
          break;
        default:
          break;
      }
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      Cookies.remove("authentication");
      Cookies.remove("authentication_date");

      localStorage.removeItem("verification");

      router.push(setRoute(routesName.REGISTER));

      toast.error("Failed to resend verification code, please try registration again");
    }
  };

  return (
    <div className="osahan-verification shadow bg-white p-4 rounded">
      <div className="osahan-form p-3 text-center mt-3">
        <p>Masukan kode otp yang dikirim ke nomor,</p>
        <p className="mb-4" style={{ fontSize: 16, letterSpacing: 3 }}>
          {`${verification?.data?.handphone?.slice(0, 3)} * * * * ${verification?.data?.handphone?.slice(verification?.data?.handphone?.length - 3)}`}
        </p>

        <FormOtp
          length={OTP_LENGTH}
          data={data}
          doChange={handleForm}
          doSubmit={handleSubmit}
          doResend={handleResend}
        />
      </div>
    </div>
  );
}
