import { useState, useEffect } from "react";
import Image from "next/image";
import { useRouter, NextRouter } from "next/router";
import { useSelector } from "react-redux";
// Components
import { Button } from "../../atoms";
import ItemStepOne from "./listItem";
import { CartsItem } from "../../../configs/datatype";
// Configs
import { routesName } from "../../../configs";
// Utils
import { setRoute, convertToIdr } from "../../../utils";
import { useLocalStorage } from "../../../utils/hooks";
// Data
import { RootState } from "../../../reduxs";

interface propsPesanan {
  doSetSubtotal: (value: number) => void;
}

export default function CartProductsSection(props: propsPesanan) {
  const { doSetSubtotal } = props;

  const [source, setSource] = useState<string>("/");

  const [localShop, setLocalShop] = useLocalStorage("_shop", null);

  const router: NextRouter = useRouter();

  // Reduxs
  const { carts, isLoadingItem } = useSelector((state: RootState) => { return state.rdccarts; });

  const totalHarga = carts.length > 0 ? carts.map((cart: CartsItem) => { return cart.price * cart.qty; }).reduce((prev, curr) => { return prev + curr; }) : 0;

  useEffect(() => {
    doSetSubtotal(totalHarga);
  }, [totalHarga]);

  useEffect(() => {
    if (localShop && localShop.logo) setSource(localShop.logo);
  }, [localShop]);

  return (
    <div className="card">
      <div className="card-header">
        <span>Produk Dipesan</span>
      </div>
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="position-relative overflow-hidden rounded" style={{ width: 50, height: 50 }}>
              <Image
                src={source}
                onError={() => { setSource("/images/image-default.jpg"); }}
                alt="Store Logo"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={localShop?.logo}
              />
            </div>
            <div className="d-flex flex-column ml-4">
              <p className="card-text mb-0" style={{ fontSize: 14, fontWeight: 600 }}>{localShop?.name}</p>
              <small className="text-muted">
                {localShop?.regional_kecamatan_name || localShop?.regional_kelurahan_name}
                ,
                {" "}
                {localShop?.regional_provinsi_name}
              </small>
            </div>
          </div>

          <Button
            label="Kunjungi Toko"
            variant="link"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: () => { router.push(`${setRoute(routesName.SHOPS)}/${localShop?.id}`); },
            }}
          />
        </div>
      </div>
      <div className="card-body">

        {carts.map((cart: CartsItem, i: number) => {
          return (
            <div key={i.toString()} className="mb-2">
              <ItemStepOne
                data={cart}
                index={i}
              />
            </div>
          );
        })}

      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-sm-5 offset-sm-7">

            <div className="row">
              <div className="col text-right">
                <span>Jumlah harga produk:</span>
              </div>
              <div className="col d-flex justify-content-end align-items-center text-right">
                {isLoadingItem ? (
                  <i className="icofont-spinner animate-spin mr-4" />
                ) : (
                  <h6 className="mb-0 font-weight-normal">{convertToIdr(totalHarga)}</h6>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
