import cx from "classnames";

interface BadgeProductFeatureProps {
  icon: string;
  iconsize: "h3" | "h4" | "h5" | "h6";
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export default function BadgeProductFeature(props: BadgeProductFeatureProps) {
  const { icon, iconsize, children, className } = props;

  const badgetclass = cx(className, "d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative mr-2");

  const iconclass = cx(icon, "text-muted m-0", {
    [`${iconsize}`]: true,
  });

  return (
    <div className={badgetclass}>
      <div
        className="mr-3 d-flex bg-white rounded-circle align-items-center justify-content-center position-absolute"
        style={{ width: 40, height: 40, left: ".5rem" }}
      >
        <i className={iconclass} />
      </div>
      <div className="d-flex flex-column ml-4 pl-2">{children}</div>
    </div>
  );
}

BadgeProductFeature.defaultProps = {
  className: undefined,
};
