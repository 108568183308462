import { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import { toast } from "react-toastify";
// Components
import { Logo, Button } from "../../atoms";
// Utils
import { uri } from "../../../configs";
import { CategoriesType } from "../../../configs/datatype";
// Data
import { serviceGetCategoriesForProduct } from "../../../services";

interface SectionFilterSortProps {
  sorted: string;
  categored: number;
  doSetCategory: (val: number) => void;
  doSetSort: (val: string) => void;
}

type SortType = {
  id: "termurah" | "termahal" | "rating" | "order-terbanyak" | "terdekat";
  name: string;
}

const sortOptions: Array<SortType> = [
  {
    id: "termurah",
    name: "Harga Termurah",
  },
  {
    id: "termahal",
    name: "Harga Termahal",
  },
  {
    id: "rating",
    name: "Rating",
  },
  {
    id: "order-terbanyak",
    name: "Order Terbanyak",
  },
  {
    id: "terdekat",
    name: "Terdekat",
  },
];

export default function SectionFilterSort(props: SectionFilterSortProps) {
  const { sorted, categored, doSetCategory, doSetSort } = props;

  const [categories, setCategories] = useState<Array<CategoriesType>>([]);
  const [categoryActive, setCategoryActive] = useState<number>(0);
  const [sortActive, setSortActive] = useState<string>("");

  const listitem = cx("list-group-item list-group-item-action");

  const getCategoriesForProduct = useCallback(async () => {
    try {
      const response = await serviceGetCategoriesForProduct();

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      const categoryData = [...response.data];

      for (let i = 0; i < categoryData.length; i += 1) {
        categoryData[i].logo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.category}/${categoryData[i].logo}`;
      }

      setCategories(categoryData);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting categories");
    }
  }, []);

  useEffect(() => {
    getCategoriesForProduct();
  }, []);

  const handleChangeCategory = (value: number) => {
    doSetCategory(value);
    setCategoryActive(categoryActive === value ? 0 : value);
  };

  const handleChangeSort = (value: string) => {
    doSetSort(value);
    setSortActive(sortActive === value ? "" : value);
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between my-3">
        <h4 className="d-none d-sm-block">Explore Produk</h4>

        <div className="d-flex">
          <Button
            variant="light"
            size="md"
            label="Filter"
            icon="icofont-filter"
            className="mr-2"
            buttonProps={{
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalfilter",
            }}
          />

          <Button
            variant="light"
            size="md"
            label="Sort"
            icon="icofont-signal"
            buttonProps={{
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalsort",
            }}
          />
        </div>
      </div>

      {/* Modal Filter */}
      <div
        className="modal fade right-modal sidebar_Product"
        id="modalfilter"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h6 className="m-0">FILTER</h6>

              <Button
                variant="light"
                size="md"
                icon="icofont-close"
                buttonProps={{
                  type: "button",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                }}
              />
            </div>
            <div className="modal-body p-0 ">
              <div className="osahan-filters">
                <div className="filter">
                  <ul className="list-group list-group-flush">

                    {categories.map((val: CategoriesType, i: number) => {
                      return (
                        <button
                          key={val.id}
                          type="button"
                          className={`${listitem} ${categored === val.id ? "active" : ""}`}
                          onClick={() => { handleChangeCategory(val.id); }}
                        >
                          {val.name}
                        </button>
                      );
                    })}

                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 border-0">
              <div className="col-12 m-0 p-0" style={{ height: 50 }} />
            </div>
          </div>
        </div>
      </div>

      {/* Modal Sort */}
      <div
        className="modal fade right-modal sidebar_Product"
        id="modalsort"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h6 className="m-0">SORT BY</h6>

              <Button
                icon="icofont-close"
                variant="light"
                size="md"
                buttonProps={{
                  type: "button",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                }}
              />
            </div>
            <div className="modal-body p-0 ">
              <div className="osahan-filters">
                <div className="filter">
                  <ul className="list-group list-group-flush">

                    {sortOptions.map((val: SortType, i: number) => {
                      return (
                        <button
                          key={val.id}
                          className={`${listitem} ${sorted === val.id ? "active" : ""}`}
                          onClick={() => { handleChangeSort(val.id); }}
                        >
                          {val.name}
                        </button>
                      );
                    })}

                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer p-0 border-0">
              <div className="col-12 m-0 p-0" style={{ height: 50 }} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
