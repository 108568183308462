import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import cx from "classnames";

interface InputProps {
  id: string;
  label?: string;
  block?: boolean;
  useFormGroup?: boolean;
  required?: boolean;
  direction?: "row" | "column";
  size?: "sm" | "md" | "lg";
  className?: string;
  error?: boolean;
  helper?: string;
  inputProps?: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
}

export default function Multiple(props: InputProps) {
  const { id, label, block, useFormGroup, required, direction, size, className, error, helper, inputProps } = props;

  const FormGroupClass = cx(
    {
      "form-group": useFormGroup,
    },
  );

  const InputGroupClass = cx(
    "input-group",
    {
      "has-validation": error,
      "input-group-sm": size === "sm",
      "input-group-lg": size === "lg",
    },
  );

  const InputClass = cx(
    "form-control",
    {
      "is-invalid": error,
    },
  );

  return (
    <div className={FormGroupClass}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
          {required && " *"}
        </label>
      )}

      <textarea
        id={id}
        className={InputClass}
        {...inputProps}
      >
        {inputProps?.value}
      </textarea>

      {error && <div className="invalid-feedback">{helper}</div>}

      {helper && !error && (
        <small className="form-text text-muted">{helper}</small>
      )}
    </div>
  );
}

Multiple.defaultProps = {
  label: undefined,
  block: false,
  useFormGroup: true,
  required: false,
  direction: "column",
  size: "md",
  className: undefined,
  error: false,
  helper: undefined,
  inputProps: undefined,
};
