import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
import _ from "lodash";
import * as Sentry from "@sentry/nextjs";
// Components
import { Button, Dialog, Alert } from "../../atoms";
import FormInput from "./formInput";
import FormCariLokasi from "./formCari";
// Utils
import { decoded } from "../../../utils";
import { addressValidation } from "../../../utils/validation";
// Configs
import { KindOfType, Locations, AddressAddData } from "../../../configs/datatype";
// Data
import { AppDispatch, actionGetAddresses } from "../../../reduxs";
import { serviceAddAddress, serviceUpdateAddress } from "../../../services";

interface DialogManageProps {
  open: boolean;
  kindof: KindOfType;
  data: Partial<AddressAddData>;
  error: Partial<AddressAddData>;
  edit: boolean;
  dataLocation: Partial<Locations>;
  doClose: () => void;
  doChangeKind: (value: KindOfType) => void;
  doChangeData: (value: Partial<AddressAddData>) => void;
  doChangeDataLocation: (value: Partial<Locations>) => void;
  doChangeDataError: (value: Partial<AddressAddData>) => void;
}

interface RenderModalBodyProps {
  kindof: KindOfType;
  data: Partial<AddressAddData>;
  error: Partial<AddressAddData>;
  edit: boolean;
  dataLocation: Partial<Locations>;
  initMapLocation: boolean;
  doChangeKind: (value: KindOfType) => void;
  doChangeDataLocation: (value: Partial<Locations>) => void;
  doSetData: (value: Partial<AddressAddData>) => void;
  setInitMapLocation: (value: boolean) => void;
}

const RenderModalBody = (props: RenderModalBodyProps) => {
  const { kindof, data, error, edit, dataLocation, initMapLocation, doChangeKind, doChangeDataLocation, doSetData, setInitMapLocation } = props;

  switch (kindof) {
    case "input-location":
      return (
        <FormInput
          data={data}
          error={error}
          edit={edit}
          initMapLocation={initMapLocation}
          doChangeKind={doChangeKind}
          dataLocation={dataLocation}
          doChangeDataLocation={doChangeDataLocation}
          doSetData={doSetData}
          setInitMapLocation={setInitMapLocation}
        />
      );
    case "find-location":
      return (
        <FormCariLokasi
          doChangeKind={doChangeKind}
          doChangeDataLocation={doChangeDataLocation}
        />
      );
    default:
      return null;
  }
};

export default function DialogManage(props: DialogManageProps) {
  const { open, kindof, data, error, edit, dataLocation, doClose, doChangeKind, doChangeData, doChangeDataLocation, doChangeDataError } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initMapLocation, setInitMapLocation] = useState<boolean>(true);

  const dispatch: AppDispatch = useDispatch();

  const handleError = (value: Partial<AddressAddData>) => {
    doChangeDataError(value);
  };

  const handleSetDefault = () => {
    doChangeData({});
    doChangeDataError({});
    doChangeKind("find-location");
    setInitMapLocation(true);
    setIsLoading(false);
  };

  const handleSaveAddress = useCallback(async () => {
    const inputValid = addressValidation(data, handleError);

    if (!inputValid) {
      toast.warn("Please fill all the required fields");

      return;
    }

    setIsLoading(true);

    // Token
    const _xSe = Cookies.get("_xSe");

    const dataSubmit = {
      id: edit ? data.id : "",
      session_request: decoded(_xSe || ""),
      address: data.address,
      address_detail: data.address_detail || "",
      address_alias: data.address_alias,
      recipient_name: data.recipient_name,
      phone: `0${data.phone}`,
      regional: {
        provinsi: { id: data.provinsi?.id },
        kabupaten: { id: data.kabupaten?.id },
        kecamatan: { id: data.kecamatan?.id },
        kelurahan: { id: data.kelurahan?.id },
      },
      latitude: dataLocation.coordinate?.latitude,
      longitude: dataLocation.coordinate?.longitude,
    };

    try {
      const fd = new FormData();
      fd.append("session_request", dataSubmit.session_request);
      fd.append("address", dataSubmit.address);
      fd.append("address_detail", dataSubmit.address_detail);
      fd.append("address_alias", dataSubmit.address_alias);
      fd.append("recipient_name", dataSubmit.recipient_name);
      fd.append("phone", dataSubmit.phone);
      fd.append("regional[provinsi][id]", dataSubmit.regional.provinsi.id);
      fd.append("regional[kabupaten][id]", dataSubmit.regional.kabupaten.id);
      fd.append("regional[kecamatan][id]", dataSubmit.regional.kecamatan.id);
      fd.append("regional[kelurahan][id]", dataSubmit.regional.kelurahan.id);
      fd.append("latitude", dataSubmit.latitude);
      fd.append("longitude", dataSubmit.longitude);

      if (edit) {
        fd.append("id", dataSubmit.id);

        const response = await serviceUpdateAddress(fd);

        if (response.status === "failed" || response.status === "error") {
          Sentry.setContext("Update Address", dataSubmit);
          Sentry.captureException(new Error(`Failed to submit :: ${response.message}`), {
            tags: {
              section: "address",
            },
          });

          toast.error(response.message);
          handleSetDefault();
          doClose();

          return;
        }

        toast.success(`"${dataSubmit.address_alias}" has been updated`);

        dispatch(actionGetAddresses({ token: decoded(_xSe || "") }));

        setInitMapLocation(true);
        setIsLoading(false);

        doChangeDataError({});
        doChangeKind("find-location");
        doClose();

        return;
      }

      const response = await serviceAddAddress(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("Add Address", dataSubmit);
        Sentry.captureException(new Error(`Failed to submit :: ${response.message}`), {
          tags: {
            section: "address",
          },
        });

        toast.error(response.message);
        handleSetDefault();
        doClose();

        return;
      }

      toast.success(`"${dataSubmit.address_alias}" has been added`);

      dispatch(actionGetAddresses({ token: decoded(_xSe || "") }));
      handleSetDefault();
      doClose();
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setIsLoading(false);

        return;
      }

      Sentry.setContext("Add Address", dataSubmit);
      Sentry.captureException(new Error(`Failed to submit :: ${err.message}`), {
        tags: {
          section: "address",
        },
      });

      toast.error("Something wrong when saving address");
      setIsLoading(false);
    }
  }, [edit, data, dataLocation]);

  return (
    <Dialog open={open} size="lg">
      {!isLoading && (
        <div className="modal-header border-0">
          <p className="modal-title" style={{ fontSize: 14 }}>{edit ? "Ubah Alamat" : "Tambah Alamat"}</p>
        </div>
      )}

      <div className="modal-body">
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="d-flex flex-column ml-4">
              <h6 className="mb-1 text-danger" style={{ fontWeight: 500 }}>Mohon tunggu...</h6>
              <p className="mb-0 text-muted">Alamat sendang disimpan</p>
            </div>
          </div>
        )}

        {!isLoading && kindof === "input-location" && initMapLocation && (
          <Alert
            variant="warning"
            text={`
              Drag drop pin map atau klik dua kali di titik lokasi untuk menentukan lokasi map. 
              Map digunakan untuk pengiriman instant. Klik tombol "Selanjutnya" untuk melanjutkan
            `}
          />
        )}

        {!isLoading && (
          <RenderModalBody
            kindof={kindof}
            data={data}
            error={error}
            edit={edit}
            initMapLocation={initMapLocation}
            dataLocation={dataLocation}
            doChangeKind={doChangeKind}
            doChangeDataLocation={doChangeDataLocation}
            doSetData={doChangeData}
            setInitMapLocation={setInitMapLocation}
          />
        )}
      </div>

      {!isLoading && (
        <div className="modal-footer">
          <div className="d-flex flex-grow-1 align-items-center justify-content-end">
            <Button
              label="Batalkan"
              variant="link"
              size="lg"
              className="text-muted mr-2"
              buttonProps={{
                onClick: () => {
                  handleSetDefault();
                  doClose();
                },
              }}
            />

            <Button
              label="Simpan Alamat"
              variant="danger"
              size="lg"
              buttonProps={{
                disabled: kindof === "find-location" || initMapLocation || isLoading,
                onClick: handleSaveAddress,
              }}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}
