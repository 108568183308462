import { useState, useEffect } from "react";
// Componnets
import { Button } from "../../../atoms";

interface PropsCartTimePicker {
  hourLimit?: number;
  minuteLimit?: number;
  hour: number;
  minute: number;
  onChangeHours: (hours: number) => void;
  onChangeMinutes: (minutes: number) => void;
}

function CartTimePicker(props: PropsCartTimePicker) {
  const { hour, minute, hourLimit, minuteLimit, onChangeHours, onChangeMinutes } = props;

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setHours(hour);
    setMinutes(minute);
  }, []);

  useEffect(() => {
    onChangeHours(hours);
    onChangeMinutes(minutes);
  }, [hours, minutes]);

  const handleChangeHours = (type: "plus" | "minus") => {
    if (hours >= 0 && hours < hourLimit! && type === "plus") setHours(hours + 1);

    if (hours > 0 && hours <= hourLimit! && type === "minus") setHours(hours - 1);

    if (hours === 23 && type === "plus") setHours(0);

    if (hours === 0 && hourLimit! === 23 && type === "minus") setHours(23);
  };

  const handleChangeMinutes = (type: "plus" | "minus") => {
    if (minutes >= 0 && minutes < minuteLimit! && type === "plus") setMinutes(minutes + 1);

    if (minutes > 0 && minutes <= minuteLimit! && type === "minus") setMinutes(minutes - 1);

    if (minutes === 59 && type === "plus") setMinutes(0);

    if (minutes === 0 && minuteLimit! === 59 && type === "minus") setMinutes(59);
  };

  return (
    <div className="d-flex align-items-center justify-content-center py-3">
      <div className="d-flex flex-column align-items-center">
        <Button
          size="md"
          variant="light"
          icon="icofont-thin-up"
          buttonProps={{
            onClick: () => { handleChangeHours("plus"); },
          }}
        />

        <div className="py-4">
          <h4 className="m-0">{hours.toString().length === 1 ? `0${hours}` : hours}</h4>
        </div>

        <Button
          size="md"
          variant="light"
          icon="icofont-thin-down"
          buttonProps={{
            onClick: () => { handleChangeHours("minus"); },
          }}
        />
      </div>

      <div className="d-flex flex-column align-items-center px-4">
        <h5 className="mb-1">:</h5>
      </div>

      <div className="d-flex flex-column align-items-center">
        <Button
          size="md"
          variant="light"
          icon="icofont-thin-up"
          buttonProps={{
            onClick: () => { handleChangeMinutes("plus"); },
          }}
        />

        <div className="py-4">
          <h4 className="m-0">{minutes.toString().length === 1 ? `0${minutes}` : minutes}</h4>
        </div>

        <Button
          size="md"
          variant="light"
          icon="icofont-thin-down"
          buttonProps={{
            onClick: () => { handleChangeMinutes("minus"); },
          }}
        />
      </div>

      <div className="d-flex flex-column align-items-center pl-4">
        <h5 className="mb-1">Wib</h5>
      </div>
    </div>
  );
}

CartTimePicker.defaultProps = {
  hourLimit: 23,
  minuteLimit: 59,
};

export default CartTimePicker;
