import { useState, useEffect, useCallback } from "react";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import FormData from "form-data";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// Components
import { Input, Button, Dialog } from "../../atoms";
import { CounterInput } from "../../molecules";
// Configs
import { CartsItem, Products } from "../../../configs/datatype";
// Utils
import { convertToIdr, decoded } from "../../../utils";
// Data
import { serviceGetDetailProduct } from "../../../services";
import { AppDispatch, RootState, actionUpdateItemCarts, actionRemoveItemCarts } from "../../../reduxs";

interface propsCartsItem {
  data: CartsItem;
  index: number;
}

export default function ItemOnCarts(props: propsCartsItem) {
  const { data, index } = props;

  const [productDetail, setProductDetail] = useState<Products>({});
  const [quantity, setQuantity] = useState<number>(0);
  const [note, setNote] = useState<string>(data?.notes);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [editCatatan, setEditCatatan] = useState<boolean>(false);
  // const [hapus, setHapus] = useState<boolean>(false);
  const [source, setSource] = useState<string>("/");

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { carts, cartsId } = useSelector((state: RootState) => { return state.rdccarts; });

  const getDetailProduct = useCallback(async (id: number) => {
    try {
      const fd = new FormData();
      fd.append("product_id", id);

      const response: any = await serviceGetDetailProduct(fd);

      if (response.status === "failed" && response.status === "error") {
        toast.error(response.message);

        return;
      }

      setProductDetail(response.data.product);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something when wrong when retrieving product detail");
    }
  }, []);

  useEffect(() => {
    if (data && data.product_id) getDetailProduct(data.product_id);
  }, [data]);

  useEffect(() => {
    if (data && data.product_photo) setSource(data.product_photo);
    if (data && data.qty) setQuantity(data.qty);
  }, [data]);

  const handleDeleteProduct = () => {
    // Token
    const _xSe = Cookies.get("_xSe");

    const item = {
      session_request: decoded(_xSe || ""),
      shopping_cart_id: cartsId,
      product_id: data.product_id,
    };

    if (carts.length === 1) {
      localStorage.removeItem("_cartsId");
      localStorage.removeItem("_shipping");
      localStorage.removeItem("_shippingdate");
      localStorage.removeItem("_shipper");
      localStorage.removeItem("_payment");
      localStorage.removeItem("_shop");

      dispatch({ type: "CLEAR_CARTS" });
      dispatch(actionRemoveItemCarts(item));

      toast.success("The cart now is empty");

      setDialogDelete(false);

      return;
    }

    carts.splice(index, 1);

    dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts } } });
    dispatch(actionRemoveItemCarts(item));

    toast.success("The item has been removed from the cart");

    setDialogDelete(false);
  };

  const handleQuantity = (value: number) => {
    // Token
    const _xSe = Cookies.get("_xSe");

    carts[index].qty = value;

    const item = {
      session_request: decoded(_xSe || ""),
      shopping_cart_id: cartsId,
      product_id: data.product_id,
      qty: value,
      notes: data.notes,
    };

    dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts } } });
    dispatch(actionUpdateItemCarts(item));

    setQuantity(value);
  };

  const handleEditCatatan = () => {
    // Token
    const _xSe = Cookies.get("_xSe");

    carts[index].notes = note;

    const item = {
      session_request: decoded(_xSe || ""),
      shopping_cart_id: cartsId,
      product_id: data.product_id,
      qty: data.qty,
      notes: note,
    };

    dispatch({ type: "ADD_TO_STATE_CARTS", payload: { data: { carts } } });
    dispatch(actionUpdateItemCarts(item));

    setEditCatatan(false);
  };

  const handleDialogDelete = () => {
    setDialogDelete(!dialogDelete);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-end justify-content-between">
          <div className="d-flex align-items-center position-relative">
            <div
              className="position-relative rounded mr-3 overflow-hidden"
              style={{
                width: 120,
                height: 100,
              }}
            >
              <Image
                src={source}
                onError={() => { setSource("/images/image-default.jpg"); }}
                alt={data?.product_name}
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={data?.product_photo}
              />
            </div>

            <div className="d-flex flex-column">
              <h6>{data?.product_name}</h6>

              {editCatatan ? (
                <div className="d-flex mt-3">
                  <Input
                    id={data.product_id.toString()}
                    inputProps={{
                      placeholder: "Tulis catatan produk ini",
                      value: note || "",
                      onChange: (e) => { setNote(e.target.value); },
                      append: (
                        <>
                          <Button
                            label="Batal"
                            variant="light"
                            size="sm"
                            buttonProps={{
                              onClick: () => { setEditCatatan(false); },
                            }}
                          />
                          <Button
                            label="Simpan"
                            variant="danger"
                            size="sm"
                            buttonProps={{
                              onClick: handleEditCatatan,
                            }}
                          />
                        </>
                      ),
                    }}
                  />
                </div>
              ) : (
                <div className="position-relative">
                  {data?.notes && (
                    <p>
                      &quot;
                      {data?.notes}
                      &quot;
                    </p>
                  )}

                  <Button
                    label={data?.notes ? "Ubah catatan" : "Beri catatan"}
                    variant="link"
                    size="md"
                    icon="icofont-plus-square"
                    className="p-0 text-danger"
                    buttonProps={{
                      onClick: () => { setEditCatatan(true); },
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-column align-items-end justify-content-end align-self-stretch">

            {productDetail && productDetail.minimum_order_stock! > 1 && (
              <div className="d-flex align-items-center mr-2 mb-3">
                <p className="mb-0 mr-2">Min. order:</p>
                <p className="mb-0" style={{ fontWeight: 600 }}>{productDetail?.minimum_order_stock}</p>
              </div>
            )}

            <div className="d-flex align-items-center">
              <Button
                label="Hapus"
                variant="link"
                size="md"
                icon="icofont-trash"
                className="mr-5 text-muted"
                buttonProps={{
                  onClick: handleDialogDelete,
                }}
              />
              <h6 className="font-weight-normal mb-0 mr-4">
                {convertToIdr(data?.price * quantity)}
              </h6>

              <CounterInput data={quantity} min={productDetail?.minimum_order_stock || 1} doSet={handleQuantity} />
              {/* <CounterInput data={quantity} min={1} doSet={handleQuantity} /> */}
            </div>
          </div>

        </div>
      </div>

      <Dialog open={dialogDelete} size="sm">
        <div className="modal-body">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <i className="icofont-trash text-danger my-3" style={{ fontSize: "40px" }} />
            <h6 className="text-center" style={{ lineHeight: 1.5 }}>Apa kamu yakin ingin menghapus produk ini?</h6>
            <p className="small text-muted m-0">{data?.product_name}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Batal"
            size="md"
            variant="light"
            buttonProps={{
              onClick: handleDialogDelete,
            }}
          />

          <Button
            label="Ya, Hapus"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: handleDeleteProduct,
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
