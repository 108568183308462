import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { Loading } from "../../atoms";
import { ItemOrderStatus } from "../../molecules";
// Configs
import { StatusTransaction, Transaction } from "../../../configs/datatype";
// Utils
import { decoded } from "../../../utils";
// Service
import { serviceGetListTransaction } from "../../../services";

interface ItemOrderStatusProps {
  status: StatusTransaction;
}

export default function ListsOrder(props: ItemOrderStatusProps) {
  const { status } = props;

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const getListOrder = useCallback(async () => {
    try {
      // Token
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();

      fd.append("session_request", decoded(_xSe || ""));
      fd.append("status_transaction", status);

      setLoading(true);

      const response = await serviceGetListTransaction(fd);

      if (response.status === "failed" && response.status === "error") {
        toast.error(response.message);

        return;
      }

      setData(response.data);

      if (response.data.length === 0) setIsEmpty(true);

      setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting order list");
    }
  }, []);

  useEffect(() => {
    getListOrder();
  }, []);

  return (
    <div className="row">
      {/* <Loading show={loading} /> */}
      {loading && (
        <div className="w-100 position-relative" style={{ background: "none" }}>
          <div className="d-flex align-items-center justify-content-center text-muted" style={{ height: 450 }}>
            <i className="icofont-spinner animate-spin" style={{ fontSize: 28 }} />
          </div>
        </div>
      )}

      {!loading && isEmpty && (
        <div className="col-sm-12 mb-3">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 450 }}
          >
            <div className="d-flex text-muted">
              <i className="icofont-box" style={{ fontSize: 80 }} />
            </div>

            <h6 className="mb-0 ml-4 text-muted" style={{ lineHeight: 1.7 }}>
              <span className="font-weight-light">Transaksi status &quot;Belum bayar&quot;</span>
              <br />
              masih kosong
            </h6>
          </div>
        </div>
      )}

      {!loading && !isEmpty && data.map((transaction: Transaction, i: number) => {
        return (
          <div key={i.toString()} className="col-sm-12 mb-3">
            <ItemOrderStatus
              data={transaction}
              status={status}
            />
          </div>
        );
      })}
    </div>
  );
}
