import { useState, useEffect, useCallback, useRef, FormEvent } from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import Pusher, { PresenceChannel } from "pusher-js";
import _ from "lodash";
import moment from "moment";
// Components
import { Input, Button } from "../../atoms";
// Configs
import { __exch, messagesDataShowLimit } from "../../../configs";
import * as DataType from "../../../configs/datatype";
// Utils
import { decoded } from "../../../utils";
// Data
import { AppDispatch, RootState, actionAddMessages, actionGetMessages } from "../../../reduxs";

interface MessagesProps {
  user: Partial<DataType.UserProfile>;
  recipient: Partial<DataType.ConversationsData>;
}

export default function Messages(props: MessagesProps) {
  const { user, recipient } = props;

  const [content, setContent] = useState<string>("");
  // const [hasMore, setHasMore] = useState<boolean>(false);

  const bottomMessagesRef = useRef<HTMLDivElement>(null);
  const observer = useRef<HTMLDivElement | IntersectionObserver | any>();

  // Reduxs
  const { isOpenMessage, conversation, messages, messagesCursor, messagesHasMore } = useSelector((state: RootState) => { return state.rdcchats; });
  const dispatch: AppDispatch = useDispatch();

  const handleScrollToBottom = () => {
    bottomMessagesRef.current?.scrollIntoView();
  };

  const lastElementRef = useCallback((node) => {
    // if (loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      console.log("intersecting", entries[0].isIntersecting);
      console.log("has more: ", messagesHasMore);

      if (entries[0].isIntersecting && messagesHasMore) {
        // dispatch(actionGetMessages({ limit: messagesDataShowLimit, conversation: recipient.id!, cursor: messagesCursor }, false));
        console.log("last element");
      }
    });

    if (node) observer.current.observe(node);
  }, [messagesHasMore, messagesCursor]);

  useEffect(() => {
    handleScrollToBottom();
  });

  useEffect(() => {
    const pusher = new Pusher(decoded(__exch), {
      cluster: "ap1",
    });

    const channel = pusher.subscribe(`messages_${recipient.id || conversation.id}`);

    channel.bind(`inserted_${recipient.id || conversation.id}`, (newMessage: DataType.MessagesAttributes) => {
      dispatch({ type: "NEW_MESSAGES", payload: newMessage });
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [recipient, conversation]);

  const handleSubmitMessage = async (e: FormEvent) => {
    e.preventDefault();

    const data: DataType.MessagesAdd = {
      conversation_id: recipient.id! || conversation.id!,
      sender: {
        user_id: user.id!,
        name: user.name!,
      },
      content,
      is_received: false,
      created_at: new Date(),
      fcm_token: recipient.attributes?.participants?.fcm_token || conversation.attributes?.participants?.fcm_token, // receiver fcm_token
      notification: {
        participants: [
          {
            user_id: user.id,
            name: user.name,
            fcm_token: user.fcm_token!,
          },
          {
            shop_id: recipient.attributes?.participants?.shop_id || conversation.attributes?.participants?.shop_id,
            name: recipient.attributes?.participants?.name || conversation.attributes?.participants?.name,
            fcm_token: recipient.attributes?.participants?.fcm_token || conversation.attributes?.participants?.fcm_token,
          },
        ],
        conversation: { id: recipient.id! || conversation.id! },
        type: "seller", // receiver type
      },
    };

    dispatch(actionAddMessages(data));
    setContent("");
  };

  const imageURL = conversation.attributes
    ? conversation.attributes?.participants?.find((val: DataType.UserParticipants) => { return val.user_id !== user.id; })?.avatar
    : recipient.attributes?.participants?.avatar;

  const groups: any = (messages as Array<DataType.MessagesAttributes>).reduce((group, message) => {
    const temp: any = { ...group };

    const date = message.created_at.split("T")[0];

    if (!temp[date]) {
      temp[date] = [];
    }

    temp[date].push(message);

    return temp;
  }, {});

  const groupOfMessages: any = Object.keys(groups).map((date) => {
    return {
      date,
      data: groups[date],
    };
  });

  return (
    <div className="card d-flex flex-column rounded-0 border-0 h-100">
      {!isOpenMessage ? (
        <div className="flex-1 d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column text-center text-muted">
            <i className="icofont-live-messenger" style={{ fontSize: 50 }} />
            <span className="mt-2">Mulai obrolan</span>
          </div>
        </div>
      ) : (
        <>
          <div className="card-header d-flex align-items-center py-0" style={{ height: 64 }}>
            <div className="d-flex flex-1 align-items-center mr-4">
              <div
                className="rounded-circle position-relative overflow-hidden"
                style={{ height: 38, width: 38 }}
              >
                <Image
                  src="/"
                  layout="fill"
                  objectFit="cover"
                />
              </div>

              <div className="d-flex flex-column align-items-start ml-3">
                <p className="mb-0" style={{ fontSize: 12, fontWeight: 600 }}>
                  {
                    conversation.attributes
                      ? conversation.attributes?.participants?.find((val: DataType.UserParticipants) => { return val.user_id !== user.id; })?.name
                      : recipient.attributes?.participants?.name
                  }
                </p>
                <span className="text-muted" style={{ fontSize: 10 }}>
                  {/* {convertTimeAgo(conversation.attributes ? conversation.attributes.updated_at : recipient.attributes?.updated_at)} */}
                  Penjual
                </span>
              </div>
            </div>
          </div>

          <div className="list-message">

            {groupOfMessages.map((message: any, i: number) => {
              return (
                <div
                  key={i.toString()}
                  className="position-relative d-flex flex-column mb-4"
                  ref={messages.length >= messagesDataShowLimit && i === 0 ? lastElementRef : null}
                >
                  <div className="d-flex justify-content-center mb-3">
                    <span
                      className="px-3 py-2 text-muted rounded-pill"
                      style={{ fontSize: 10, fontWeight: 600, backgroundColor: "#fdfdfd" }}
                    >
                      {moment(message.date).calendar(null, { sameDay: "[Hari ini]", lastDay: "[Kemarin]", lastWeek: "DD MMMM YYYY", sameElse: "DD MMMM YYYY" })}
                    </span>
                  </div>

                  {message.data.map((messageData: DataType.MessagesAttributes, x: number) => {
                    return (
                      <div key={x.toString()} className="position-relative">
                        <div
                          className={`list-message-item ${messageData.sender.user_id === user.id && "sender"}`}
                        >
                          <div className="message-content">
                            <span>{messageData.content}</span>
                          </div>
                          <div className="message-time">
                            <i className="icofont-check-alt" />
                            <span>
                              {moment(messageData.created_at).format("HH:mm")}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div className="position-relative" ref={bottomMessagesRef} />
          </div>

          <div className="flex-1 d-flex px-3 align-items-center bg-light">
            <form className="d-flex" onSubmit={handleSubmitMessage}>
              <Input
                size="md"
                id="send-message"
                useFormGroup={false}
                inputProps={{
                  placeholder: "Tulis pesan disini...",
                  value: content || "",
                  onChange: (e) => { setContent(e.target.value); },
                }}
              />

              <Button
                size="md"
                variant="danger"
                icon="icofont-paper-plane"
                className="ml-2"
                buttonProps={{
                  type: "submit",
                }}
              />
            </form>
          </div>
        </>
      )}
    </div>
  );
}
