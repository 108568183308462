import { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import FormData from "form-data";
import Cookies from "js-cookie";
import moment from "moment";
import { toast } from "react-toastify";
// Utils
import { decoded, convertToKilo } from "../../../../utils";
// Data
import { serviceGetListWithdraw } from "../../../../services";

export default function TabRiwayat() {
  const [listRiwayat, setListRiwayat] = useState<Array<any>>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(0);

  const getListWithdraw = useCallback(async () => {
    try {
      const fd = new FormData();

      Cookies.get("_xSe") && fd.append("session_request", decoded(Cookies.get("_xSe") as string));
      fd.append("page", "");

      const response = await serviceGetListWithdraw(fd);

      if (response.status === "success") {
        setListRiwayat(response.data.lists);
        setPage(response.data.current_page);
        setTotalPage(response.data.max_page);
        setTotalData(response.data.total_data);
      }
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting withdraw list");
    }
  }, []);

  useEffect(() => {
    getListWithdraw();
  }, []);

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" style={{ width: 120 }}>Tanggal</th>
              <th scope="col">Deskripsi</th>
              <th scope="col" style={{ width: 150 }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {listRiwayat.length === 0 ? (
              <tr>
                <td colSpan={4} className="text-center" style={{ height: 100, verticalAlign: "middle" }}>
                  Belum pernah melakukan pencairan saldo.
                </td>
              </tr>
            ) : (
              <>
                {listRiwayat.map((riwayat: any, i: number) => {
                  const statusclass = cx("text-uppercase", {
                    "text-success": riwayat.status_transaksi === "success",
                    "text-info": riwayat.status_transaksi === "request",
                    "text-danger": riwayat.status_transaksi === "failed",
                  });

                  return (
                    <tr key={i.toString()}>
                      <th scope="row">{i + 1}</th>
                      <td>{moment(riwayat.created_date).format("DD MMM yyyy")}</td>
                      <td>
                        <p className="mb-1 text-muted">
                          Nominal:
                          {" "}
                          <span className="text-dark">{convertToKilo(riwayat.nominal)}</span>
                        </p>
                        <p className="mb-0 text-muted">
                          Keterangan:
                          {" "}
                          <span className="text-dark">{riwayat.keterangan ? riwayat.keterangan : "-"}</span>
                        </p>
                      </td>
                      <td>
                        <span className={statusclass} style={{ letterSpacing: 2 }}>{riwayat.status_transaksi}</span>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}

          </tbody>
        </table>
      </div>
    </div>
  );
}
