import { useState, useCallback, useEffect } from "react";
import Link from "next/link";
import Slider from "react-slick";
import { toast } from "react-toastify";
// Components
import { Button, Dialog } from "../../atoms";
import { CardCategory } from "../../molecules";
// Utils
import { uri } from "../../../configs";
import { CategoriesType } from "../../../configs/datatype";
// Data
import { serviceGetCategoriesForProduct } from "../../../services";

interface CategoryHomeProps {
  data: any;
  isLoading: boolean;
}

export default function CategoryHome(props: Partial<CategoryHomeProps>) {
  const { data, isLoading } = props;

  const [categories, setCategories] = useState<Array<CategoriesType>>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const getCategoriesForProduct = useCallback(async () => {
    try {
      const response = await serviceGetCategoriesForProduct();

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      const categoryData = [...response.data];

      for (let i = 0; i < categoryData.length; i += 1) {
        categoryData[i].logo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.category}/${categoryData[i].logo}`;
      }

      setCategories(categoryData);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting categories");
    }
  }, []);

  useEffect(() => {
    getCategoriesForProduct();
  }, []);

  const settings = {
    // slidesToScroll: 1,
    slidesToShow: 6,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="pt-3 pb-2  osahan-categories">
        <div className="d-flex align-items-center mb-2">
          <h5 className="m-0">Kategori</h5>

          <Button
            label="Lihat Semua"
            variant="danger"
            size="sm"
            outline
            className="ml-auto"
            buttonProps={{
              onClick: () => { setOpenDialog(true); },
              disabled: isLoading && categories.length === 0,
            }}
          />
        </div>

        <Slider {...settings} className="categories-slider">

          {isLoading && [...Array(12)].map((_, i) => {
            return (
              <div key={i.toString()} className="col-c d-flex flex-column" style={{ height: "100%" }}>
                <div className="bg-light shadow-sm rounded text-center my-2 px-2 py-3 c-it" style={{ height: 112 }} />
              </div>
            );
          })}

          {!isLoading && data.map((val: any, i: number) => {
            return <CardCategory key={i.toString()} category={val} />;
          })}

        </Slider>
      </div>

      <Dialog open={openDialog} size="lg">
        <div className="modal-header border-0">
          <h6 className="modal-title">Semua Kategori</h6>
        </div>
        <div className="modal-body">
          <div className="row d-flex flex-wrap">

            {categories.map((val: CategoriesType, i: number) => {
              return (
                <div key={i.toString()} className="col-md-2" style={{ height: 150 }}>
                  <CardCategory category={val} />
                </div>
              );
            })}

          </div>
        </div>
        <div className="modal-footer border-0">
          <Button
            label="Tutup"
            size="sm"
            variant="light"
            buttonProps={{
              onClick: () => { setOpenDialog(false); },
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
