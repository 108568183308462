import { useState, useCallback, useEffect, FormEvent, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import { toast } from "react-toastify";
// components
import ColumnAvatar from "./columnAvatar";
import { Button, Dialog, Input } from "../../atoms";
import { FormOtp } from "../../molecules";
// Configs
import { uri } from "../../../configs";
import { UserProfile } from "../../../configs/datatype";
// Utils
import { decoded, encoded, useLocalStorage } from "../../../utils";
// Data
import { serviceGetProfileDetail, serviceUpdateProfile } from "../../../services";
import { AppDispatch, RootState } from "../../../reduxs";
import ActionTypes from "../../../reduxs/actions/users/actionTypes";

type VerifyBy = "verify-handphone" | "verify-email";

export default function SectionProfile() {
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  // const [verifyBy, setVerifyBy] = useState<VerifyBy>("verify-email");
  const [data, setData] = useState<Partial<UserProfile>>({});

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: RootState) => { return state.rdcusers; });

  useEffect(() => {
    if (edit) {
      setData({
        name: user?.name,
        avatar: user?.avatar_file,
      });
    }
  }, [edit, user]);

  const handleForm = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  const handleSubmitEdit = async (isUpdating: "avatar" | "name", value?: string | File) => {
    // e: FormEvent<HTMLFormElement>
    // e.preventDefault();

    try {
      const _xSe = Cookies.get("_xSe");

      const fd = new FormData();

      fd.append("session_request", decoded(_xSe as string || ""));
      fd.append("avatar", isUpdating === "avatar" ? value : data?.avatar_file);
      fd.append("name", isUpdating === "avatar" ? user?.name : data?.name);

      const response = await serviceUpdateProfile(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);

        return;
      }

      const profile: UserProfile = { ...response.data.profile };

      profile.avatar = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original.profile}/${response.data.profile.avatar}`;
      profile.avatar_file = response.data.profile.avatar;

      dispatch({ type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS, payload: { data: profile, token: decoded(_xSe as string || "") } });

      localStorage.setItem("ufk", encoded(JSON.stringify(profile)));

      setEdit(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while editing profile");
    }
  };

  return (
    <div className="row">
      <div className="col-md-3 col-sm-12">

        <ColumnAvatar
          avatar={user?.avatar as string}
          doChange={handleForm}
          doEditAvatar={handleSubmitEdit}
        />

      </div>
      <div className="col-md-9 col-sm-12">
        <div className="d-flex flex-column mb-4 ml-4">
          <h6 className="mb-3">Biodata Diri</h6>
          <div className="d-flex mb-3">
            <span className="text-muted" style={{ width: 150 }}>Nama lengkap</span>

            <div className="d-flex align-items-center">
              <span className="mr-5">{user?.name}</span>

              <Button
                label="Ubah"
                variant="light"
                size="sm"
                icon="icofont-edit"
                buttonProps={{
                  onClick: () => { setEdit(true); },
                }}
              />
            </div>
          </div>

          {/* <div className="d-flex mb-3">
            <span className="text-muted" style={{ width: 150 }}>Tanggal lahir</span>
            <span>-</span>
          </div>
          <div className="d-flex">
            <span className="text-muted" style={{ width: 150 }}>Jenis kelamin</span>
            <span>-</span>
          </div> */}

        </div>
        <div className="d-flex flex-column mb-4 ml-4">
          <h6 className="mb-3">Kontak Akun</h6>

          {/* <div className="d-flex mb-5">
            <span className="text-muted" style={{ width: 150 }}>Email</span>

            <div className="d-flex flex-column">
              <span className="mb-2">{user?.email ? user.email : "-"}</span>

              {user?.email_verified_status === "verified" ? (
                <span className="text-success">
                  <i className="icofont-check-circled" />
                  {" "}
                  Terverifikasi
                </span>
              ) : (
                <div className="d-flex align-items-center">
                  <span className="text-info mr-5">Belum Terverifikasi</span>
                  <Button
                    label="Verifikasi"
                    variant="light"
                    size="sm"
                    buttonProps={{
                      onClick: () => {
                        handleDialog("verify-email");
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div> */}

          <div className="d-flex mb-5">
            <span className="text-muted" style={{ width: 150 }}>No. Handphone</span>

            <div className="d-flex flex-column">
              <span className="mb-2">
                (+62)
                {" "}
                {user?.phone?.[0] === "0" ? user?.phone?.slice(1) : user?.phone}
              </span>

              {user?.phone_verified_status === "verified" ? (
                <span className="text-success">
                  <i className="icofont-check-circled" />
                  {" "}
                  Terverifikasi
                </span>
              ) : (
                <div className="d-flex align-items-center">
                  <span className="text-info mr-5">Belum Terverifikasi</span>
                  <Button
                    label="Verifikasi"
                    variant="light"
                    size="sm"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={edit} size="md">
        <div className="modal-header border-0">
          <h6 className="mb-0" style={{ lineHeight: 1.5 }}>Kamu ingin mengubah nama?</h6>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <Input
              id="name"
              label="Nama Lengkap"
              size="lg"
              required
              inputProps={{
                placeholder: "",
                value: data?.name || "",
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  handleForm("name", e.target.value);
                },
              }}
              helper="Tulis nama lengkapmu tanpa gelar atau tanda baca"
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Batal"
            size="md"
            variant="light"
            buttonProps={{
              onClick: () => { setEdit(false); },
            }}
          />

          <Button
            label="Ya, Ubah"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: () => { handleSubmitEdit("name"); },
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
