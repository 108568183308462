import Image from "next/image";
// Components
import { Button } from "../../atoms";
import Rating from "../rating";
// Utils
import { formatDateTime } from "../../../utils";
// Configs
import { ListReview } from "../../../configs/datatype";

interface ItemReviewProps {
  variant: "awaiting" | "done" | "done-shop";
  data: any;
}

export default function ItemReview(props: ItemReviewProps) {
  const { variant, data } = props;

  return (
    <div className="card border-0 shadow-sm overflow-hidden">
      <div className="row">
        <div className="col-lg-3 col-md-4 d-flex flex-column align-items-center">
          <div
            className="position-relative"
            style={{ width: "100%", height: "100%" }}
          >
            <Image
              src={data.cover}
              alt=""
              layout="fill"
              objectFit="cover"
            />
          </div>

          {data.length > 1 && (
            <span
              className="badge badge-pill badge-light position-absolute"
              style={{ bottom: 10 }}
            >
              Ada
              {data.length}
              produk
            </span>
          )}
        </div>
        <div className=" col-lg-9 col-md-8">
          <div className="card-body">
            <div className="d-flex align-items-center mb-2">
              <p className="card-text mb-0 mr-4">
                INV:
                {data.invoice}
              </p>
              <p className="card-text mb-0 text-muted">
                {formatDateTime(data.created_date, "dd MMMM y")}
              </p>
            </div>

            {variant !== "awaiting" && (
              <div className="d-flex align-items-center mb-3">
                {/* <i className="icofont-star icone-start" /> */}
                <Rating value={data.rate} />
                <p className="card-text text-muted mb-0 ml-3">
                  (
                  {data.rate}
                  )
                </p>
              </div>
            )}

            {variant === "done-shop" && (
              <div className="d-flex align-items-center">
                <h6 className="card-text mb-1 mr-4">{data.member_name}</h6>
              </div>
            )}

            <p
              className="card-text text-left mb-0"
              style={{ fontSize: 12, color: "#212529" }}
            >
              {data.comment}
            </p>

            {variant === "awaiting" && (
              <div className="mt-4 d-flex align-items-center justify-content-end">
                <Button label="Beri Ulasan" variant="light" size="md" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
