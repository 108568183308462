import { NextRouter, useRouter } from "next/router";
import cx from "classnames";

interface SectionPrivacyContentProps {
  query: any;
}

export default function SectionPrivacyContent(props: SectionPrivacyContentProps) {
  const { query } = props;

  const { layout } = query;

  const bodyClass = cx("d-flex flex-column", {
    "py-4": layout !== "mobile",
  });

  return (
    <div className={bodyClass}>
      {layout !== "mobile" && (
        <h6 className="mb-4 profile-title">KEBIJAKAN PRIVASI</h6>
      )}

      <p className="mb-3">
        Foodizmart (PT. ______________ dan afiliasi-afiliasi, atau “kami”)
        mengumpulkan, menyimpan, menggunakan, mengolah, menguasai, mentransfer,
        mengungkapkan dan melindungi Informasi Pribadi anda. Kebijakan Privasi
        ini berlaku bagi seluruh pengguna aplikasi-aplikasi, situs web
        (www.foodizmart.id), layanan, atau produk (“Aplikasi”) kami, kecuali
        diatur pada kebijakan privasi yang terpisah
      </p>

      <ol className="pl-3">
        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Informasi Pribadi yang kami kumpulkan</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Kami mengumpulkan informasi yang mengidentifikasikan atau dapat
              digunakan untuk mengidentifikasi, menghubungi, atau menemukan orang
              atau perangkat yang terkait dengan informasi tersebut (Informasi
              Pribadi). Informasi Pribadi termasuk, tetapi tidak terbatas pada,
              nama, alamat, tanggal lahir, pekerjaan, nomor telepon, alamat e-mail,
              rekening bank dan detail kartu kredit, jenis kelamin, identifikasi
              (termasuk KTP, SIM, atau Paspor) atau tanda pengenal lainnya yang
              dikeluarkan oleh pemerintah, foto, kewarganegaraan, nomor telepon
              pengguna dan non-pengguna Aplikasi kami yang terdapat dalam daftar
              kontak telefon selular anda, data kesehatan, informasi keuangan
              terkait, serta informasi biometrik (termasuk namun tidak terbatas
              pengenalan wajah). Selain itu, untuk informasi lainnya, seperti profil
              pribadi, dan/atau nomor pengenal unik, yang dikaitkan atau digabungkan
              dengan Informasi Pribadi, maka informasi tersebut juga dianggap
              sebagai Informasi Pribadi. Informasi Pribadi yang kami kumpulkan dapat
              diberikan oleh anda secara langsung atau oleh pihak ketiga (misalnya:
              ketika anda mendaftar atau menggunakan Aplikasi, ketika anda
              menghubungi layanan pelanggan kami, atau sebaliknya ketika anda
              memberikan Informasi Pribadi kepada kami). Kami dapat mengumpulkan
              informasi dalam berbagai macam bentuk dan tujuan (termasuk tujuan yang
              diizinkan berdasarkan peraturan perundang-undangan yang berlaku).
              Informasi yang kami kumpulkan dari anda atau perangkat seluler anda
              secara langsung
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Ketika anda mendaftar dan membuat akun menggunakan Aplikasi, anda
              harus memberikan kepada kami Informasi Pribadi tertentu, termasuk
              nama, alamat fisik, alamat e-mail, dan nomor telepon anda. Jika anda
              menggunakan Aplikasi sebagai penyedia layanan, anda harus memberikan
              Informasi Pribadi tambahan kepada kami sebagai bagian dari proses
              pendaftaran penyedia layanan. Ini akan mencakup rincian rekening bank
              anda.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              <span>
                Ketika anda menggunakan aplikasi, anda harus memberikan kami informasi
                yang relevan yang mungkin diperlukan oleh kami agar Aplikasi dapat
                berfungsi, contohnya :
              </span>
              <ul className="text-muted pl-3 mb-0 mt-2">
                <li>
                  Jika anda menggunakan Aplikasi sebagai pengguna, anda harus
                  memberikan informasi pada kami sesuai dengan jenis layanan yang anda
                  cari, dan rincian alamat pengiriman.
                </li>
                <li>
                  Jika anda menggunakan Aplikasi sebagai penyedia layanan, agar
                  Aplikasi dapat berfungsi, anda harus memberikan kepada kami
                  informasi terkait dengan layanan yang anda dapat terima pesanannya
                  pada saat itu, dan perinciannya pada saat ini, dan setelah pesanan
                  untuk layanan yang ditempatkan oleh pengguna diterima oleh anda
                  sebagai penyedia layanan, anda mungkin akan perlu memberikan kepada
                  kami data lain yang kami perlukan untuk mengelola Aplikasi dan
                  ekosistem terkait, serta untuk memantau keseluruhan penggunaan
                  Aplikasi.
                </li>
                <li>
                  Jika anda menggunakan dan/atau ketika pembayaran atau transfer
                  dilakukan melalui fasilitas uang elektronik dan/atau dompet
                  elektronik yang disediakan oleh kami, jika anda adalah pihak
                  pembayar atau pengirim, anda akan menyediakan informasi kepada kami
                  yang berkaitan dengan penggunaan, pembayaran atau transfer, termasuk
                  tetapi tidak terbatas pada rincian transfer dan/atau penerima
                  pembayaran, jumlah pembayaran yang dibayarkan, jenis kartu
                  pembayaran dan rekening yang digunakan, nama penerbit kartu
                  pembayaran atau akun tersebut, nama pemegang dan nomor kartu
                  pembayaran atau rekening tersebut, kode verifikasi kartu pembayaran
                  atau rekening dan tanggal kadaluarsa kartu pembayaran atau rekening,
                  sebagaimana berlaku.
                </li>
                <li>
                  Jika anda bermaksud untuk mendaftar sebagai pemegang akun terdaftar
                  atau terverifikasi dari fasilitas uang elektronik yang disediakan
                  oleh kami, anda akan memberikan informasi kepada kami yang
                  diperlukan termasuk tetapi tidak terbatas pada nama lengkap anda,
                  nomor kartu identitas anda, jenis kartu identitas yang anda gunakan
                  untuk registrasi, alamat, jenis kelamin, tempat dan tanggal lahir,
                  pekerjaan, rincian pajak, kebangsaan dan/atau data biometrik.
                </li>
                <li>
                  Jika anda bermaksud untuk menambahkan kartu pembayaran atau rekening
                  sebagai sumber dana untuk pembayaran dalam Aplikasi, anda akan
                  memberikan kepada kami informasi terkait jenis kartu pembayaran dan
                  rekening yang didaftarkan, penerbit kartu pembayaran atau rekening,
                  nama pemegang kartu pembayaran dan rekening tersebut, nomor
                  identifikasi dari kartu pembayaran atau rekening tersebut dan kode
                  verifikasi kartu pembayaran atau rekening dan tanggal kadaluarsa
                  kartu pembayaran dan rekening tersebut, sebagaimana berlaku.
                </li>
              </ul>
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Informasi yang dikumpulkan setiap kali anda menggunakan Aplikasi atau
              mengunjungi situs web kami
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Setiap kali anda menggunakan Aplikasi atau mengunjungi situs web kami,
              kami dapat mengumpulkan data teknis tertentu sehubungan dengan
              penggunaan anda seperti, alamat protokol internet, informasi halaman
              web yang sebelumnya atau selanjutnya dilihat, durasi setiap
              kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses
              media, dan mobile advertising ID dan informasi perangkat lainnya
              termasuk informasi mengenai produsen, model, dan sistem operasi dari
              perangkat yang anda gunakan untuk mengakses Aplikasi atau situs web
              kami.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Ketika anda menggunakan Aplikasi atau mengunjungi situs web kami,
              informasi tertentu juga dapat dikumpulkan secara otomatis dengan
              menggunakan cookies. Cookies adalah berkas data kecil yang tersimpan
              pada komputer atau perangkat seluler anda. Kami menggunakan cookies
              untuk melacak aktivitas pengguna dengan tujuan untuk meningkatkan
              antarmuka dan pengalaman pengguna. Sebagian besar perangkat seluler
              dan peramban internet mendukung penggunaan cookies; namun anda dapat
              menyesuaikan pengaturan pada perangkat seluler atau peramban internet
              anda untuk menolak beberapa jenis cookies atau cookies spesifik
              tertentu. Perangkat seluler dan/atau peramban anda juga memungkinkan
              anda untuk menghapus cookies apa pun yang sebelumnya telah tersimpan.
              Namun demikian, hal itu dapat mempengaruhi fungsi-fungsi yang tersedia
              pada Aplikasi atau situs web kami.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Setiap kali anda menggunakan Aplikasi melalui perangkat seluler, kami
              akan melacak dan mengumpulkan informasi lokasi geografis anda dalam
              waktu sebenarnya. Dalam beberapa kasus, anda akan diminta atau
              diharuskan untuk mengaktifkan Global Positioning System (GPS) pada
              perangkat seluler anda untuk memungkinkan kami agar dapat memberikan
              anda pengalaman yang lebih baik dalam menggunakan Aplikasi (misalnya,
              untuk memberikan informasi tentang seberapa dekat penyedia layanan
              dengan anda). Anda dapat menonaktifkan informasi pelacakan lokasi
              geografis pada perangkat seluler anda untuk sementara waktu. Namun,
              hal ini dapat mempengaruhi fungsi-fungsi yang tersedia pada Aplikasi.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Jika anda menggunakan dan/atau ketika pembayaran atau transfer
              dilakukan melalui fasilitas uang elektronik dan/atau dompet elektronik
              yang disediakan oleh kami, kami dapat mengumpulkan informasi tertentu
              terkait dengan sumber dana yang anda gunakan untuk melakukan isi ulang
              (termasuk rincian rekening bank), rincian rekening penerima penarikan,
              riwayat transaksi (termasuk detail penerima), rincian tagihan, faktur,
              dan nomor telepon.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Jika anda menggunakan akun virtual yang disediakan oleh kami untuk
              menerima pembayaran menggunakan uang elektronik dan/atau dompet
              elektronik dari pembayar, baik anda penyedia layanan atau merchant,
              kami dapat mengumpulkan informasi tertentu yang terkait dengan
              penggunaan anda termasuk tetapi tidak terbatas pada layanan dan/atau
              barang yang ditransaksikan, jumlah yang anda kumpulkan dari setiap
              transaksi, rincian akun penarikan atau penyelesaian pembayaran dan
              riwayat penarikan atau penyelesaian pembayaran.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Jika anda menggunakan dan/atau pada saat suatu pembayaran dilakukan
              melalui kartu pembayaran atau rekening yang anda tambahkan di
              Aplikasi, kami dapat mengumpulkan informasi tertentu terkait catatan
              transaksi, termasuk rincian penerima, rincian tagihan, rincian tanda
              terima, dan rincian nomor telepon.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Informasi yang dikumpulkan dari pihak ketiga
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Kami juga dapat mengumpulkan Informasi Pribadi anda dari pihak ketiga
              (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak lainnya
              yang memberikan layanan kepada kami, mengumpulkan Data Pribadi
              dan/atau melakukan tugas atas nama kami, atau dengan siapa kami
              melakukan kerja sama). Dalam kasus tersebut, kami hanya akan
              mengumpulkan Informasi Pribadi anda untuk atau sehubungan dengan
              tujuan yang melibatkan pihak ketiga tersebut atau tujuan kerja sama
              kami dengan pihak ketiga tersebut (tergantung pada situasinya).
              Khususnya pada saat anda mendaftarkan kartu pembayaran atau rekening
              melalui Aplikasi dan/atau mengakses, menambahkan dan/atau
              menghubungkan kartu pembayaran atau rekening anda pada Aplikasi, kami
              dapat mengumpulkan informasi keuangan tertentu dan catatan finansial
              anda (termasuk tetapi tidak terbatas pada catatan transaksi, rincian
              dan penempatan kartu pembayaran atau rekening, dan/atau status dan
              negara kartu pembayaran atau rekening anda) dari penerbit kredensial
              pembayaran tersebut atau dari pihak ketiga lainnya.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Informasi tentang pihak ketiga yang anda berikan kepada kami
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Anda dapat memberikan kepada kami Informasi Pribadi yang berkaitan
              dengan individu pihak ketiga lainnya (termasuk Informasi Pribadi yang
              berkaitan dengan pasangan anda, anggota keluarga, teman, atau individu
              lain). Anda tentu saja akan memerlukan persetujuan dari mereka
              terlebih dahulu untuk melakukannya - lihat “Pengakuan dan
              Persetujuan”, di bawah, untuk informasi lebih lanjut.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Kami tidak mewajibkan atau mendukung, ataupun melarang, pemasangan
              atau penggunaan alat perekam dalam kendaraan di kendaraan apa pun.
              Jika perangkat tersebut dipasang dan digunakan di kendaraan, kami
              tidak mengumpulkan Informasi Pribadi apapun dari rekaman dalam
              kendaraan oleh perangkat tersebut, dan pengumpulan Informasi Pribadi
              dari rekaman di dalam kendaraan tersebut tidak dilakukan atas nama
              kami. Pengumpulan Informasi Pribadi dari rekaman di dalam kendaraan
              tersebut merupakan diskresi dari penyedia layanan. Kami tidak memiliki
              kendali atas pengumpulan Informasi Pribadi tersebut oleh penyedia
              layanan dan setiap penggunaan atau pengungkapan oleh penyedia layanan
              dari Informasi Pribadi yang dikumpulkan. Jika anda adalah penyedia
              layanan dan memilih untuk mengunduh dan menggunakan perangkat rekaman
              di dalam kendaraan tersebut di kendaraan anda, anda bertanggung jawab
              sepenuhnya untuk memberitahukan pengguna. Jika anda adalah seorang
              pengguna dan anda keberatan atas penggunaan alat perekam dalam
              kendaraan penyedia layanan, anda harus menyampaikan hal tersebut
              kepada penyedia layanan secara langsung dan hal tersebut menjadi
              tanggung jawab pribadi anda.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              ika anda menggunakan fitur pesan di Aplikasi kami, anda akan
              memberikan nomor telefon pengguna kami yang tersimpan di kontak
              telefon selular untuk membantu anda dalam menggunakan fitur pesan kami
              serta tujuan internal lainnya
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Penggunaan Informasi Pribadi yang kami kumpulkan</span>
          <p className="mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk tujuan
            sebagai berikut maupun untuk tujuan lain yang diizinkan oleh peraturan
            perundang-undangan yang berlaku (Tujuan):
          </p>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              <span>
                Jika anda adalah pengguna, kami dapat menggunakan Informasi
                Pribadi anda:
              </span>
              <ul className="text-muted pl-3 mb-0 mt-2">
                <li>
                  untuk mengidentifikasi dan mendaftarkan anda sebagai pengguna
                  dan untuk mengadministrasikan, memverifikasi, menonaktifkan,
                  atau mengelola akun anda;
                </li>
                <li>
                  untuk memfasilitasi atau memungkinkan verifikasi apapun yang
                  menurut pertimbangan kami diperlukan sebelum penyedia layanan
                  memberikan layanan kepada anda atau sebelum kami mendaftarkan
                  anda sebagai pengguna, termasuk melakukan proses Mengenal
                  Pelanggan (Know Your Customer - KYC) dan penentuan kredit
                  (credit scoring);
                </li>
                <li>
                  untuk memungkinkan penyedia layanan untuk menyediakan layanan
                  yang anda minta;
                </li>
                <li>
                  untuk mengolah dan memfasilitasi pesanan dan transaksi
                  pembayaran yang anda lakukan, termasuk sebagaimana berlaku,
                  transaksi yang dilakukan melalui kartu pembayaran atau rekening
                  yang tersedia pada Aplikasi;
                </li>
                <li>
                  untuk memberitahu anda atas transaksi atau aktivitas yang
                  terjadi dalam Aplikasi atau sistem lain yang terhubung dengan
                  Aplikasi kami;
                </li>
                <li>
                  untuk berkomunikasi dengan anda dan mengirimkan anda informasi
                  sehubungan dengan penggunaan Aplikasi;
                </li>
                <li>
                  untuk memberitahu anda mengenai segala pembaruan pada Aplikasi
                  atau perubahan pada layanan yang disediakan;
                </li>
                <li>
                  untuk mengolah dan menanggapi pertanyaan dan saran yang diterima
                  dari anda;
                </li>
                <li>
                  untuk memelihara, mengembangkan, menguji, meningkatkan, dan
                  mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan
                  preferensi anda sebagai pengguna;
                </li>
                <li>
                  untuk memantau dan menganalisis aktivitas, perilaku, dan data
                  demografis pengguna termasuk kebiasaan dan penggunaan berbagai
                  layanan yang tersedia di Aplikasi;
                </li>
                <li>untuk memproses dan mengelola reward points anda;</li>
                <li>
                  untuk menawarkan atau menyediakan layanan dari afiliasi atau
                  mitra kami
                </li>
                <li>
                  untuk mengirimkan anda komunikasi pemasaran, iklan, voucher,
                  survei, dan informasi secara langsung atau terfokus, dan
                  informasi tentang penawaran atau promosi khusus.
                </li>
              </ul>
            </li>

            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              <span>
                Jika anda adalah penyedia layanan, kami dapat menggunakan
                Informasi Pribadi anda
              </span>
              <ul className="text-muted pl-3 mb-0 mt-2">
                <li>
                  untuk mengidentifikasi anda dan mendaftarkan anda sebagai
                  penyedia layanan dan untuk mengadministrasi, mengelola atau
                  memverifikasi Akun anda;
                </li>
                <li>
                  untuk memfasilitasi atau memungkinkan verifikasi apapun yang
                  menurut pertimbangan kami mungkin perlu dilakukan sebelum kami
                  dapat mendaftarkan anda sebagai penyedia layanan, termasuk untuk
                  KYC (Know Your Customer) dan penilaian kredit (credit scoring);
                </li>
                <li>
                  untuk memungkinkan anda memberikan layanan kepada pengguna;
                  untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran
                  kepada anda terkait dengan layanan yang telah anda berikan;
                </li>
                <li>
                  untuk mengolah, memfasilitasi, dan menyelesaikan pembayaran
                  kepada anda terkait dengan layanan yang telah anda berikan;
                </li>
                <li>
                  untuk berkomunikasi dengan anda dan mengirimkan anda informasi
                  terkait dengan penyediaan layanan anda, termasuk untuk
                  menyampaikan pesanan pengguna kepada anda dan untuk
                  memfasilitasi penerimaan anda atas pesanan tersebut;
                </li>
                <li>
                  untuk memberikan anda pemberitahuan dan pembaruan pada Aplikasi
                  atau perubahan cara penyediaan layanan;
                </li>
                <li>
                  untuk memberi anda laporan terkait dengan layanan yang telah
                  anda sediakan;
                </li>
                <li>
                  untuk memproses dan menanggapi saran dari pengguna terhadap
                  layanan yang telah anda berikan;
                </li>
                <li>
                  untuk memelihara, mengembangkan, menguji, meningkatkan, dan
                  mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan
                  preferensi anda sebagai penyedia layanan;
                </li>
                <li>
                  untuk memantau dan menganalisis aktivitas, perilaku, dan data
                  demografis pengguna termasuk kebiasaan dan daya tanggap penyedia
                  layanan untuk berbagai layanan yang tersedia pada Aplikasi;
                </li>
                <li>untuk memproses dan mengelola reward points anda;</li>
                <li>
                  untuk menawarkan atau menyediakan layanan dari afiliasi atau
                  mitra kami;
                </li>
                untuk mengirimkan anda komunikasi pemasaran, iklan, promo, survei,
                dan penawaran khusus atau promosi secara langsung atau tertuju.
              </ul>
            </li>

            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              <span>
                Baik anda merupakan pengguna ataupun penyedia layanan atau
                pihak yang memberikan Informasi Pribadi kepada kami, kami juga
                dapat menggunakan Informasi Pribadi anda secara lebih umum untuk
                tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu kami
                akan bertindak secara wajar dan tidak menggunakan Informasi
                Pribadi anda lebih dari apa yang diperlukan untuk tujuan
                tersebut):
              </span>
              <ul className="text-muted pl-3 mb-0 mt-2">
                <li>untuk melakukan proses dan fungsi bisnis terkait;</li>
                <li>
                  untuk memantau penggunaan Aplikasi dan mengelola, mendukung
                  serta meningkatkan efisiensi kinerja, perkembangan, pengalaman
                  pengguna dan fungsi-fungsi Aplikasi;
                </li>
                <li>
                  untuk memberikan bantuan sehubungan dengan dan untuk
                  menyelesaikan kesulitan teknis atau masalah operasional dengan
                  Aplikasi atau layanan;
                </li>
                <li>
                  untuk memberikan bantuan sehubungan dengan dan untuk
                  menyelesaikan kesulitan teknis atau masalah operasional dengan
                  Aplikasi atau layanan;
                </li>
                <li>
                  untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang
                  dilarang, ilegal, tidak sah, atau curang;
                </li>
                <li>
                  untuk memfasilitasi transaksi aset bisnis (yang dapat berupa
                  penggabungan, akuisisi, atau penjualan aset) yang melibatkan
                  kami dan/atau afiliasi kami; dan
                </li>
                <li>
                  untuk memungkinkan kami mematuhi semua kewajiban berdasarkan
                  peraturan perundang-undangan yang berlaku, termasuk namun tidak
                  terbatas pada menanggapi permintaan, penyelidikan, atau arahan
                  peraturan, mematuhi persyaratan pengarsipan, pelaporan, dan
                  perizinan yang disyaratkan oleh peraturan yang berlaku) dan
                  melakukan pemeriksaan audit, uji tuntas dan penyelidikan.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pemberian Informasi Pribadi yang kami kumpulkan</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              <span>
                Kami dapat mengungkapkan atau membagikan Informasi Pribadi anda
                dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini serta
                untuk tujuan lain yang diizinkan oleh peraturan perundang-undangan
                yang berlaku:
              </span>
              <ul className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
                <li>
                  jika anda adalah pengguna, untuk memungkinkan penyedia layanan,
                  untuk melakukan atau memberikan layanan;
                </li>
                <li>
                  jika anda adalah penyedia layanan, untuk memungkinkan pengguna
                  untuk meminta atau menerima layanan dari anda;
                </li>
                <li>
                  jika disyaratkan atau diotorisasikan oleh peraturan
                  perundang-undangan yang berlaku (termasuk namun tidak terbatas
                  pada menanggapi pertanyaan terkait regulasi, penyelidikan atau
                  pedoman, atau mematuhi persyaratan atau ketentuan pengarsipan dan
                  pelaporan berdasarkan undang-undang), untuk tujuan yang ditentukan
                  dalam peraturan perundang-undangan yang berlaku;
                </li>
                <li>
                  jika diinstruksikan, diminta, disyaratkan atau diperbolehkan oleh
                  pemerintah yang berwenang, untuk tujuan sebagaimana disebutkan
                  dalam kebijakan pemerintah, peraturan atau peraturan
                  perundang-undangan yang berlaku.
                </li>
                <li>
                  jika terdapat proses hukum dalam bentuk apapun antara anda dengan
                  kami, atau antara anda dengan pihak lain, sehubungan dengan, atau
                  terkait dengan layanan, untuk keperluan proses hukum tersebut;
                </li>
                <li>
                  sehubungan dengan segala proses verifikasi kami atau pihak ketiga
                  perlukan sebelum pemberi layanan memberikan layanan kepada anda
                  atau kami mendaftarkan anda sebagai pengguna, termasuk proses KYC
                  (Know Your Customer) dan penentuan kredit (credit scoring);
                </li>
                <li>
                  dalam keadaan darurat terkait keselamatan anda (baik anda adalah
                  pengguna atau penyedia layanan) untuk keperluan menangani keadaan
                  darurat tersebut;
                </li>
                <li>
                  dalam situasi terkait kesehatan anda atau kepentingan umum (baik
                  anda adalah pengguna atau penyedia layanan), kami dapat membagikan
                  Informasi Pribadi anda kepada pemerintah yang berwenang dan/atau
                  institusi lainnya yang dapat ditunjuk oleh pemerintah yang
                  berwenang atau memiliki kerja sama dengan kami, untuk tujuan
                  pelacakan kontak, mendukung inisiatif, kebijakan atau program
                  pemerintah, kesehatan public dan tujuan lainnya sebagaimana
                  dibutuhkan secara wajar;
                </li>
                <li>
                  sehubungan dengan, penggabungan, penjualan aset perusahaan,
                  konsolidasi atau restrukturisasi, pembiayaan atau akuisisi semua
                  atau sebagian dari bisnis kami oleh atau ke perusahaan lain, untuk
                  keperluan transaksi tersebut (bahkan jika kemudian transaksi tidak
                  dilanjutkan);
                </li>
                <li>
                  sehubungan dengan proses klaim asuransi, kami akan membagikan
                  Informasi Pribadi anda untuk tujuan pemrosesan klaim asuransi
                  kepada perusahaan asuransi yang bekerja sama atau berkolaborasi
                  dengan kami;
                </li>
                <li>
                  kepada pihak ketiga (termasuk agen, vendor, pemasok, kontraktor,
                  mitra, dan pihak lain yang memberikan layanan kepada kami atau
                  anda, melakukan tugas atas nama kami, atau pihak dengan siapa kami
                  mengadakan kerja sama komersial), untuk atau sehubungan dengan
                  tujuan di mana pihak ketiga tersebut terlibat, untuk melaksanakan
                  pengungkapan kepada pihak ketiga terkait yang secara teknis
                  diperlukan untuk memproses transaksi anda atau tujuan kerja sama
                  kami dengan pihak ketiga tersebut (tergantung keadaannya), yang
                  dapat mencakup diperbolehkannya pihak ketiga tersebut untuk
                  memperkenalkan atau menawarkan produk atau layanan kepada anda,
                  melakukan autentikasi terhadap anda atau menghubungkan anda dengan
                  Akun atau melakukan kegiatan lain termasuk pemasaran, penelitian,
                  analisis dan pengembangan produk; dan
                </li>
                <li>
                  dalam hal kami berbagi Informasi Pribadi dengan afiliasi, kami
                  akan melakukannya dengan maksud agar mereka membantu kami dalam
                  menyediakan Aplikasi, untuk mengoperasikan bisnis kami (termasuk,
                  ketika anda berlangganan milis kami, untuk tujuan pemasaran
                  langsung), atau untuk tujuan pengolahan data atas nama kami.
                  Misalnya, sebuah afiliasi Gojek di negara lain dapat mengolah
                  dan/atau menyimpan Informasi Pribadi anda atas nama perusahaan
                  grup Gojek di negara anda. Semua afiliasi kami berkomitmen untuk
                  mengolah Informasi Pribadi yang mereka terima dari kami sesuai
                  dengan Kebijakan Privasi dan peraturan perundang-undangan yang
                  berlaku ini.
                </li>
              </ul>
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Ketika Informasi Pribadi tidak perlu dikaitkan dengan anda, kami
              akan melakukan upaya yang wajar untuk menghapus dikaitkannya
              Informasi Pribadi tersebut dengan anda sebagai individu sebelum
              mengungkapkan atau berbagi informasi tersebut.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Kami tidak akan menjual atau menyewakan Informasi Pribadi anda
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami dapat
              mengungkapkan dan membagikan Informasi Pribadi anda jika kami
              memberitahu anda dan kami telah memperoleh persetujuan anda untuk
              pengungkapan atau pembagian tersebut.
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Penyimpanan Informasi Pribadi</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Informasi Pribadi anda hanya akan disimpan selama diperlukan untuk
              memenuhi tujuan dari pengumpulannya, atau selama penyimpanan tersebut
              diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang
              berlaku. Kami akan berhenti menyimpan Informasi Pribadi, atau
              menghapus maksud dari dikaitkannya Informasi Pribadi tersebut dengan
              anda sebagai individu, segera setelah dianggap bahwa tujuan
              pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan dengan
              menyimpan Informasi Pribadi dan penyimpanan tidak lagi diperlukan
              untuk tujuan bisnis atau secara hukum.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa
              Informasi Pribadi anda disimpan oleh pihak lain dengan termasuk
              institusi pemerintah cara tertentu. Dalam hal kami membagikan
              Informasi Pribadi anda kepada institusi pemerintah yang berwenang
              dan/atau institusi lainnya yang dapat ditunjuk oleh pemerintah yang
              berwenang atau memiliki kerja sama dengan kami, anda menyetujui dan
              mengakui bahwa penyimpanan Informasi Pribadi anda oleh institusi
              terkait akan mengikuti kebijakan penyimpanan data masing-masing
              institusi tersebut. Informasi yang disampaikan melalui komunikasi
              antara pengguna dan penyedia layanan yang dilakukan selain melalui
              penggunaan Aplikasi (seperti melalui panggilan telepon, SMS, pesan
              seluler atau cara komunikasi lainnya dan pengumpulan atas Informasi
              Pribadi anda oleh agen kami) juga dapat disimpan dengan beberapa cara.
              Kami tidak mengizinkan penyimpanan Informasi Pribadi dengan cara
              demikian dan kami tidak bertanggung jawab kepada anda untuk hal
              tersebut. Kami tidak akan bertanggung jawab atas penyimpanan Informasi
              Pribadi anda. Anda setuju untuk mengganti rugi, membela, dan
              membebaskan kami, pejabat, direktur, karyawan, agen, mitra, pemasok,
              kontraktor, dan afiliasi kami dari dan terhadap setiap dan segala
              klaim, kerugian, kewajiban, biaya, kerusakan, dan ongkos (termasuk
              tetapi tidak terbatas pada biaya hukum dan pengeluaran biaya ganti
              rugi penuh) yang dihasilkan secara langsung atau tidak langsung dari
              setiap penyimpanan yang tidak sah atas Informasi Pribadi anda.
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Kebijakan Privasi</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Tunduk pada peraturan perundang-undangan yang berlaku, anda dapat
              meminta kepada kami untuk mengakses dan/atau mengoreksi Informasi
              Pribadi anda yang berada dalam kepemilikan dan penguasaan kami, dengan
              menghubungi kami di perincian yang disediakan di bawah ini.
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Kami berhak menolak permintaan anda untuk mengakses, atau untuk
              memperbaiki, sebagian atau semua Informasi Pribadi anda yang kami
              miliki atau kuasai jika diizinkan atau diperlukan berdasarkan
              perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di
              mana Informasi Pribadi tersebut dapat berisi referensi kepada orang
              lain atau di mana permintaan untuk akses atau permintaan untuk
              mengoreksi adalah untuk alasan yang kami anggap tidak relevan, tidak
              serius, atau menyulitkan.
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Tempat kami menyimpan Informasi Pribadi anda</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Saat anda menggunakan Aplikasi dan layanan kami di negara lain
              dimana Aplikasi kami dapat diakses (“Negara Tujuan”), kami dapat
              mentransfer Informasi Pribadi anda dari negara asal (“Negara Asal”) ke
              Negara Tujuan untuk membuat anda dapat mengakses dan memastikan
              pengalaman pelanggan yang lancar ketiga Anda menggunakan Aplikasi kami
              di luar negeri. Dalam hal tersebut, kami akan meminta persetujuan anda
              untuk mentransfer Data Pribadi anda dari Negara Asal ke Negara Tujuan
              untuk memastikan kepatuhan atas hukum dan peraturan yang berlaku
            </li>
            <li className="mb-3 text-dark" style={{ lineHeight: 1.5 }}>
              Informasi Pribadi dari anda yang kami kumpulkan dapat disimpan,
              ditransfer, atau diolah oleh penyedia layanan pihak ketiga. Kami akan
              menggunakan semua upaya yang wajar untuk memastikan bahwa semua
              penyedia layanan pihak ketiga tersebut memberikan tingkat perlindungan
              yang sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.
            </li>
            <li className="mb-3 text-dark">
              Kami akan mematuhi seluruh peraturan dan hukum yang berlaku dan
              menggunakan seluruh usaha yang terbaik untuk memastikan Afiliasi kami
              di negara lain dan seluruh pihak ketiga pemberi layanan memberikan
              tingkat perlindungan yang setara dengan komitmen kami dalam Kebijakan
              Privasi ini.
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Keamanan Informasi Pribadi anda</span>
          <p className="mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas kebijakan
            kami sendiri dari waktu ke waktu, untuk memastikan bahwa Kebijakan
            Privasi ini konsisten dengan perkembangan kami di masa depan, dan/atau
            perubahan persyaratan hukum atau peraturan. Jika kami memutuskan untuk
            mengubah Kebijakan Privasi ini, kami akan memberitahu anda tentang
            perubahan tersebut melalui pemberitahuan umum yang dipublikasikan pada
            Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail anda
            yang tercantum dalam Akun anda. Anda setuju bahwa anda bertanggung
            jawab untuk meninjau Kebijakan Privasi ini secara teratur untuk
            informasi terbaru tentang pengolahan data dan praktik perlindungan
            data kami, dan bahwa dengan anda terus menggunakan Aplikasi atau situs
            web kami, berkomunikasi dengan kami, atau mengakses dan menggunakan
            layanan setelah adanya perubahan apapun terhadap Kebijakan Privasi ini
            akan dianggap sebagai persetujuan anda terhadap Kebijakan Privasi ini
            dan segala perubahannya.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pengakuan dan persetujuan</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark">
              gan menyetujui Kebijakan Privasi, anda mengakui bahwa anda telah
              membaca dan memahami Kebijakan Privasi ini dan menyetujui segala
              ketentuannya. Secara khusus, anda setuju dan memberikan persetujuan
              kepada kami untuk mengumpulkan, menggunakan, membagikan,
              mengungkapkan, menyimpan, mentransfer, atau mengolah Informasi
              Pribadi anda sesuai dengan Kebijakan Privasi ini. Dalam keadaan di
              mana anda memberikan kepada kami Informasi Pribadi yang berkaitan
              dengan
            </li>
            <li className="mb-3 text-dark">
              Dalam keadaan di mana anda memberikan kepada kami Informasi Pribadi
              yang berkaitan dengan individu lain (seperti Informasi Pribadi yang
              berkaitan dengan pasangan anda, anggota keluarga, teman, atau pihak
              lain), anda menyatakan dan menjamin bahwa anda telah memperoleh
              persetujuan dari individu tersebut untuk, dan dengan ini menyetujui
              atas nama individu tersebut untuk, pengumpulan, penggunaan,
              pengungkapan dan pengolahan Informasi Pribadi mereka oleh kami.
            </li>
            <li className="mb-3 text-dark">
              Anda dapat menarik persetujuan anda untuk setiap atau segala
              pengumpulan, penggunaan atau pengungkapan Informasi Pribadi anda
              kapan saja dengan memberikan kepada kami pemberitahuan yang wajar
              secara tertulis menggunakan rincian kontak yang disebutkan di bawah
              ini. Anda juga dapat menarik persetujuan pengiriman komunikasi dan
              informasi tertentu dari kami melalui fasilitas &quot;opt-out&quot; atau
              pilihan &quot;berhenti berlangganan&quot; yang tersedia dalam pesan kami
              kepada anda. Tergantung pada keadaan dan sifat persetujuan yang anda
              tarik, anda harus memahami dan mengakui bahwa setelah penarikan
              persetujuan tersebut, anda mungkin tidak lagi dapat menggunakan
              Aplikasi atau layanan. Penarikan persetujuan anda dapat
              mengakibatkan penghentian Akun anda atau hubungan kontraktual anda
              dengan kami, dengan semua hak dan kewajiban yang muncul sepenuhnya
              harus dipenuhi. Setelah menerima pemberitahuan untuk menarik
              persetujuan untuk pengumpulan, penggunaan atau pengungkapan
              Informasi Pribadi anda, kami akan menginformasikan anda tentang
              konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga
              anda dapat memutuskan apakah anda tetap ingin menarik persetujuan.
            </li>
          </ol>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Materi pemasaran dan promosi</span>
          <p className="mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami dan mitra kami dapat mengirimkan anda pemasaran langsung, iklan,
            dan komunikasi promosi melalui aplikasi push-notification, pesan
            melalui Aplikasi, pos, panggilan telepon, layanan pesan singkat (SMS),
            Platform Chat, Media Sosial, dan email (“Materi Pemasaran”) jika anda
            telah setuju untuk berlangganan milis kami, dan/atau setuju untuk
            menerima materi pemasaran dan promosi dari kami. Anda dapat memilih
            untuk tidak menerima komunikasi pemasaran tersebut kapan saja dengan
            mengklik pilihan “berhenti berlangganan” yang ada dalam pesan yang
            bersangkutan, atau menghubungi kami melalui detail kontak yang
            tercantum di bawah ini. Mohon perhatikan bahwa jika anda memilih untuk
            keluar, kami masih dapat mengirimi anda pesan-pesan non-promosi,
            seperti tanda terima atau informasi tentang Akun anda.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Data anonim</span>
          <p className="mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan
            Informasi Pribadi, yang tersedia, dengan catatan, (i) bahwa semua hal
            yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri
            atau dikombinasi dengan data lain yang tersedia, tidak dapat
            dihubungkan dengan atau dikaitkan dengan atau tidak dapat
            mengidentifikasi suatu individu, dan (ii) data serupa telah
            digabungkan sehingga data asli membentuk bagian dari kumpulan data
            yang lebih besar.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Platform pihak ketiga</span>
          <ol type="A" className="text-muted pl-3 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li className="mb-3 text-dark">
              Aplikasi, situs web, dan Materi Pemasaran dapat berisi tautan ke
              situs web yang dioperasikan oleh pihak ketiga. Kami tidak
              mengendalikan atau menerima pertanggungjawaban atau tanggung jawab
              untuk situs web ini dan untuk pengumpulan, penggunaan,
              pemeliharaan, berbagi, atau pengungkapan data dan informasi oleh
              pihak ketiga tersebut. Silakan baca syarat dan ketentuan dan
              kebijakan privasi dari situs web pihak ketiga tersebut untuk
              mengetahui bagaimana mereka mengumpulkan dan menggunakan Informasi
              Pribadi anda.
            </li>
            <li className="mb-3 text-dark">
              Saat anda menggunakan Aplikasi kami dan mengaktifkan fitur sidik
              jari (fingerprint)dan/atau pengenalan wajah (facial recognition)
              di perangkat seluler anda untuk keperluan autentikasi, harap
              dicatat bahwa kami tidak menyimpan data biometrik tersebut.
              Kecuali diberitahukan lain kepada anda, data tersebut tersimpan di
              perangkat seluler anda dan dapat disimpan oleh pihak ketiga,
              seperti pembuat perangkat anda. Anda setuju dan mengakui bahwa
              kami tidak bertanggung jawab atas setiap akses tidak sah atau
              kerugian apapun atas data biometrik yang tersimpan di perangkat
              seluler anda.
            </li>
            <li className="mb-3 text-dark">
              Iklan yang terdapat pada Aplikasi, situs web, dan Materi Pemasaran
              kami berfungsi sebagai tautan ke situs web pengiklan dan dengan
              demikian segala informasi yang mereka kumpulkan berdasarkan klik
              anda pada tautan itu akan dikumpulkan dan digunakan oleh pengiklan
              yang relevan sesuai dengan kebijakan privasi pengiklan tersebut.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
