import { useState, useRef, useEffect, useCallback } from "react";
import { NextRouter, useRouter } from "next/router";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { CardProduct } from "../../molecules";
// Configs
import { uri } from "../../../configs/constants";
import { Locations, Products } from "../../../configs/datatype";
// Utils
import { convertToDistance } from "../../../utils";
// Data
import { serviceGetListProducts } from "../../../services";

interface SectionProductsProps {
  location: Partial<Locations>;
  sorted: string;
  categored: number;
}

export default function SectionProducts(props: SectionProductsProps) {
  const { location, sorted, categored } = props;

  // const [location, setLocatiosetLocationn] = useState<Partial<Locations>>({});
  const [products, setProducts] = useState<Array<Products>>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const router: NextRouter = useRouter();

  const { keywords, category } = router.query;

  const observer = useRef<any>();

  const lastElementRef = useCallback((node) => {
    if (loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => { return prevPage + 1; });
      }
    });

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const getProducts = useCallback(async (cari: typeof keywords, categoryId: typeof category, pageNumber: typeof page) => {
    setLoading(true);

    try {
      const fd = new FormData();
      fd.append("search_type", "product");
      fd.append("search_key", cari || "");
      fd.append("filter[product_category_id]", categoryId || "");
      // fd.append("filter[product_categor_id]", categoryId || "");
      fd.append("pagination", pageNumber);

      const response = await serviceGetListProducts(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setHasMore(false);
        setLoading(false);

        return;
      }

      const data = response.data.product_result;

      setProducts((prevState) => { return [...prevState, ...data]; });
      setHasMore(data.length > 0);
      setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setLoading(false);

        return;
      }

      toast.error("Something went wrong while getting product");
      setLoading(false);
    }
  }, []);

  const handleAddDistance = (latitude: string, longitude: string) => {
    const nearbyInMeters = products.length > 0 && location?.coordinate ? convertToDistance(location?.coordinate, { latitude, longitude }) : 0;

    const nearbyInKilometers = nearbyInMeters / 1000;

    return nearbyInKilometers.toFixed(1);
  };

  // useEffect(() => {
  //   const currentLocation = sessionStorage.getItem("location");

  //   if (currentLocation) setLocation(JSON.parse(currentLocation));
  // }, []);

  useEffect(() => {
    setProducts([]);
    setPage(1);
  }, [keywords, category]);

  useEffect(() => {
    getProducts(keywords, category, page);
  }, [keywords, category, page]);

  const formatedData = products?.map((val: Partial<Products>) => {
    const temp = { ...val };

    // Type of size on image uri: original, medium, small
    const baseUrl = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/`;

    temp.photo = baseUrl + val.photo;
    temp.information = JSON.parse(temp.information);
    temp.distance = handleAddDistance(temp.shop_latitude!, temp.shop_longitude!);

    return temp;
  });

  switch (sorted) {
    case "termurah":
      formatedData.sort((a, b) => { return a.price! - b.price!; });
      break;
    case "termahal":
      formatedData.sort((a, b) => { return b.price! - a.price!; });
      break;
    case "rating":
      formatedData.sort((a, b) => { return b.rating_star! - a.rating_star!; });
      break;
    case "order-terbanyak":
      formatedData.sort((a, b) => { return b.qty_order! - a.qty_order!; });
      break;
    default:
      break;
  }

  return (
    <div id="products" className="container">
      <div className="pick_today">

        <div className="row">
          {formatedData?.map((val: Partial<Products>, i: number) => {
            if (formatedData.length === i + 1) {
              return (
                <div key={i.toString()} className="col-12 col-sm-6 col-md-3 mb-3" ref={lastElementRef}>
                  <CardProduct product={val} />
                </div>
              );
            }

            return (
              <div key={i.toString()} className="col-12 col-sm-6 col-md-3 mb-3">
                <CardProduct product={val} />
              </div>
            );
          })}
        </div>

        {loading && (
          <div className="w-100 position-relative" style={{ background: "none" }}>
            <div className="d-flex align-items-center justify-content-center text-muted" style={{ height: 300 }}>
              <i className="icofont-spinner animate-spin" style={{ fontSize: 28 }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
