import { useState } from "react";
// Components
import { Select } from "../../atoms";
// Configs
import { Topic } from "../../../configs/datatype";

type SectionSelectTopicsProps = {
  selectedTopic: Topic;
  doSelectedTopic: (value: Topic) => void;
}

const SectionSelectTopics = (props: SectionSelectTopicsProps) => {
  const { selectedTopic, doSelectedTopic } = props;

  const [topics, setTopics] = useState<Array<Topic>>(["Akun Saya", "Pembelian", "Cara Bayar", "Pengiriman", "Pesanan Saya", "Saldo", "Penjual", "Komplain"]);

  return (
    <div className="container mb-4">

      <div className="row">
        <div className="col-md-3">
          <h6>Topik</h6>

          <Select
            id="code-area"
            // label="Topik"
            selectProps={{
              value: selectedTopic || "",
              onChange: (e) => { doSelectedTopic(e.target.value as Topic); },
            }}
          >
            {topics.map((topic: Topic, i: number) => {
              return (
                <option key={i.toString()} value={topic} selected={topic === selectedTopic}>
                  {topic}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default SectionSelectTopics;
