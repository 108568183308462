import { useEffect, useState } from "react";
import Link from "next/link";
// Components
import { Logo } from "../../atoms";
import SetLocation from "./setLocation";
import SearchBar from "./searchBar";
import HeaderNavbar from "./navbar";
import UserAuth from "./userauth";
// import Notification from "./notification";
import Cart from "./cart";
// Data
import { Locations } from "../../../configs/datatype";

interface HeaderProps {
  isLogin: boolean;
  doSetLogin: (value: boolean) => void;
  doSetLocation: (val: Locations) => void;
}

const Header = (props: HeaderProps) => {
  const { isLogin, doSetLogin, doSetLocation } = props;

  return (
    <div className="bg-white shadow-sm osahan-main-nav">
      <nav className="navbar navbar-expand-lg navbar-light bg-white osahan-header py-0 container">
        <Logo />

        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}

        <div className="ml-3 d-flex align-items-center">
          <SetLocation doSetLocation={doSetLocation!} />
          <SearchBar />
        </div>
        <div className="ml-auto d-flex align-items-center">
          {/* <UserAuth /> */}
          <UserAuth doSetLogin={doSetLogin} />
          {/* {isLogin && <Notification />} */}
          <Cart isLogin={isLogin} />
        </div>
      </nav>

      <HeaderNavbar />
    </div>
  );
};

export default Header;
