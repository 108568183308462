import { useState } from "react";
// Component
import { ItemReview } from "../../molecules";

// Dummy data, Will deleted soon!
const DummyReviewWaiting = [
  {
    member_name: "M Firman Setiawan",
    invoice: "7568 343 BB",
    created_date: "03/20/2021",
    rate: 4.7,
    comment: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque dolorum rerum delectus numquam molestias consequuntur a dicta facilis, similique cum!",
    items: [1, 2],
    cover: "/images/product.png",
  },
];

export default function ListReviewAwaiting() {
  return (
    <>
      {DummyReviewWaiting.map((review: any, i: number) => {
        return (
          <div key={i.toString()} className="flex-1 mb-3">
            <ItemReview
              variant="awaiting"
              data={review}
            />
          </div>
        );
      })}
    </>
  );
}
