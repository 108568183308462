import { useState, useEffect, useCallback, ChangeEvent } from "react";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { Input, Select, Multiple, Button } from "../../atoms";
import AddressMap from "./map";
// Configs
import { KindOfType, Locations, Regions, AddressAddData } from "../../../configs/datatype";
// Services
import { serviceGetRegions, serviceGetLocationByGoogle } from "../../../services";

type RegionName = "provinsi" | "kabupaten" | "kecamatan" | "kelurahan"

interface FormInputAddressProps {
  data: Partial<AddressAddData>;
  error: Partial<AddressAddData>;
  edit: boolean;
  dataLocation: Partial<Locations>;
  initMapLocation: boolean;
  doChangeKind: (value: KindOfType) => void;
  doChangeDataLocation: (value: Partial<Locations>) => void;
  doSetData: (value: Partial<AddressAddData>) => void;
  setInitMapLocation: (value: boolean) => void;
}

type FormValue = { id: number | undefined, name: string | undefined} | string | number | undefined;

type CountryCode = {
  code: string;
  label: string;
  value: string;
}

const countryCodeList: Array<CountryCode> = [
  {
    code: "id",
    label: "Indonesia",
    value: "62",
  },
];

export default function FormInputAddress(props: FormInputAddressProps) {
  const { data, error, edit, dataLocation, initMapLocation, doChangeKind, doChangeDataLocation, doSetData, setInitMapLocation } = props;

  const [listProvinsi, setListProvinsi] = useState<Array<Regions>>([]);
  const [listKabupaten, setListKabupaten] = useState<Array<Regions>>([]);
  const [listKecamatan, setListKecamatan] = useState<Array<Regions>>([]);
  const [listKelurahan, setListKelurahan] = useState<Array<Regions>>([]);
  const [provinsi, setProvinsi] = useState<Partial<Regions>>({});
  const [kabupaten, setKabupaten] = useState<Partial<Regions>>({});
  const [kecamatan, setKecamatan] = useState<Partial<Regions>>({});
  const [kelurahan, setKelurahan] = useState<Partial<Regions>>({});

  const getRegions = useCallback(async (regionName: RegionName, region: any | null) => {
    try {
      const fd = new FormData();

      fd.append("level", regionName);

      if (regionName === "kabupaten") {
        fd.append("regional[provinsi][id]", region.provinsi);
      }

      if (regionName === "kecamatan") {
        fd.append("regional[provinsi][id]", region.provinsi);
        fd.append("regional[kabupaten][id]", region.kabupaten);
      }

      if (regionName === "kelurahan") {
        fd.append("regional[provinsi][id]", region.provinsi);
        fd.append("regional[kabupaten][id]", region.kabupaten);
        fd.append("regional[kecamatan][id]", region.kecamatan);
      }

      const response = await serviceGetRegions(fd);

      if (response.status === "success" && regionName === "provinsi") setListProvinsi(response.data.list);
      if (response.status === "success" && regionName === "kabupaten") setListKabupaten(response.data.list);
      if (response.status === "success" && regionName === "kecamatan") setListKecamatan(response.data.list);
      if (response.status === "success" && regionName === "kelurahan") setListKelurahan(response.data.list);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error(`Something when wrong when getting regions ${regionName}`);
    }
  }, []);

  const getLocation = useCallback(async (regionName: string) => {
    try {
      // const userlocation = cityname ? { search_text: cityname } : { lng: locate?.coordinates?.lng, lat: locate?.coordinates?.lat };

      const response: Locations = await serviceGetLocationByGoogle(regionName, "address");

      // const response: Locations = await serviceGetLocationByMapbox(userlocation, type);

      doChangeDataLocation(response);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something when wrong when getting location");
    }
  }, []);

  useEffect(() => {
    getRegions("provinsi", null);

    if (edit) {
      getRegions("kabupaten", { provinsi: data?.provinsi?.id });
      getRegions("kecamatan", { provinsi: data?.provinsi?.id, kabupaten: data?.kabupaten?.id });
      getRegions("kelurahan", { provinsi: data?.provinsi?.id, kabupaten: data?.kabupaten?.id, kecamatan: data?.kecamatan?.id });

      setProvinsi({ id: data?.provinsi?.id, name: listProvinsi.find((val) => { return val.id === data?.provinsi?.id; })?.name });
      setKabupaten({ id: data?.kabupaten?.id, name: listKabupaten.find((val) => { return val.id === data?.kabupaten?.id; })?.name });
      setKecamatan({ id: data?.kecamatan?.id, name: listKecamatan.find((val) => { return val.id === data?.kecamatan?.id; })?.name });
      setKelurahan({ id: data?.kelurahan?.id, name: listKelurahan.find((val) => { return val.id === data?.kelurahan?.id; })?.name });
    }
  }, [edit, data]);

  const handleForm = (key: string, value: FormValue) => {
    doSetData({ ...data, [key]: value });
  };

  const handleInputPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const regex = /^[0-9]*$/;

    if (value.length > 0 && value[0] === "0") {
      value = value.substring(1);
    }

    if (value.length > 14) {
      value = value.slice(0, 14);
    }

    if (regex.test(value)) {
      handleForm("phone", value);
    }
  };

  const handleSelectProvinsi = (value: number) => {
    setProvinsi({ id: value, name: listProvinsi.find((val) => { return val.id === value; })?.name });
    handleForm("provinsi", { id: value, name: listProvinsi.find((val) => { return val.id === value; })?.name });

    getRegions("kabupaten", { provinsi: value.toString() });
  };

  const handleSelectKabupaten = (value: number) => {
    setKabupaten({ id: value, name: listKabupaten.find((val) => { return val.id === value; })?.name });
    handleForm("kabupaten", { id: value, name: listKabupaten.find((val) => { return val.id === value; })?.name });

    getRegions("kecamatan", { provinsi: provinsi.id, kabupaten: value.toString() });
  };

  const handleSelectKecamatan = (value: number) => {
    setKecamatan({ id: value, name: listKecamatan.find((val) => { return val.id === value; })?.name });
    handleForm("kecamatan", { id: value, name: listKecamatan.find((val) => { return val.id === value; })?.name });

    getRegions("kelurahan", { provinsi: provinsi.id, kabupaten: kabupaten.id, kecamatan: value.toString() });
  };

  const handleSelectKelurahan = (value: number) => {
    setKelurahan({ id: value, name: listKelurahan.find((val) => { return val.id === value; })?.name });
    handleForm("kelurahan", { id: value, name: listKelurahan.find((val) => { return val.id === value; })?.name });

    // getLocation(listKelurahan.find((val) => { return val.id === value; })?.name as string);
  };

  return (
    <div className="row">
      {!initMapLocation && (
        <>
          <div className="col-md-12">
            <div
              className="d-flex flex-column"
              style={{
                marginBottom: "1.5rem",
                paddingBottom: ".5rem",
                borderBottom: "1px dashed #ddd",
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <Input
                    id="simpan"
                    label="Simpan sebagai?"
                    error={error.address_alias !== undefined}
                    inputProps={{
                      placeholder: "Alamat kontrakanku",
                      prepend: (
                        <span className="input-group-text"><i className="icofont-address-book" /></span>
                      ),
                      value: data.address_alias || "",
                      onChange: (e) => { handleForm("address_alias", e.target.value); },
                    }}
                    helper={error.address_alias ? error.address_alias : ""}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Input
                    id="nama"
                    label="Nama kontak?"
                    error={error.recipient_name !== undefined}
                    inputProps={{
                      placeholder: "Jaka Sudrajat",
                      prepend: (
                        <span className="input-group-text"><i className="icofont-user-alt-1" /></span>
                      ),
                      value: data.recipient_name || "",
                      onChange: (e) => { handleForm("recipient_name", e.target.value); },
                    }}
                    helper={error.recipient_name ? error.recipient_name : ""}
                  />
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3">
                      <Select
                        id="code-area"
                        label="Kode"
                        selectProps={{
                          // placeholder: "Kode Negara",
                          value: data.country_code || "62",
                          onChange: (e) => { handleForm("country_code", e.target.value); },
                        }}
                      >
                        {countryCodeList.map((val: CountryCode, i: number) => {
                          return (
                            <option key={i.toString()} value={val.value} selected={val.value === data.country_code}>
                              +
                              {val.value}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="col-md-9">
                      <Input
                        id="telepon"
                        label="Nomor telepon kontak?"
                        error={error.phone !== undefined}
                        inputProps={{
                          placeholder: "87839800006",
                          prepend: (
                            <span className="input-group-text"><i className="icofont-smart-phone" /></span>
                          ),
                          value: data.phone?.[0] === "0" ? data.phone.slice(1) : data.phone || "",
                          onChange: handleInputPhoneNumber,
                        }}
                        helper={error.phone ? error.phone : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <Select
              id="provinsi"
              label="Provinsi alamat kamu?"
              error={error.provinsi !== undefined}
              selectProps={{
                placeholder: "Pilih provinsi",
                prepend: (
                  <span className="input-group-text"><i className="icofont-stylish-right" /></span>
                ),
                value: provinsi.id || "Pilih provinsi",
                onChange: (e) => { handleSelectProvinsi(parseInt(e.target.value, 10)); },
              }}
              helper={error.provinsi ? error.provinsi : ""}
            >
              {listProvinsi.map((val: Regions, i: number) => {
                return (
                  <option key={i.toString()} value={val.id} selected={val.id === provinsi.id}>
                    {val.name}
                  </option>
                );
              })}
            </Select>

            {listKabupaten.length > 0 && (
              <Select
                id="kabupaten"
                label="Dimana kotamu / kabupatenmu?"
                error={error.kabupaten !== undefined}
                selectProps={{
                  placeholder: "Pilih kabupaten",
                  prepend: (
                    <span className="input-group-text"><i className="icofont-stylish-right" /></span>
                  ),
                  value: kabupaten.id || "Pilih kabupaten",
                  onChange: (e) => { handleSelectKabupaten(parseInt(e.target.value, 10)); },
                }}
                helper={error.kabupaten ? error.kabupaten : ""}
              >
                {listKabupaten.map((val: Regions, i: number) => {
                  return (
                    <option key={i.toString()} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </Select>
            )}

            {listKecamatan.length > 0 && (
              <Select
                id="kecamatan"
                label="Di kecamatan mana?"
                error={error.kecamatan !== undefined}
                selectProps={{
                  placeholder: "Pilih kecamatan",
                  prepend: (
                    <span className="input-group-text"><i className="icofont-stylish-right" /></span>
                  ),
                  value: kecamatan.id || "Pilih kecamatan",
                  onChange: (e) => { handleSelectKecamatan(parseInt(e.target.value, 10)); },
                }}
                helper={error.kecamatan ? error.kecamatan : ""}
              >
                {listKecamatan.map((val: Regions, i: number) => {
                  return (
                    <option key={i.toString()} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </Select>
            )}

            {listKelurahan.length > 0 && (
              <Select
                id="kelurahan"
                label="Nama kelurahannya?"
                error={error.kelurahan !== undefined}
                selectProps={{
                  placeholder: "Pilih kelurahan",
                  prepend: (
                    <span className="input-group-text"><i className="icofont-stylish-right" /></span>
                  ),
                  value: kelurahan.id || "Pilih kelurahan",
                  onChange: (e) => { handleSelectKelurahan(parseInt(e.target.value, 10)); },
                }}
                helper={error.kelurahan ? error.kelurahan : ""}
              >
                {listKelurahan.map((val: Regions, i: number) => {
                  return (
                    <option key={i.toString()} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </Select>
            )}

            <Multiple
              id="Alamat"
              label="Nama Jalan"
              error={error.address !== undefined}
              inputProps={{
                placeholder: "Jln. Jendral Sudirman No. 1, RT. 01/RW. 01",
                rows: 3,
                value: data.address || "",
                onChange: (e) => { handleForm("address", e.target.value); },
              }}
              helper={error.address ? error.address : ""}
            />

            <Input
              id="ciri-bangunan"
              label="Ciri bangunan (opsional)"
              inputProps={{
                placeholder: "Pagar hitam / rumah ke 3 / lantai 7",
                value: data.address_detail || "",
                onChange: (e) => { handleForm("address_detail", e.target.value); },
              }}
            />
          </div>
        </>
      )}

      <div className={`${initMapLocation ? "col-md-12" : "col-md-6"} position-relative`}>
        <AddressMap
          isOpened
          dataLocation={dataLocation}
          doChangeDataLocation={doChangeDataLocation}
          initMapLocation={initMapLocation}
        />

        {initMapLocation && (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              right: 20,
            }}
          >
            <button
              className="btn btn-light btn-sm rounded d-flex align-items-center justify-content-center"
              onClick={() => { setInitMapLocation(false); }}
            >
              <span className="pl-3" style={{ fontWeight: 500 }}>Selanjutnya</span>
              <i className="icofont-dotted-right pl-1" style={{ fontSize: 24 }} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
