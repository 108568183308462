interface RatingProps {
  value: number;
  size?: number;
}

export default function Rating(props: RatingProps) {
  const { value, size } = props;

  const fixedValue = value ? value.toFixed(1) : 0;
  const rateBeforeComma = parseInt(fixedValue.toString().split(".")[0], 10);
  const rateAfterComma = parseInt(fixedValue.toString().split(".")[1], 10);

  let starwidth = "100%";
  if (rateAfterComma > 7 && rateAfterComma <= 9) {
    starwidth = "90%";
  } else if (rateAfterComma > 5 && rateAfterComma <= 7) {
    starwidth = "75%";
  } else if (rateAfterComma > 3 && rateAfterComma <= 5) {
    starwidth = "50%";
  } else if (rateAfterComma <= 3) {
    starwidth = "25%";
  }

  let gap = 10;
  if (size! > 20) {
    gap = 20;
  }

  return (
    <div className="d-flex align-items-center">
      {[...Array(5)].map((_, i) => {
        const index = i + 1;

        return (
          <div
            key={i.toString()}
            style={{ marginRight: index === 5 ? 0 : gap }}
          >
            <div
              style={{
                fontSize: size,
                color: index <= rateBeforeComma ? "#f2c94c" : "#bdbdbd",
              }}
            >
              <i className="icofont-star" />
            </div>
            {/* <Icon.StarIcon
              size={size}
              color={
                index <= rateBeforeComma
                  ? theme.colors.yellow
                  : theme.colors.grey2
              }
            /> */}
          </div>
        );
      })}
    </div>
  );
}

Rating.defaultProps = {
  size: 18,
};
