import { useRouter, NextRouter } from "next/router";
import Image from "next/image";
// Components
import { Button } from "../../../atoms";
// Configs
import { uri, routesName } from "../../../../configs";
// Utils
import { convertToIdr, setRoute } from "../../../../utils";

interface detailSectionProducts {
  data: any;
}

export default function DetailSectionProducts(props : detailSectionProducts) {
  const { data } = props;

  const router: NextRouter = useRouter();

  const products = data?.items?.map((item: any) => {
    const temp = { ...item };

    temp.product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${item.product_photo}`;

    return temp;
  });

  return (
    <div className="card mb-4">
      <div className="card-header">
        <span>Pesanan</span>
      </div>
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className="position-relative rounded overflow-hidden"
              style={{
                width: 50,
                height: 50,
              }}
            >
              <Image
                src={data.shop_logo ? data.shop_logo : "/"}
                alt=""
                layout="fill"
                objectFit="cover"
              />
            </div>
            <p className="card-text ml-4" style={{ fontSize: 14 }}>
              {data?.shop_name}
            </p>
          </div>

          <Button
            label="Kunjungi Toko"
            variant="link"
            size="md"
            className="text-muted"
            buttonProps={{
              onClick: () => { router.push(`${setRoute(routesName.SHOPS)}/${data?.shop_id}`); },
            }}
          />
        </div>
      </div>
      <div className="card-body">

        {products?.map((product: any, i: number) => {
          return (
            <div className={`card bg-light ${products.length === i + 1 ? "mb-0" : "mb-3"}`} key={i.toString()}>
              <div className="card-body py-2">
                <div className="d-flex align-items-center">
                  <div
                    className="position-relative rounded mr-3 overflow-hidden"
                    style={{
                      width: 100,
                      height: 80,
                    }}
                  >
                    <Image
                      src={product ? product.product_photo : "/"}
                      alt=""
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>

                  <div className="d-flex flex-column flex-1">
                    <h6>{product.product_name}</h6>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="d-flex align-items-center">
                        <p className="mb-0 mr-3">Jumlah:</p>
                        <p className="mb-0">
                          x
                          {product.qty}
                        </p>
                      </div>
                      <h6
                        className="mb-0"
                        style={{ fontSize: ".85rem" }}
                      >
                        {convertToIdr(product.total_price)}
                      </h6>
                    </div>

                    {product.notes !== "" && (
                      <div className="d-flex align-items-center">
                        <p className="mb-0 mr-3">Catatan:</p>
                        <p className="mb-0">
                          {`"${product.notes}"`}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      </div>

      <div className="card-footer">
        <div className="row">
          <div className="col-sm-5 offset-sm-7">

            <div className="row">
              <div className="col text-right">
                <span>Jumlah harga produk:</span>
              </div>
              <div className="col text-right">
                <h6 className="mb-0 font-weight-normal">{convertToIdr(data?.price)}</h6>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
