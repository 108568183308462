import Link from "next/link";
import cx from "classnames";
import {
  AnchorHTMLAttributes,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from "react";

type ReactText = string | number;
type ReactChild = ReactElement<any> | ReactText;
type ReactNode =
  | ReactChild
  | ReactFragment
  | ReactPortal
  | boolean
  | null
  | undefined;

interface DefaultAnchor extends AnchorHTMLAttributes<HTMLAnchorElement> {
  "data-toggle"?: string;
}

interface AnchorProps {
  children?: ReactNode;
  href: string;
  variant?:
    | "default"
    | "primary"
    | "secondary"
    | "info"
    | "danger"
    | "success"
    | "warning";
  icon?: string;
  className?: string;
  anchorProps?: DefaultAnchor;
}

export default function Anchor(props: AnchorProps) {
  const { children, href, variant, className, icon, anchorProps } = props;

  const anchorclass = cx(className, {
    "text-primary": variant === "primary",
    "text-secondary": variant === "secondary",
    "text-success": variant === "success",
    "text-danger": variant === "danger",
    "text-info": variant === "info",
    "text-warning": variant === "warning",
  });

  return (
    <Link href={href}>
      <a {...anchorProps} className={anchorclass}>
        {icon && <i className={`mr-2 ${icon}`} />}
        {children}
      </a>
    </Link>
  );
}

Anchor.defaultProps = {
  variant: "default",
  icon: undefined,
  className: undefined,
  anchorProps: undefined,
  children: undefined,
};
