import { NextRouter, useRouter } from "next/router";
import cx from "classnames";

interface SectionTermContentProps {
  query: any;
}

export default function SectionTermsContent(props: SectionTermContentProps) {
  const { query } = props;

  const { layout } = query;

  const bodyClass = cx("d-flex flex-column", {
    "py-4": layout !== "mobile",
  });

  return (
    <div className={bodyClass}>
      {layout !== "mobile" && (
        <>
          <h6 className="mb-1 profile-title">SYARAT DAN KETENTUAN PENGGUNAAN APLIKASI FOODIZMART</h6>

          <p className="text-muted mb-5">
            Berlaku mulai 1 Agustus 2021
          </p>
        </>
      )}

      <ol className="pl-3">
        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span className="text-dark">PENDAHULUAN</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Ketentuan Penggunaan ini adalah perjanjian antara pengguna (“Anda”)
            dan PT &quot;Nama Perusahaan&quot; (“Kami”), sebuah perseroan terbatas yang didirikan
            dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia
            dan berdomisili di DI Yogyakarta, Indonesia. Ketentuan Penggunaan ini
            mengatur akses dan penggunaan Anda atas aplikasi, situs web
            (www.foodizmart.com dan situs web lain yang Kami kelola), konten dan
            produk yang disediakan oleh Kami (selanjutnya, secara bersama-sama
            disebut sebagai “Aplikasi”), serta pemesanan, pembayaran atau
            penggunaan layanan yang tersedia pada Aplikasi (“Layanan”). Dengan
            menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan
            Penggunaan tambahan, termasuk Ketentuan Penggunaan pada setiap
            Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan
            dari Ketentuan Penggunaan ini (selanjutnya, Ketentuan Penggunaan,
            Ketentuan Penggunaan tambahan, dan perubahannya secara bersama-sama
            disebut sebagai “Ketentuan Penggunaan”). Meskipun merupakan satu
            kesatuan, Ketentuan Penggunaan tambahan akan berlaku dalam hal
            terdapat perbedaan dengan Ketentuan Penggunaan.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Istilah Umum</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kecuali didefinisikan secara khusus dibawah ini dan pada bagian lain
            dari Ketentuan Penggunaan Aplikasi ini, istilah-istilah dalam huruf
            besar yang digunakan dalam Ketentuan Penggunaan Foodizmart ini harus
            ditafsirkan sesuai dengan Ketentuan Penggunaan Foodizmart.
          </p>
          <ul className="text-muted pl-4 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>
              <span style={{ fontWeight: 600 }}>Gerai</span>
              {" "}
              adalah restoran, rumah makan, warung toko, gerobak
              dan/atau bentuk fisik lainnya yang terdaftar dalam Aplikasi
              Foodizmart yang dimiliki dan dikelola oleh Merchant.
            </li>
            <li>
              <span style={{ fontWeight: 600 }}>Merchant</span>
              {" "}
              adalah pihak yang memiliki dan mengelola Gerai
              dimana bertindak sebagai penyedia Produk.
            </li>
            <li>
              <span style={{ fontWeight: 600 }}>Mitra</span>
              {" "}
              adalah Penyedia Layanan pihak ketiga independen yang
              setuju menjadi mitra Kami, bekerja sama dengan Kami dengan skema
              kemitraan, dan bukan karyawan, agen atau perwakilan Kami.
            </li>
            <li>
              <span style={{ fontWeight: 600 }}>Produk</span>
              {" "}
              adalah makanan dan/atau minuman yang Anda pesan
              melalui Layanan Foodizmart di Gerai yang Anda pilih.
            </li>
            <li>
              <span style={{ fontWeight: 600 }}>Transaksi</span>
              {" "}
              adalah pemesanan, pembelian dan pengantaran atas
              Produk melalui Aplikasi.
            </li>
          </ul>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Penggunaan Aplikasi dan Layanan</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Akses dan penggunaan Aplikasi tunduk pada Ketentuan Penggunaan ini.
            Anda mempunyai kebebasan penuh untuk memilih menggunakan Aplikasi atau
            aplikasi lain, menggunakan Layanan yang tersedia pada Aplikasi atau
            tidak, atau berhenti menggunakan Aplikasi
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami hanya memfasilitasi Anda untuk menemukan berbagai Layanan yang
            Anda perlukan dengan menyediakan Aplikasi. Semua Layanan disediakan
            secara langsung oleh pihak ketiga independen yang setuju menjadi
            penyedia layanan Kami dengan skema kemitraan atau skema lainnya
            (“Penyedia Layanan”).
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketika memesan Layanan, Aplikasi akan menghubungkan Anda dengan
            Penyedia Layanan yang tersedia di sekitar lokasi Anda. Dengan
            demikian, beberapa Layanan tidak dapat digunakan bila Anda tidak
            mengaktifkan fitur penentuan lokasi.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Selanjutnya, jika Penyedia Layanan menerima pesanan Anda, Kami akan
            menginformasikan status pesanan Anda melalui Aplikasi.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pembukaan dan Pengaksesan Akun</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Sebelum menggunakan Aplikasi, Anda harus menyetujui Ketentuan
            Penggunaan ini dan Kebijakan Privasi, dan mendaftarkan diri Anda
            dengan memberikan informasi yang dibutuhkan oleh Kami. Saat melakukan
            pendaftaran, Kami akan meminta Anda untuk memberikan nama lengkap,
            alamat email, dan nomor telepon seluler pribadi Anda yang sah. Anda
            dapat mengubah informasi data diri Anda pada fitur pengaturan dalam
            Aplikasi. Setelah melakukan pendaftaran, sistem Kami akan menghasilkan
            kode verifikasi secara otomatis dan mengirim kode verifikasi tersebut
            melalui pesan singkat ke nomor telepon genggam yang Anda berikan. Anda
            perlu melakukan verifikasi dengan memasukan kode verifikasi tersebut
            pada halaman pendaftaran di Aplikasi.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Setelah melakukan verifikasi, sistem Kami akan membuatkan akun
            Foodizmart pribadi (“Akun”) untuk Anda yang dapat digunakan untuk
            menggunakan Aplikasi dan memesan Layanan melalui Aplikasi. Nomor
            telepon genggam Anda melekat pada Akun Anda sehingga Anda tidak bisa
            membuat Akun baru dengan nomor telepon genggam yang sudah didaftarkan.
            Hal yang sama juga berlaku apabila di kemudian hari Anda mengubah
            nomor telepon genggam Anda pada menu pengaturan di Aplikasi.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            KODE VERIFIKASI (ONE TIME PASSWORD/OTP) DIHASILKAN SECARA OTOMATIS
            OLEH SISTEM KAMI. KAMI TIDAK MENGETAHUI DAN TIDAK PERNAH MEMINTA KODE
            VERIFIKASI ANDA. JANGAN PERNAH MEMBERITAHUKAN KODE VERIFIKASI ANDA
            KEPADA SIAPAPUN BAHKAN KEPADA KAMI ATAU PIHAK LAIN YANG MENGAKU
            SEBAGAI PERWAKILAN KAMI.
          </p>
          <p className="text-muted mb-0 mt-4" style={{ fontWeight: 600 }}>Fitur Otentikasi Perangkat</p>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Jika Anda menggunakan perangkat yang mendukung fitur sidik jari
            (fingerprint) dan / atau pengenalan wajah (facial recognition), Anda
            dapat mengaktifkan fitur otentikasi perangkat tersebut untuk masuk ke
            Aplikasi kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketika Anda mengaktifkan fitur sidik jari dan / atau pengenalan wajah
            di perangkat seluler Anda untuk keperluan otentikasi di Aplikasi,
            harap dicatat bahwa Kami tidak menyimpan data biometrik tersebut.
            Kecuali jika diberitahukan sebaliknya kepada Anda, data tersebut
            disimpan di perangkat seluler Anda dan juga dapat disimpan oleh pihak
            ketiga, seperti pabrik pembuat perangkat Anda. Anda setuju dan
            mengakui bahwa kami tidak bertanggung jawab atas akses atau kehilangan
            yang tidak sah terhadap data biometrik yang disimpan di perangkat Anda
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Akun Anda</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Akun Anda hanya dapat digunakan oleh Anda dan tidak bisa dialihkan
            kepada orang lain dengan alasan apapun. Kami berhak menolak untuk
            memfasilitasi pesanan jika Kami mengetahui atau mempunyai alasan yang
            cukup untuk menduga bahwa Anda telah mengalihkan atau membiarkan Akun
            Anda digunakan oleh orang lain. Keamanan dan kerahasiaan Akun Anda,
            termasuk nama terdaftar, alamat surat elektronik terdaftar, nomor
            telepon genggam terdaftar, rincian pembayaran dan Metode Pembayaran
            yang Anda pilih, serta kode verifikasi yang dihasilkan dan dikirim
            oleh sistem Kami atau Penyedia Metode Pembayaran sepenuhnya merupakan
            tanggung jawab pribadi Anda. Semua kerugian dan risiko yang ada akibat
            kelalaian Anda menjaga keamanan dan kerahasiaan sebagaimana disebutkan
            ditanggung oleh Anda sendiri. Dalam hal demikian, Kami akan menganggap
            setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda
            sebagai permintaan yang sah dari Anda.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Segera beritahukan Kami jika Anda mengetahui atau menduga bahwa Akun
            Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami
            akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan
            terhadap penggunaan tanpa persetujuan tersebut.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Jika Anda seorang Penyedia Layanan, Anda tidak dapat menggunakan Akun
            pribadi Anda atau Akun orang lain untuk memesan Layanan yang akan Anda
            terima sendiri sebagai seorang Penyedia Layanan.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Informasi Pribadi</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian
            informasi pribadi Anda, seperti nama, alamat surat elektronik, dan
            nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun
            tunduk pada Kebijakan Privasi, yang merupakan bagian yang tidak
            terpisahkan dari Ketentuan Penggunaan ini.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Konten, Informasi dan Promosi</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami atau pihak lain yang bekerja sama dengan Kami dapat menyediakan
            Konten Pihak Ketiga yang dapat Anda temukan pada Aplikasi. Dalam hal
            Konten Pihak Ketiga disediakan oleh pihak lain yang bekerja sama
            dengan Kami (“Penyedia Konten Pihak Ketiga”), Kami tidak bertanggung
            jawab atas bagian apapun dari isi Konten Pihak Ketiga. Akses atau
            penggunaan Anda terhadap Konten Pihak Ketiga tersebut merupakan bentuk
            persetujuan Anda untuk tunduk pada syarat dan ketentuan yang
            ditetapkan oleh Kami atau Penyedia Konten Pihak Ketiga, termasuk
            terhadap Kebijakan Privasi Kami atau Penyedia Konten Pihak Ketiga.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Konten Pihak Ketiga adalah setiap dan/atau seluruh informasi dan
            penawaran barang dan/atau jasa, yang dibuat dan/atau disusun dan/atau
            dikembangkan dan/atau dikelola oleh Penyedia Konten Pihak Ketiga
            termasuk namun tidak terbatas pada teks atau tulisan, gambar, quotes
            atau kutipan, foto, ilustrasi, animasi, video, rekaman suara atau
            musik, judul, deskripsi dan/atau setiap data dalam bentuk apapun yang
            disediakan oleh Penyedia Konten Pihak Ketiga untuk ditampilkan pada
            Aplikasi Gojek, termasuk setiap tautan yang menghubungkan kepadanya.
            Untuk menghindari keraguan, Konten Pihak Ketiga mencakup pula setiap
            dan/atau seluruh penawaran barang dan/atau jasa, informasi, data,
            berita aktual, tulisan, gambar, kutipan, foto, ilustrasi, animasi,
            video, rekaman suara, yang diperoleh Penyedia Konten Pihak Ketiga dari
            pihak ketiga, dimana Penyedia Konten Pihak Ketiga telah memiliki
            kewenangan untuk menggunakan dan mendistribusikan konten tersebut.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami atau pihak lain yang bekerja sama dengan Kami dapat memberikan
            penawaran atau promosi (“Penawaran”) yang dapat ditukar dengan barang,
            Layanan atau manfaat lain terkait dengan penggunaan Aplikasi. Dalam
            hal Penawaran disediakan oleh pihak lain yang bekerja sama dengan Kami
            (“Penyedia Penawaran”), Kami tidak bertanggung jawab atas bagian
            apapun dari isi Penawaran tersebut. Akses atau penggunaan Anda
            terhadap Penawaran merupakan bentuk persetujuan Anda untuk tunduk pada
            syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia
            Penawaran, termasuk terhadap Kebijakan Privasi Kami atau Penyedia
            Penawaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Semua informasi, Penawaran yang terdapat pada Aplikasi hanya
            dimaksudkan untuk memberikan Anda pengalaman terbaik ketika
            menggunakan Aplikasi atau Layanan. Anda tidak boleh menyalahgunakan
            Penawaran yang Anda terima selama penggunaan Aplikasi atau Layanan.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda setuju untuk menggunakan Penawaran tersebut sesuai dengan syarat,
            ketentuan, dan maksud dari pemberian Penawaran dan tidak akan
            menyalahgunakan, menggandakan, menguangkan, mengalihkan, menggunakan
            untuk kepentingan komersial atau mengambil keuntungan dengan tidak
            wajar dari promosi tersebut dengan bentuk atau cara apapun.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda memahami bahwa Penawaran tidak dapat ditukar dengan uang tunai,
            memiliki masa keberlakuan yang terbatas dan tunduk pada ketentuan yang
            berlaku untuk setiap Penawaran tersebut.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pemesanan</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda dapat melakukan pemesanan atas Produk pada laman Merchant. Mitra
            akan menindaklanjuti pesanan Anda, termasuk namun tidak terbatas pada,
            pencarian lokasi Gerai, pemesanan langsung di Gerai, pembayaran serta
            pengantaran Produk.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Untuk meminimalisir kesalahan dalam pelaksanaan Transaksi oleh Mitra,
            Anda diharapkan untuk memberikan informasi selengkap-lengkapnya
            terkait dengan Produk yang dipesan dan pengantaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami dan/atau Mitra tidak menjamin ketersediaan atas Produk di Gerai.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda dapat menghubungi dan/atau dapat dihubungi oleh Mitra untuk
            mendapatkan konfirmasi atau klarifikasi mengenai Transaksi, termasuk
            namun tidak terbatas pada ketersediaan dan/atau perubahan Produk,
            lokasi Gerai, perubahan harga Produk, alamat pengantaran, penerima
            Produk atau informasi lainnya, melalui fitur komunikasi dalam Aplikasi
            Gojek dan/atau jalur komunikasi lainnya sebelum atau pada saat
            melakukan pemesanan, pembelian dan/atau pengantaran Produk.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda hanya dapat melakukan pembatalan atas Transaksi sebelum Mitra
            melakukan pembelian Produk.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Dalam hal Anda melakukan pemesanan atas minuman beralkohol, Anda
            menjamin bahwa Anda telah berusia 21 tahun dan bersedia untuk
            menunjukkan kartu identitas (KTP) Anda kepada Mitra pada saat
            pengantaran pesanan di tempat tujuan pengantaran yang Anda tentukan.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Biaya dan Pembayaran</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Pengunduhan perangkat lunak dan penggunaan Aplikasi adalah bebas
            biaya. Namun, Kami dapat mengenakan biaya untuk penggunaan fitur
            tertentu pada Aplikasi di kemudian hari. Layanan yang tersedia pada
            Aplikasi dikenakan biaya yang dapat Anda temukan pada Aplikasi sebelum
            Anda memesan Layanan tersebut. Kami dapat mengubah atau memperbaharui
            biaya dari waktu ke waktu berdasarkan faktor tertentu, antara lain
            lokasi, waktu, jenis Layanan dan peraturan perundang-undangan yang
            berlaku. Kami juga dapat membebankan harga dan/atau biaya dengan
            besaran tertentu sebagai pembayaran untuk penggunaan Aplikasi yang
            dapat dipungut oleh Kami, afiliasi Kami, atau Penyedia Layanan.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda dapat melakukan pembayaran terhadap Layanan, Konten Pihak Ketiga
            atau Penawaran yang Anda akses atau gunakan dengan uang tunai atau
            melalui metode pembayaran elektronik, yang disediakan oleh pihak
            ketiga independen (“Penyedia Metode Pembayaran”), pada Aplikasi,
            termasuk uang elektronik, fasilitas pinjaman, tagihan perusahaan
            (dengan menggunakan Akun pengguna yang sah yang terhubung dengan akun
            pemberi pekerjaan Anda) kartu debit atau kredit, rekening bank, atau
            metode pembayaran lain (“Metode Pembayaran”) yang dapat berubah
            sewaktu-waktu berdasarkan kebijakan kami sepenuhnya.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Untuk dapat melakukan pembayaran melalui Metode Pembayaran, Anda harus
            terdaftar secara resmi pada Penyedia Metode Pembayaran yang Anda pilih
            dan menggunakan kredensial pembayaran Anda sendiri. Dalam hal Anda
            menggunakan kredensial terdaftar pihak lain, Anda bertanggung jawab
            secara penuh atas semua perizinan yang diperlukan dan semua kerugian
            atau sengketa yang timbul antara Anda dan pihak lain tersebut, baik
            karena kelalaian atau kesalahan Anda, Kami, Penyedia Layanan, Penyedia
            Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode
            Pembayaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami berhak menolak atau menunda untuk meneruskan permintaan
            pembayaran Anda melalui Metode Pembayaran karena alasan tertentu,
            termasuk namun tidak terbatas pada adanya indikasi atau Kami mempunyai
            alasan yang cukup untuk menduga adanya kecurangan, penipuan,
            pelanggaran Ketentuan Penggunaan, pelanggaran atas peraturan
            perundang-undangan yang berlaku termasuk yang terkait dengan alat
            pembayaran menggunakan kartu, uang elektronik, pemrosesan transaksi
            pembayaran, anti pencucian uang, korupsi dan pencegahan pendanaan
            terorisme, atau tindakan lain yang tidak wajar atau mencurigakan,
            termasuk belum dipenuhinya kewajiban Anda kepada Kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketentuan lebih lanjut tentang biaya yang berlaku terhadap Layanan
            tertentu, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayaran
            dapat ditemukan dalam Ketentuan Penggunaan tambahan dari Layanan yang
            Anda pesan dan syarat dan ketentuan dari Penyedia Layanan, Penyedia
            Konten Pihak Ketiga, Penyedia Penawaran dan/atau Penyedia Metode
            Pembayaran.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pengantaran</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda atau pihak lainnya yang Anda tunjuk untuk menerima Produk
            memahami untuk membuat dirinya tersedia, dapat dihubungi dan dapat
            menerima pada saat Produk diantarkan oleh Mitra. Anda dapat memberikan
            ulasan atas Produk sebagaimana terdapat dalam Layanan Foodizmart
            setelah Transaksi Anda selesai.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami tidak bertanggung-jawab atas Produk, termasuk namun tidak
            terbatas pada hal-hal berikut:
          </p>
          <ul className="text-muted pl-4 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>Perbedaan harga yang tercantum di Layanan Foodizmart dan Gerai;</li>
            <li>Kondisi dan kualitas;</li>
            <li>Durasi pengantaran oleh Mitra;</li>
            <li>Kegagalan menyelesaikan pesanan dan/atau pengantaran oleh Mitra; dan;</li>
            <li>Ketidaksesuaian Produk yang dipesan dengan Produk yang diantarkan.</li>
          </ul>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Perangkat Lunak Aplikasi</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami hanya menyediakan perangkat lunak Aplikasi yang resmi pada pasar
            digital resmi, seperti Google Play Store atau Apple App Store, dan
            untuk digunakan hanya pada perangkat telepon genggam atau tablet.
            Mengunduh Aplikasi dari tempat lain selain pasar digital resmi
            dan/atau ke dalam perangkat lain selain telepon genggam atau tablet
            merupakan pelanggaran terhadap Ketentuan Penggunaan ini dan terhadap
            hak kekayaan intelektual Kami.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Kekayaan Intelektual</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Aplikasi dan Layanan, termasuk namun tidak terbatas pada, nama, logo,
            kode program, desain, merek dagang, teknologi, basis data, proses dan
            model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan
            intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia
            yang terdaftar baik atas nama Kami ataupun afiliasi Kami. Kami (dan
            pemberi lisensi Kami) memiliki seluruh hak dan kepentingan atas
            Aplikasi dan Layanan, termasuk seluruh hak kekayaan intelektual
            terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan
            intelektual terkait.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi
            terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat
            dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan
            menggunakan Aplikasi, sebagaimana adanya, hanya pada perangkat
            berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk
            keperluan terkait penggunaan Layanan, dan (ii) mengakses atau
            menggunakan konten, informasi dan materi terkait yang tersedia pada
            Aplikasi hanya untuk kepentingan pribadi dan bukan tujuan komersial.
            Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam
            Ketentuan Penggunaan ini, adalah hak Kami atau pemberi lisensi Kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan
            atau penawaran untuk menjual, penampilan baik sebagian atau
            seluruhnya, serta penggunaan Aplikasi dan/atau Layanan, baik secara
            digital atau lainnya atau pada perangkat selain telepon genggam atau
            tablet, termasuk namun tidak terbatas pada penggunaan aplikasi
            tambahan seperti aplikasi modifikasi, emulator, dan lain-lain, yang
            menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan
            yang ditentukan oleh Kami, merupakan pelanggaran terhadap hak
            kekayaan intelektual Kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>Hal yang tidak boleh Anda lakukan antara lain :</p>
          <ul className="text-muted pl-4 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>
              menghapus setiap pemberitahuan hak cipta, merek dagang atau
              pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi;
            </li>
            <li>
              menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya
              turunan dari, mendistribusikan, memberikan lisensi, menjual,
              mengalihkan, menampilkan di muka umum baik sebagian maupun
              seluruhnya, merekayasa balik (reverse engineer), mentransmisikan,
              memindahkan, menyiarkan, menguraikan, atau membongkar bagian
              manapun dari atau dengan cara lain mengeksploitasi Aplikasi
              (termasuk perangkat lunak, fitur dan Layanan di dalamnya);
            </li>
            <li>
              memberikan lisensi, mensublisensikan, menjual, menjual kembali,
              memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi
              secara komersial atau membuat tersedia kepada pihak lain Aplikasi
              dan/atau perangkat lunak dengan cara menciptakan tautan (link)
              internet ke Aplikasi atau &quot;frame&quot; atau &quot;mirror&quot; setiap perangkat
              lunak pada server lain atau perangkat nirkabel atau yang berbasis
              internet;
            </li>
            <li>
              meluncurkan program otomatis atau script, termasuk, namun tidak
              terbatas pada, web spiders, web crawlers, web robots, web ants,
              web indexers, bots, virus atau worm, atau program apapun yang
              mungkin membuat beberapa permintaan server per detik, menciptakan
              beban berat atau menghambat operasi dan/atau kinerja Aplikasi;
            </li>
            <li>
              menggunakan aplikasi pencarian atau pengambilan kembali situs,
              perangkat manual atau otomatis lainnya untuk mengambil (scraping),
              indeks (indexing), survei (surveying), tambang data (data mining),
              atau dengan cara apapun memperbanyak atau menghindari struktur
              navigasi atau presentasi dari Aplikasi atau isinya;
            </li>
            <li>
              menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun
              materi yang dilindungi hak cipta, merek dagang, atau informasi
              lain yang Kami miliki tanpa persetujuan tertulis terlebih dahulu
              dari Kami atau pemilik hak yang melisensikan hak-nya kepada Kami,
              dan
            </li>
            <li>
              menggunakan dan/atau mengakses secara tidak resmi Aplikasi untuk
              (a) merusak, melemahkan atau membahayakan setiap aspek dari
              Aplikasi, Layanan atau sistem dan jaringan terkait, dan/atau (b)
              membuat produk atau layanan tandingan serupa menggunakan ide,
              fitur, fungsi atau grafik menyerupai Aplikasi.
            </li>
          </ul>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Tanggung Jawab Anda</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk
            menggunakan atau mengakses Aplikasi, Layanan, Konten Pihak Ketiga,
            Penawaran atau Metode Pembayaran. Anda harus memperlakukan Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia
            Metode Pembayaran dengan hormat dan tidak boleh terlibat dalam
            perilaku atau tindakan yang tidak sah, mengancam atau melecehkan
            ketika menggunakan Layanan, Konten Pihak Ketiga, Penawaran atau Metode
            Pembayaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau
            klaim yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak
            Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik oleh
            Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang
            bertentangan dengan Ketentuan Penggunaan ini, Kebijakan Privasi,
            termasuk syarat dan ketentuan dan kebijakan privasi yang ditentukan
            oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia
            Penawaran dan Penyedia Metode Pembayaran, atau peraturan
            perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk
            tujuan anti pencucian uang, anti pendanaan
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk
            namun tidak terbatas pada kegiatan phishing dan/atau social
            engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas
            lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat
            atau dianggap dapat merusak reputasi Kami.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Batasan Tanggung Jawab Kami</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Kami menyediakan Aplikasi sebagaimana adanya dan Kami tidak menyatakan
            atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian,
            ketersediaan, akurasi, kelengkapan atau keamanan dari Aplikasi dapat
            memenuhi kebutuhan dan akan sesuai dengan harapan Anda, termasuk namun
            tidak terbatas pada Layanan, Konten Pihak Ketiga, Penawaran dan Metode
            Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia Layanan,
            Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan Penyedia Metode
            Pembayaran. Kami tidak bertanggung jawab atas setiap kerugian atau
            kerusakan yang disebabkan oleh setiap kegagalan atau kesalahan yang
            dilakukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran atau Penyedia Metode Pembayaran ataupun kegagalan
            atau kesalahan Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau
            Penyedia Metode Pembayaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Aplikasi dapat mengalami keterbatasan, penundaan, dan masalah-masalah
            lain yang terdapat dalam penggunaan internet dan komunikasi
            elektronik, termasuk perangkat Anda, Penyedia Layanan, Penyedia Konten
            Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran
            rusak, tidak terhubung dengan internet, berada di luar jangkauan,
            dimatikan atau tidak berfungsi. Kami tidak bertanggung jawab atas
            keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang
            diakibatkan oleh masalah-masalah tersebut.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda
            atas Aplikasi. Namun, Kami tetap melakukan pengawasan untuk tujuan
            memastikan kelancaran penggunaan Aplikasi dan untuk memastikan
            kepatuhan terhadap Ketentuan Penggunaan ini, peraturan
            perundang-undangan yang berlaku, putusan pengadilan, dan/atau
            ketentuan lembaga administratif atau badan pemerintahan lainnya.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil
            tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh Anda,
            Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran
            atau Penyedia Metode Pembayaran, terhadap setiap permasalahan atau
            perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia
            Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode
            Pembayaran. Tetapi, Kami akan memfasilitasi setiap permasalahan atau
            perselisihan yang timbul antara Anda dan Penyedia Layanan, Penyedia
            Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode
            Pembayaran dengan upaya wajar yang diperlukan. Ketika Kami
            memfasilitasi penyelesaian permasalahan atau perselisihan antara Anda
            dan Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran
            atau Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator
            dan hal tersebut tidak menimbulkan kewajiban lebih jauh apapun
            terhadap Kami.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Penyelesaian Masalah</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Apabila Anda mengalami gangguan sistem, mengetahui atau menduga bahwa
            Akun Anda diretas, digunakan atau disalahgunakan oleh pihak lain, atau
            apabila perangkat telepon genggam atau tablet pribadi Anda hilang,
            dicuri, diretas atau terkena virus, segera laporkan kepada Kami
            sehingga Kami dapat segera mengambil tindakan yang diperlukan untuk
            menghindari penggunaan, penyalahgunaan, atau kerugian yang timbul atau
            mungkin timbul lebih lanjut. Apabila Anda mengalami kendala atau
            masalah terkait Layanan, Konten Pihak Ketiga, Penawaran atau
            pembayaran melalui Metode Pembayaran, atau perlakuan Penyedia Layanan,
            Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode
            Pembayaran Anda dapat menyampaikan keluhan Anda melalui fitur yang
            Kami sediakan, termasuk pemberian peringkat dan komentar, atau dengan
            menghubungi Kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain
            (“Laporan”), Anda perlu memberikan informasi yang cukup, termasuk
            namun tidak terbatas pada, ringkasan fakta yang terjadi, bukti-bukti
            yang Anda miliki, nomor pesanan, dan informasi pribadi, seperti alamat
            surat elektronik dan nomor telepon genggam terdaftar.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan
            melakukan verifikasi terlebih dahulu dengan mencocokan informasi yang
            Anda berikan dan informasi pribadi Anda yang terdapat dalam sistem
            Kami. Jika diperlukan, Kami dapat secara langsung meminta Anda
            memberikan informasi yang diperlukan untuk tujuan verifikasi.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi
            yang Anda berikan tidak cocok dengan informasi pribadi yang terdapat
            dalam sistem Kami atau apabila Laporan disampaikan terkait, terhadap,
            atas nama atau oleh pihak lain yang berbeda dengan pemilik Akun yang
            bersangkutan yang terdaftar secara resmi pada sistem Kami. Kami dapat
            memberhentikan tindak lanjut terhadap Laporan Anda jika Kami, dengan
            kebijakan Kami sepenuhnya, menganggap bahwa Laporan Anda tidak
            didukung oleh fakta-fakta yang cukup dan jelas, atau telah selesai.
            Kami juga dapat meneruskan Laporan Anda kepada Penyedia Layanan,
            Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia Metode
            Pembayaran untuk diselesaikan secara langsung oleh Anda dan Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau
            Penyedia Metode Pembayaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan
            kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan
            Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan
            kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu
            mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat
            melakukannya atas tanggung jawab pribadi Anda sepenuhnya.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat
            mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau
            bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan kebijakan
            Kami sepenuhnya. Kami tidak akan mengembalikan dana kepada pihak lain
            yang berbeda dengan kredensial terdaftar pada Akun Gojek atau Penyedia
            Metode Pembayaran, termasuk apabila Anda menggunakan kredensial pihak
            lain untuk melakukan pembayaran melalui Metode Pembayaran.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pembekuan Akun Sementara dan Pembekuan Akun Permanen</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda dapat menghapus Aplikasi dari telepon genggam dan/atau tablet
            Anda setiap saat. Kami tidak memiliki kewajiban apapun kepada Anda
            terhadap hal-hal yang timbul sejak penghapusan Aplikasi, pembekuan
            sementara atau pembekuan permanen Akun Anda. Akan tetapi, Anda tetap
            bertanggung jawab untuk menyelesaikan kewajiban-kewajiban Anda yang
            telah timbul, termasuk namun tidak terbatas pada, setiap kewajiban
            yang mungkin timbul akibat adanya sengketa, tuntutan, maupun tindakan
            hukum lainnya yang telah ada, sebelum tanggal penghapusan Aplikasi,
            pembekuan sementara atau pembekuan permanen Akun Anda.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Akun Anda dapat dibekukan untuk sementara waktu atau dapat dibekukan
            secara permanen karena hal-hal, termasuk namun tidak terbatas pada,
            sebagai berikut:
          </p>
          <ul className="text-muted pl-4 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>
              Laporan Anda bahwa Akun Anda digunakan atau diduga digunakan atau
              disalahgunakan oleh orang lain;
            </li>
            <li>
              Laporan Anda bahwa telepon genggam atau tablet pribadi Anda
              hilang, dicuri atau diretas;
            </li>
            <li>
              Kami mengetahui atau mempunyai alasan yang cukup untuk menduga
              bahwa Akun Anda telah dialihkan atau digunakan oleh orang lain;
            </li>
            <li>
              Kami mengetahui atau dengan alasan yang cukup menduga bahwa telah
              terjadi hal-hal yang menurut pandangan Kami telah atau dapat
              merugikan Kami, Anda, Penyedia Layanan atau pihak lainnya;
            </li>
            <li>
              Kami mengetahui atau dengan alasan yang cukup menduga bahwa anda
              telah mendaftar atau masuk dalam banyak Akun dalam satu perangkat
              untuk tujuan melanggaran Ketentuan Penggunaan, Kebijakan Privasi
              atau peraturan dan hukum yang berlaku.
            </li>
            <li>
              Sistem Kami mendeteksi adanya tindakan yang tidak wajar dari
              penggunaan Akun Anda atau adanya kewajiban berdasarkan Ketentuan
              Penggunaan, dan/atau Kebijakan Privasi yang tidak dipenuhi oleh
              Anda;
            </li>
            <li>
              Anda telah meninggal dunia, ditempatkan di bawah perwalian atau
              pengampuan atau mengalami ketidakmampuan lainnya yang menjadikan
              Anda tidak cakap hukum berdasarkan peraturan perundang-undangan
              yang berlaku;
            </li>
            <li>
              Penggunaan Aplikasi atau Layanan oleh Anda atau pihak lain (yang
              menggunakan Akun Anda) dengan cara yang bertentangan dengan
              Ketentuan Penggunaan ini, Kebijakan Privasi atau peraturan
              perundang-undangan yang berlaku; dan/atau
            </li>
            <li>
              Perintah untuk pembekuan akun, baik sementara atau permanen, yang
              diterbitkan oleh institusi pemerintah atau moneter terkait atau
              berdasarkan perintah pengadilan yang diterbitkan sesuai dengan
              peraturan perundang-undangan yang berlaku.
            </li>
          </ul>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Jika Akun Anda dibekukan dan Anda memiliki bukti yang jelas bahwa
            Akun Anda seharusnya tidak dibekukan, Anda dapat membuat Laporan
            kepada Kami untuk menyampaikan bukti-bukti tersebut. Setelah
            melakukan pemeriksaan lebih lanjut terhadap Laporan Anda, Kami akan,
            atas kebijakan Kami sepenuhnya, menentukan untuk mengakhiri atau
            melanjutkan pembekuan terhadap Akun Anda. Pembekuan tidak akan
            diteruskan secara tidak wajar apabila Kami memutuskan bahwa hal-hal
            yang mengakibatkan terjadinya pembekuan telah diselesaikan.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda tidak akan menggunakan Aplikasi pada perangkat atau sisterm
            operasi yang telah dimodifikasi diluar perangkat atau konfigurasi
            sistem operasi dan konfigurasi Aplikasi. Hal ini mencakup perangkat
            yang telah melalui proses “rooted” atau “jail-broken”. Perangkat
            rooted atau jail-broken adalah perangkat yang telah dibebaskan dari
            pembatasan yang dikenakan oleh penyedia layanan perangkat dan yang
            dimanufaktur tanpa persetujuan penyedia layanan perangkat.
            Penggunaan Aplikasi pada perangkat rooted atau jail-broken dapat
            mengkompromisasi keamanan dan berujung pada transaksi penipuan
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami tidak bertanggung jawab atas pengunduhan dan penggunaan
            Aplikasi pada perangkat rooted atau jail-broken dan resiko
            penggunaan anda terhadap perangkat rooted atau jail-broken
            sepenuhnya adalah resiko anda. Anda mengerti dan setuju bahwa Kami
            tidak bertanggung jawab atas segala kehilangan atau setiap
            konsekuensi lain yang diderita atau disebabkan oleh anda sebagai
            akibat dari penggunaan aplikasi Kami pada perangkat rooted atau
            jail-broken dan Kami mempunyai diskresi untuk menghentikan
            Penggunaan Anda terhadap Aplikasi pada perangkat rooted atau
            jail-broken dan memblokirperangkat rooted atau jail-broken untuk
            menggunakan Aplikasi.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Tindakan yan Kami Anggap Perlu</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga
            bahwa Anda telah melakukan tindakan asusila, pelanggaran, kejahatan
            atau tindakan lain yang bertentangan dengan Ketentuan Penggunaan ini
            dan/atau peraturan perundang-undangan yang berlaku, baik yang dirujuk
            dalam Ketentuan Penggunaan ini atau tidak, maka Kami berhak untuk dan
            dapat membekukan Akun, baik sementara atau permanen, atau menghentikan
            akses Anda terhadap Aplikasi, termasuk Layanan, Konten Pihak Ketiga,
            Penawaran dan/atau Metode Pembayaran yang terdapat di dalamnya,
            melakukan pemeriksaan, menuntut ganti kerugian, melaporkan kepada
            pihak berwenang dan/atau mengambil tindakan lain yang kami anggap
            perlu, termasuk tindakan hukum pidana maupun perdata.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami akan menindaklanjuti dengan melakukan investigasi dan/atau
            memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan
            kepada pihak yang berwajib apabila Kami menerima Laporan adanya
            pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini ataupun
            pelanggaran terhadap peraturan perundang-undangan yang berlaku,
            sehubungan dengan:
          </p>
          <ul className="text-muted pl-4 mb-0 mt-2" style={{ fontWeight: 400 }}>
            <li>
              Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas
              pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda
              lakukan terhadap Penyedia Layanan;
            </li>
            <li>Tindakan penipuan</li>
            <li>Penggunaan Aplikasi pada perangkat rooted atau jail-broken;</li>
            <li>
              Pelecehan atau kekerasan fisik yang Anda lakukan terhadap Penyedia
              Layanan; dan/atau
            </li>
            <li>
              Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik,
              yang Anda lakukan terhadap Penyedia Layanan.
            </li>
          </ul>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami
            tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia
            Layanan terhadap Anda.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Pernyataan Anda</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18
            (delapan belas) tahun atau sudah menikah dan tidak berada di bawah
            perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan
            berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. Jika Anda
            tidak memenuhi ketentuan tersebut namun tetap mengakses atau
            menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran atau
            Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan Anda
            membuka, mengakses atau melakukan aktivitas lain dalam Aplikasi telah
            disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas
            mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau
            mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan
            Ketentuan Penggunaan ini pada waktu Anda dianggap telah dewasa secara
            hukum.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda setuju untuk mengakses atau menggunakan Aplikasi, Layanan,
            Konten Pihak Ketiga, Penawaran atau Metode Pembayaran hanya untuk
            tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan
            tidak menyalahgunakan atau menggunakan Aplikasi, Layanan, Konten
            Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan
            penipuan, menyebabkan ketidaknyamanan kepada orang lain, melakukan
            pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau
            dianggap dapat menimbulkan kerugian dalam bentuk apapun terhadap
            orang lain.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari
            penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran dan
            Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda
            dengan ini setuju untuk melepaskan Kami dari segala tuntutan apapun
            sehubungan dengan kerusakan, gangguan, atau bentuk lain dari
            gangguan sistem yang disebabkan oleh akses tidak resmi oleh pihak
            lain.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas
            pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari
            setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau
            immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau
            tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat
            pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun sehubungan
            dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran atau Penyedia Metode Pembayaran.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung
            akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka Anda
            setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar
            jumlah keseluruhan yang senyatanya ditimbulkan atau telah dibayarkan
            sehubungan dengan peristiwa tersebut.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            ANDA MENGAKUI BAHWA KAMI ADALAH PERUSAHAAN TEKNOLOGI, BUKAN
            PERUSAHAAN TRANSPORTASI, KURIR, POS, JASA PENGANTARAN, LOGISTIK,
            KEUANGAN DAN LAIN-LAIN DAN KAMI TIDAK MEMBERIKAN LAYANAN
            TRANSPORTASI, KURIR, POS, JASA PENGANTARAN, LOGISTIK, KEUANGAN DAN
            LAYANAN LAIN. SEMUA LAYANAN TRANSPORTASI, KURIR, POS, JASA
            PENGANTARAN, LOGISTIK, KEUANGAN DAN LAYANAN LAIN DISEDIAKAN PIHAK
            KETIGA INDEPENDEN YANG TIDAK DIPEKERJAKAN OLEH KAMI.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            DENGAN MELANJUTKAN AKSES ATAU PENGGUNAAN TERHADAP APLIKASI GOJEK
            DAN/ATAU LAYANAN, ANDA SETUJU UNTUK TUNDUK DAN MEMATUHI SEMUA
            KETENTUAN PERATURAN PERUNDANG-UNDANGAN TERKAIT DAN KETENTUAN
            PENGGUNAAN INI, TERMASUK SEMUA PERUBAHANNYA DAN KETENTUAN PENGGUNAAN
            DARI SETIAP PENYEDIA LAYANAN, PENYEDIA KONTEN PIHAK KETIGA, PENYEDIA
            PENAWARAN ATAU PENYEDIA METODE PEMBAYARAN. SEGERA HENTIKAN AKSES
            ATAU PENGGUNAAN APLIKASI GOJEK DAN/ATAU LAYANAN JIKA ANDA TIDAK
            SETUJU DENGAN BAGIAN APAPUN DARI KETENTUAN PENGGUNAAN INI.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Keadaan Kahar</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Aplikasi dapat diinterupsi oleh kejadian di luar kewenangan atau
            kontrol Kami (“Keadaan Kahar”), termasuk namun tidak terbatas pada
            bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan
            pemerintah, dan lain-lain. Anda setuju untuk membebaskan Kami dari
            setiap tuntutan dan tanggung jawab, jika Kami tidak dapat
            memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan
            melalui Aplikasi, baik sebagian maupun seluruhnya, karena suatu
            Keadaan Kahar.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Lain-lain</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan
            perjanjian dalam bentuk elektronik dan tindakan Anda menekan tombol
            &quot;daftar&quot; saat pembukaan Akun atau tombol &quot;masuk&quot; saat akan mengakses
            Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri
            dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan Penggunaan
            ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan
            terus berlaku sepanjang penggunaan Aplikasi dan Layanan oleh Anda.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap
            keabsahan dari Ketentuan Penggunaan atau Kebijakan Privasi yang dibuat
            dalam bentuk elektronik.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan
            ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat
            mengalihkan hak Kami berdasarkan Ketentuan Penggunaan ini setiap saat
            kepada pihak lain tanpa perlu mendapatkan persetujuan terlebih dahulu
            dari atau memberikan pemberitahuan sebelumya kepada Anda.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan
            Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung,
            bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan
            yang diperlukan di kemudian hari.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara,
            pembekuan permanen, penghapusan Aplikasi atau setelah berakhirnya
            perjanjian ini antara Anda dan Kami.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak
            dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan
            lainnya.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Kami membuat Ketentuan Penggunaan ini dalam bahasa Indonesia dan
            Inggris. Setiap perbedaan akan diartikan sesuai dengan bahasa
            Indonesia.
          </p>
          <p className="text-muted mb-0 mt-2" style={{ fontWeight: 400 }}>
            Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia.
            Setiap dan seluruh perselisihan yang timbul dari penggunaan Aplikasi
            atau Layanan tunduk pada yurisdiksi eksklusif Pengadilan Negeri
            Jakarta Selatan.
          </p>
        </li>

        <li className="mb-3 text-dark" style={{ fontWeight: 600, lineHeight: 1.5 }}>
          <span>Cara Menghubungi Kami</span>
          <p className="text-muted mb-0 mt-1" style={{ fontWeight: 400 }}>
            Anda dapat menghubungi Kami melalui surat elektronik ke
            customerservice@foodizmart.id atau melalui telepon ke nomor (0274)
            882964. Semua korespondensi Anda akan dicatat, direkam dan disimpan
            untuk arsip Kami.
          </p>
        </li>
      </ol>
    </div>
  );
}
