import { useState, useEffect } from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
// Utils
import { limitContentText } from "../../../utils";
// Configs
import { messagesDataShowLimit, uri } from "../../../configs";
import { UserProfile, ConversationsData } from "../../../configs/datatype";
// Data
import { AppDispatch, actionGetMessages, RootState } from "../../../reduxs";

interface ConversationsProps {
  user: Partial<UserProfile>;
  doSetRecipient: (recipient: ConversationsData) => void;
}

interface ConversationItemProps {
  conversation: ConversationsData;
  doOpenConversation: (conversation: ConversationsData) => void;
}

function RenderConversationItem(props: ConversationItemProps) {
  const { conversation, doOpenConversation } = props;

  const [source, setSource] = useState<string>("/");

  const { id, attributes } = conversation;

  useEffect(() => {
    if (conversation) setSource(attributes?.participants?.avatar as string);
  }, [conversation]);

  return (
    <button
      className="list-conversation-item"
      onClick={() => { doOpenConversation(conversation); }}
    >
      <div
        className="rounded-circle position-relative overflow-hidden"
        style={{ height: 28, width: 28 }}
      >
        <Image
          src={source}
          onError={() => { setSource("/images/avatar-default.jpg"); }}
          alt="avatar"
          layout="fill"
          objectFit="cover"
        />
      </div>

      <div className="d-flex flex-column align-items-start ml-2 text-left">
        <p className="mb-0" style={{ fontSize: 12 }}>
          {limitContentText(attributes?.participants?.name || "", 12)}
        </p>
        <span className="text-muted" style={{ fontSize: 10 }}>
          {limitContentText(attributes?.last_message || "", 10)}
        </span>
      </div>
    </button>
  );
}

export default function Conversations(props: ConversationsProps) {
  const { user, doSetRecipient } = props;

  // Reduxs
  const dispatch: AppDispatch = useDispatch();
  const { conversations } = useSelector((state: RootState) => { return state.rdcchats; });

  const handleOpenConversations = async (recipient: ConversationsData) => {
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch(actionGetMessages({ limit: messagesDataShowLimit, conversation: recipient.id, user_id: user.id! }, true));

    doSetRecipient(recipient);
  };

  return (
    <div className="card rounded-0 border-0 h-100">
      <div className="card-header d-flex align-items-center py-0" style={{ height: 64 }}>
        <span style={{ fontWeight: 600 }}>Chat</span>
      </div>
      <div className="list-conversation">

        {conversations.map((conversation: ConversationsData, i: number) => {
          return (
            <RenderConversationItem
              key={i.toString()}
              conversation={conversation}
              doOpenConversation={handleOpenConversations}
            />
          );
        })}

      </div>
    </div>
  );
}
