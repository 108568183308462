import { useState, useEffect, useCallback } from "react";
import FormData from "form-data";
import { toast } from "react-toastify";
// Components
import { Button } from "../../atoms";
import { CardProduct } from "../../molecules";
// Utils
import { convertToDistance } from "../../../utils";
// Configs
import { uri } from "../../../configs";
import { Products, Locations } from "../../../configs/datatype";
// Data
import { serviceGetListProducts } from "../../../services";

interface SectionProductSerupaProps {
  product: Partial<Products>;
}

export default function SectionProductSerupa(props: SectionProductSerupaProps) {
  const { product } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Array<Products>>([]);
  const [more, setMore] = useState(false);

  const getSimilarProduct = useCallback(async () => {
    setLoading(true);

    try {
      const params = product.name?.split(" ")[0];

      const fd = new FormData();
      fd.append("search_type", "product");
      fd.append("search_key", params);

      const response = await serviceGetListProducts(fd);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setLoading(false);

        return;
      }

      const { product_result } = response.data;

      const currentLocation = sessionStorage.getItem("location");
      const location: Partial<Locations> = currentLocation ? JSON.parse(currentLocation) : {};

      const formatedData = product_result.map((val: Partial<Products>) => {
        const temp = { ...val };

        const nearbyInMeters = location?.coordinate ? convertToDistance(location?.coordinate, { latitude: temp.shop_latitude!, longitude: temp.shop_longitude! }) : 0;
        const nearbyInKilometers = nearbyInMeters / 1000;

        // Type of size on image uri: original, medium, small
        temp.photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${val.photo}`;
        temp.information = JSON.parse(temp.information);
        temp.distance = nearbyInKilometers.toFixed(1);

        return temp;
      });

      setProducts(formatedData);
      setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setLoading(false);

        return;
      }

      toast.error("Something went wrong while getting similar product");
      setLoading(false);
    }
  }, [product]);

  useEffect(() => {
    getSimilarProduct();
  }, [product]);

  const handleShowMore = () => {
    setMore(!more);
  };

  let dataLength = products.length;

  if (products.length > 8 && more) {
    dataLength = products.length;
  }

  if (products.length > 8 && !more) {
    dataLength = 8;
  }

  if (loading) return null;

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between pt-5 pb-3">
        <h5 className="m-0">Produk Serupa</h5>
        <div className="d-flex align-items-center">
          <small className="text-muted">
            Menampilkan
            {" "}
            {dataLength}
            {" "}
            {products.length > 8 && `dari ${products.length}`}
            {" "}
            produk
          </small>

          {products.length > 8 && (
            <Button
              label={!more ? "Tampilkan Lebih" : "Tampilkan Sedikit"}
              variant="danger"
              size="sm"
              outline
              className="ml-4"
              buttonProps={{
                onClick: handleShowMore,
              }}
            />
          )}
        </div>
      </div>

      <div className="row">
        {products.slice(0, dataLength).map((val: Partial<Products>, i: number) => {
          return (
            <div key={val.id} className="col-6 col-md-3 mb-3">
              <CardProduct product={val} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
