import { useState, useEffect, useCallback, FormEvent } from "react";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
// Data
import { serviceGetLocationByGoogle, serviceGetLocationByMapbox } from "../../../services";
// Configs
import { Locations, LocationType } from "../../../configs/datatype";
// Utils
import { useGeoLocation, useLocation, limitContentText } from "../../../utils";

interface SetLocationProps {
  doSetLocation: (val: Locations) => void;
}

const SetLocation = (props: SetLocationProps) => {
  const { doSetLocation } = props;
  const [address, setAddress] = useState<any>({});
  const [city, setCity] = useState<string>("");

  const router = useRouter();
  const location = useGeoLocation();

  const getLocation = useCallback(async (type: LocationType, cityname?: string | undefined, locate?: any) => {
    try {
      const userlocation = cityname || `${locate?.coordinates?.lat},${locate?.coordinates?.lng}`;
      // const userlocation = cityname ? { search_text: cityname } : { lng: locate?.coordinates?.lng, lat: locate?.coordinates?.lat };

      const localLocation = JSON.parse(sessionStorage.getItem("location")!);

      if (!localLocation || (type === "address" && cityname !== undefined && cityname !== "")) {
        const response: Locations = await serviceGetLocationByGoogle(userlocation, type);

        // const response: Locations = await serviceGetLocationByMapbox(userlocation, type);

        sessionStorage.setItem("location", JSON.stringify(response));

        setAddress(response);
        doSetLocation !== undefined && doSetLocation(response);

        return;
      }

      setAddress(localLocation);
      doSetLocation !== undefined && doSetLocation(localLocation);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);

        return;
      }

      toast.error("Something went wrong while getting location");
    }
  }, []);

  useEffect(() => {
    if (location.loaded) {
      getLocation("latlng", undefined, location);
    }
  }, [location]);

  const onSubmitSearch = (e: FormEvent) => {
    e.preventDefault();

    getLocation("address", city, undefined);
  };

  const handleSelectCity = (value: string) => {
    getLocation("address", value, undefined);
    setCity("");
  };

  const userLocation = () => {
    let text = "";

    if (address.kelurahan !== "" && address.kabupaten !== "") {
      text = `${address.kelurahan}, ${address.kabupaten}`;
    } else if (address.kelurahan === "" && address.kabupaten !== "") {
      text = `${address.kabupaten}, ${address.provinsi}`;
    } else {
      text = address.provinsi;
    }

    return text;
  };

  return (
    <div className="dropdown mr-4">
      <a
        className="text-dark dropdown-toggle d-flex align-items-center osahan-location-drop"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div>
          <i className="icofont-location-pin d-flex align-items-center bg-light rounded-pill p-2 icofont-size border shadow-sm mr-2" />
        </div>
        <div>
          <p className="text-muted mb-0 small">Pilih lokasi</p>
          {limitContentText(userLocation(), 25)}
        </div>
      </a>
      <div
        className="dropdown-menu osahan-select-loaction p-3"
        aria-labelledby="navbarDropdown"
      >
        <span>Cari lokasimu</span>
        <form className="form-inline my-2" onSubmit={onSubmitSearch}>
          <div className="input-group p-0 col-lg-12">
            <input
              type="text"
              className="form-control form-control-sm"
              autoComplete="off"
              value={city || ""}
              placeholder="e.g. Yogyakarta"
              onChange={(e) => { setCity(e.target.value); }}
            />
            <div className="input-group-prepend">
              <button type="submit" className="btn btn-danger rounded-right btn-sm">
                <i className="icofont-location-arrow" />
                Deteksi
              </button>
            </div>
          </div>
        </form>
        <div className="city pt-2">
          <h6>Wilayah Lainnya</h6>
          <p className="border-bottom m-0 py-1">
            <a href="#" className="text-dark" onClick={() => { handleSelectCity("yogyakarta"); }}>
              Yogyakarta
            </a>
          </p>
          <p className="border-bottom m-0 py-1">
            <a href="#" className="text-dark" onClick={() => { handleSelectCity("magelang"); }}>
              Magelang
            </a>
          </p>
          <p className="border-bottom m-0 py-1">
            <a href="#" className="text-dark" onClick={() => { handleSelectCity("purworejo"); }}>
              Purworejo
            </a>
          </p>
          <p className="m-0 py-1">
            <a href="#" className="text-dark" onClick={() => { handleSelectCity("muntilan"); }}>
              Muntilan
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetLocation;
