import { useState, useEffect, useRef } from "react";
import mapboxgl, { LngLatLike, MapMouseEvent } from "mapbox-gl";
// Configs
import { Locations } from "../../../configs/datatype";

interface MapProps {
  isOpened: boolean;
  id?: string;
  dataLocation: Partial<Locations>;
  height?: number;
}

export default function ShowedMap(props: MapProps) {
  const { isOpened, id, dataLocation, height } = props;

  if (!isOpened) return null;

  useEffect(() => {
    let maps: any = null;
    let center: LngLatLike = [110.4058563385276, -7.7594104029866315];

    // if (dataLocation.coordinate?.latitude! < -90 || dataLocation.coordinate?.latitude! > 90) {
    //   center = [dataLocation.coordinate?.latitude as number, dataLocation.coordinate?.longitude as number];
    // } else {

    if (dataLocation) {
      center = [dataLocation.coordinate?.longitude as number, dataLocation.coordinate?.latitude as number];
    }

    // }

    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN!;

    maps = new mapboxgl.Map({
      container: id!,
      style: "mapbox://styles/mapbox/streets-v11",
      center,
      zoom: 15,
    });

    maps.addControl(new mapboxgl.NavigationControl());

    const marker = new mapboxgl.Marker({
      color: "#f52d56",
      draggable: false,
    })
      .setLngLat(center)
      .addTo(maps);

    return () => {
      maps.remove();
    };
  }, [id, dataLocation]);

  return (
    <div
      style={{
        // borderRadius: ".25rem",
        overflow: "hidden",
      }}
    >
      <div id={id} style={{ minHeight: height || 200 }} />
    </div>
  );
}

ShowedMap.defaultProps = {
  height: 200,
  id: "maps",
};
