import { useState, useEffect } from "react";
import Image from "next/image";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
// Components
import { Rating } from "../../molecules";
// Configs
import { uri } from "../../../configs";
import { UserProfile, ConversationsAdd } from "../../../configs/datatype";
// Utils
import { decoded } from "../../../utils";
// Data
import { AppDispatch, actionAddConversation } from "../../../reduxs";

interface PropsDataStore {
  data: any;
  totalReview: number;
}

export default function SectionInformasiStore(props: PropsDataStore) {
  const { data, totalReview } = props;

  const [source, setSource] = useState<string>("/");

  // Reduxs
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (data && data.logo) setSource(data.logo);
  }, [data]);

  const handleAddConversation = () => {
    const _xLo = JSON.parse(sessionStorage.getItem("_xLo")!);

    if (!_xLo) {
      toast.warning("Silahkan login terlebih dahulu");

      return;
    }

    const ufk = localStorage.getItem("ufk");
    const userdata: UserProfile = ufk ? JSON.parse(decoded(ufk as string)) : {};

    const token = Cookies.get("_xSe");

    const dataConversation: ConversationsAdd = {
      participants: [
        { user_id: userdata.id },
        { shop_id: data?.id },
      ],
      last_message: "-",
    };

    dispatch({ type: "OPEN_CHAT" });
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch(actionAddConversation(dataConversation, { session: decoded(token as string || ""), userid: userdata.id }));
  };

  return (
    <div className="container mb-5">
      {/* <AlertInformationStore /> */}

      <div className="bg-white rounded shadow-sm overflow-hidden">
        <div className="row d-flex align-items-center">
          <div className="col-xs-12 col-md-2">
            <div
              className="position-relative overflow-hidden rounded"
              style={{ width: "100%", height: "100%", minHeight: 200 }}
            >
              <Image
                src={source}
                onError={() => { setSource("/images/image-default.jpg"); }}
                alt="Store Logo"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={data?.logo}
              />
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <h6 className="mb-3 font-weight-500">{data?.name}</h6>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-1">
                  <i className="icofont-location-pin" />
                  <p className="mb-0 ml-2 font-italic ">
                    {data?.address}
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <i className="icofont-ui-cell-phone" />
                  <p className="mb-0 ml-2">
                    (+62)
                    {" "}
                    {data?.phone[0] === "0" ? data?.phone.slice(1) : data?.phone}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <button
                  className="btn d-flex align-items-center text-dark rounded-circle"
                  onClick={handleAddConversation}
                >
                  <i className="icofont-support-faq h5 mb-0 mr-1" />
                  <span style={{ fontSize: 10 }}>Hubungi</span>
                </button>
              </div>
            </div>

            <div className="d-flex flex-column mb-1">
              <p className="mb-1">Deskripsi:</p>
              <p className="text-muted mb-0">
                {data?.description}
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="d-flex flex-column col4Store" style={{ minHeight: 200 }}>
              <div className="d-flex flex-column position-relative mb-4">
                <p className="mb-1 text-dark">Kualitas Toko:</p>
                <div className="d-flex align-items-center" style={{ height: 40 }}>
                  <h5 className="mb-0 mr-3">
                    (
                    {data?.rating}
                    )
                  </h5>

                  <div className="d-flex flex-column">
                    <Rating
                      size={22}
                      value={parseInt(data?.rating, 10)}
                    />

                    <small className="mb-0 text-muted">
                      {totalReview}
                      {" "}
                      ulasan
                    </small>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column position-relative">
                <p className="mb-1 text-dark">Produk Terjual:</p>
                <h5 className="mb-0" style={{ fontWeight: 500 }}>{data?.total_product_sold}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
