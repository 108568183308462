import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
// Configs
import { Topic } from "../../../configs/datatype";
// Data
import { serviceGetFaq } from "../../../services";

type FaqDesc = {
  type: string;
  content: Array<string> | string;
}

type FaqItemType = {
  title: string;
  description: Array<FaqDesc>;
}

type FaqType = {
  [key in Topic]: Array<FaqItemType>;
}

type SectionListQuestionsProps = {
  selectedTopic: Topic;
};

const SectionListQuestions = (props: SectionListQuestionsProps) => {
  const { selectedTopic } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Partial<FaqType>>({});

  const getFaqData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await serviceGetFaq();

      if (response.status !== "passed") {
        toast.error("Terjadi kesalahan saat mengambil data FAQ");
        setIsLoading(false);

        return;
      }

      setQuestions(response.data);
      setIsLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setIsLoading(false);

        return;
      }

      toast.error("Something went wrong when getting data FAQ");
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getFaqData();
  }, []);

  return (
    <div className="container">
      <h6 className="mb-3">Daftar Tanya dan Jawaban</h6>

      <div id="basicsAccordion">
        {!isLoading && questions[selectedTopic]?.map((question: FaqItemType, i: number) => {
          return (
            <div key={i.toString()} className="box border rounded mb-1 bg-white">
              <h5 className="mb-0">
                <button
                  className="shadow-none btn btn-block d-flex align-items-center justify-content-between card-btn p-3"
                  data-bs-toggle="collapse"
                  data-bs-target={`#question${i.toString()}`}
                  aria-expanded="true"
                  aria-controls={`question${i.toString()}`}
                >
                  {question.title}
                  <i className="icofont-simple-down" />
                </button>
              </h5>
              <div
                id={`question${i.toString()}`}
                className={`collapse ${i === 0 ? "show" : ""}`}
                aria-labelledby={i.toString()}
                data-bs-parent="#basicsAccordion"
              >
                <div className="card-body border-top p-3 text-muted">
                  {question?.description.map((desc: any, x: number) => {
                    if (desc.type === "list") {
                      return (
                        <ul key={x.toString()}>
                          {desc.content.map((list: any, y: number) => {
                            return (
                              <li key={y.toString()}>
                                {list}
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }

                    if (desc.type === "paragraph") {
                      return (
                        <p key={x.toString()}>{desc?.content}</p>
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
};

export default SectionListQuestions;
