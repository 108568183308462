// Components
import { Logo, Anchor } from "../../atoms";
// Configs
import { navigation } from "../../../utils";
import { RoutesDataTypes } from "../../../configs/datatype";

const Footer = () => {
  return (
    <footer className="border-top bg-white px-0 d-none d-md-block">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container d-flex">
          {/* <Logo /> */}

          <div className="container menu-bar d-flex align-items-center">
            <div className="navbar-nav ml-auto text-left">

              {navigation("footer").map((route: RoutesDataTypes, i: number) => {
                return (
                  <Anchor
                    key={i.toString()}
                    href={route.path}
                    className="nav-link px-3"
                  >
                    {route.title}
                  </Anchor>
                );
              })}

            </div>
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
