import { Component, ButtonHTMLAttributes, FormEvent } from "react";
import cx from "classnames";

interface DefaultButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  "data-sitekey"?: string,
  "data-callback"?: string | ((e: FormEvent) => void),
  "data-action"?: string,
  "data-bs-toggle"?: string,
  "data-bs-target"?: string,
  "data-bs-dismiss"?: string,
  "data-bs-content"?: string,
  "data-bs-placement"?: string,
  title?: string;
}

interface ButtonProps {
  variant: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link";
  size: "sm" | "md" | "lg";
  block?: boolean;
  outline?: boolean;
  loading?: boolean;
  label?: string;
  icon?: string;
  ComponentIcon?: typeof Component;
  className?: string;
  // onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonProps?: DefaultButton;
  // buttonProps?: ButtonHTMLAttributes<HTMLButtonElement> | {
  //   "data-bs-toggle"?: string;
  //   "data-bs-target"?: string;
  //   "data-bs-dismiss"?: string;
  // };
}

export default function Button(props: ButtonProps) {
  const { variant, size, block, outline, label, loading, icon, ComponentIcon, className, buttonProps } = props;

  const btnclass = cx(
    "btn d-flex align-items-center justify-content-center",
    className,
    {
      [`btn-${variant}`]: !outline,
      [`btn-outline-${variant}`]: outline,
      [`btn-${size}`]: true,
      "btn-block": block,
      // border: variant === "light",
    },
  );

  const iconclass = cx(
    icon,
    {
      "mr-2": label !== undefined,
      "h5 mb-0": size === "lg",
      "h6 mb-0": size === "md",
    },
  );

  const RenderIcon = () => {
    if (icon) return <i className={iconclass} />;

    if (ComponentIcon) return <ComponentIcon className={`${label ? "mr-2" : null}`} />;

    return null;
  };

  return (
    <button className={btnclass} {...buttonProps}>
      <RenderIcon />

      {label && <span>{loading ? "Loading..." : label}</span>}
    </button>
  );
}

Button.defaultProps = {
  block: false,
  label: undefined,
  outline: false,
  loading: false,
  icon: undefined,
  ComponentIcon: undefined,
  className: undefined,
  buttonProps: undefined,
};
