import { useState, useEffect, useRef } from "react";
// Components
import { Button, Dialog } from "../../atoms";
// Configs
import { Locations, AddressItem } from "../../../configs/datatype";

interface ItemAddressProps {
  isDefault?: boolean;
  data: AddressItem;
  doSetDefault: (id: number, alias: string) => void;
  doOpenEdit: () => void;
  doChangeData: (value: any) => void;
  doChangeDataLocation: (value: Partial<Locations>) => void;
  doDeleteData: (id: number, alias: string) => void;
}

export default function ItemAddress(props: ItemAddressProps) {
  const { isDefault, data, doSetDefault, doOpenEdit, doChangeData, doChangeDataLocation, doDeleteData } = props;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);

  const dropdownref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (dropdownref.current && !dropdownref.current.contains(e.target)) {
        setOpenDropdown(false);
      }
    };

    if (openDropdown) {
      document.addEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdownref, openDropdown]);

  const handleOpenEdit = () => {
    setOpenDropdown(false);

    const locationData: Partial<Locations> = {
      address: data.address,
      kelurahan: data.default_data.data?.kelurahan?.name,
      kecamatan: data.default_data.data?.kecamatan?.name,
      kabupaten: data.default_data.data?.kabupaten?.name,
      provinsi: data.default_data.data?.provinsi?.name,
      coordinate: {
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
      },
    };

    doChangeData({
      id: data.id,
      recipient_name: data.recipient_name,
      phone: data.phone?.[0] === "0" ? data.phone.slice(1) : data.phone,
      address: data.address,
      address_alias: data.address_alias,
      address_detail: data.address_detail,
      provinsi: data.default_data?.data?.provinsi,
      kabupaten: data.default_data?.data?.kabupaten,
      kecamatan: data.default_data?.data?.kecamatan,
      kelurahan: data.default_data?.data?.kelurahan,
    });

    doChangeDataLocation(locationData);
    doOpenEdit();
  };

  const handleSetDefault = () => {
    setOpenDropdown(false);

    doSetDefault(data.id, data.address_alias);
  };

  const handleOpenDialogDelete = () => {
    setOpenDropdown(false);

    setDialogDelete(true);
  };

  const handleDelete = () => {
    setDialogDelete(false);

    doDeleteData(data.id, data.address_alias);
  };

  return (
    <div
      className={`card shadow-sm ${isDefault && "border-success"}`}
      style={{ backgroundColor: isDefault ? "#ebffef" : "#fff" }}
    >
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <p className="card-title mb-0 mr-3" style={{ fontWeight: 600 }}>
              {data?.address_alias}
            </p>
            {isDefault && <span className="badge badge-pill badge-light">Utama</span>}
          </div>

          <div className="d-flex align-items-center">
            {/* {!isDefault && (
              <Button
                label="Jadikan utama"
                variant="light"
                size="sm"
                className="mr-4 text-muted"
                buttonProps={{
                  onClick: () => { doSetDefault(data?.id); },
                }}
              />
            )}

            <Button
              label="Ubah"
              variant="link"
              size="md"
              icon="icofont-edit"
              className="p-0 text-muted"
              buttonProps={{
                onClick: handleOpenEdit,
              }}
            /> */}

            <div className="btn-group">
              <Button
                variant="link"
                size="md"
                icon="icofont-options"
                className="pr-0 text-muted dropdown-toggle"
                buttonProps={{
                  onClick: () => { setOpenDropdown(!openDropdown); },
                }}
              />

              {openDropdown && (
                <div
                  ref={dropdownref}
                  className="dropdown-menu dropdown-menu-right border show"
                >
                  <button className="dropdown-item" onClick={handleOpenEdit}>Ubah</button>

                  {!isDefault && (
                    <>
                      <button className="dropdown-item" onClick={handleSetDefault}>Jadikan Utama</button>
                      <div className="dropdown-divider" />
                      <button className="dropdown-item" onClick={handleOpenDialogDelete}>Hapus</button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <h6 className="card-text mb-0 mr-3" style={{ fontSize: 14 }}>{data?.recipient_name}</h6>
          <p className="card-text text-muted">
            (+62)
            {" "}
            {data?.phone?.[0] === "0" ? data?.phone?.slice(1) : data?.phone}
          </p>
        </div>
        <p className="card-text">
          {data?.address}
        </p>
      </div>

      <Dialog open={dialogDelete} size="sm">
        <div className="modal-body">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <i className="icofont-trash text-danger my-3" style={{ fontSize: "40px" }} />
            <h6 className="text-center" style={{ lineHeight: 1.5 }}>Apa kamu yakin ingin menghapus alamat ini?</h6>
            <p className="small text-muted m-0">
              &quot;
              {data?.address_alias}
              &quot;
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            label="Batal"
            size="md"
            variant="light"
            buttonProps={{
              onClick: () => { setDialogDelete(false); },
            }}
          />

          <Button
            label="Ya, Hapus"
            size="md"
            variant="danger"
            buttonProps={{
              onClick: handleDelete,
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}

ItemAddress.defaultProps = {
  isDefault: false,
};
