import { useState } from "react";
// Component
import { ItemReview } from "../../molecules";

// Dummy data, Will deleted soon!
const DummyReviewDone = [
  {
    member_name: "M Firman Setiawan",
    invoice: "7568 343 AA",
    created_date: "03/20/2021",
    rate: 4.3,
    comment: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque dolorum rerum delectus numquam molestias consequuntur a dicta facilis, similique cum!",
    items: [1, 2],
    cover: "/images/product.png",
  },
  {
    member_name: "Dede Gita Silvia",
    invoice: "7568 343 AA",
    created_date: "12/14/2020",
    rate: 3.7,
    comment: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque dolorum rerum delectus numquam molestias consequuntur a dicta facilis, similique cum!",
    items: [1],
    cover: "/images/product.png",
  },
  {
    member_name: "Angela",
    invoice: "7568 343 AA",
    created_date: "11/10/2020",
    rate: 4.8,
    comment: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque dolorum rerum delectus numquam molestias consequuntur a dicta facilis, similique cum!",
    items: [1, 2, 3],
    cover: "/images/product.png",
  },
];

export default function ListReviewDone() {
  return (
    <>
      {DummyReviewDone.map((review: any, i: number) => {
        return (
          <div key={i.toString()} className="flex-1 mb-3">
            <ItemReview
              variant="done"
              data={review}
            />
          </div>
        );
      })}
    </>
  );
}
