import { Logo } from "../../atoms";

const HeaderMobile = () => {
  return (
    <div className="border-bottom p-3 d-none mobile-nav">
      <div className="title d-flex align-items-center">
        <Logo />
        {/* <p className="ml-auto m-0">
          <a
            href="/"
            className="text-decoration-none bg-white p-1 rounded shadow-sm d-flex align-items-center"
          >
            <i className="text-dark icofont-sale-discount" />
            <span className="badge badge-danger p-1 ml-1 small">50%</span>
          </a>
        </p> */}
        <a
          className="toggle ml-auto hc-nav-trigger hc-nav-1"
          href="#"
          role="button"
          aria-controls="hc-nav-1"
          aria-label="Open Menu"
          aria-expanded="false"
        >
          <i className="icofont-navigation-menu" />
        </a>
      </div>
      <div className="input-group mt-3 rounded shadow-sm overflow-hidden bg-white">
        <div className="input-group-prepend">
          <button className="border-0 btn btn-outline-secondary text-success bg-white">
            <i className="icofont-search" />
          </button>
        </div>
        <input
          type="text"
          className="shadow-none border-0 form-control pl-0"
          placeholder="Search for Products.."
          aria-label=""
          aria-describedby="basic-addon1"
        />
      </div>
    </div>
  );
};

export default HeaderMobile;
