// Components
import { UserMenu } from "../../organisms";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}

const UserPageLayout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-3 col-md-12">
          <UserMenu />
        </div>

        <div className="col-lg-9 col-md-12">
          {children}
        </div>
      </div>
    </div>
  );
};

export default UserPageLayout;
